<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6">
                <div class="search-box mr-2 mb-2 d-inline-block ">
                  <div class="position-relative">
                    <input type="text" class="form-control" v-model="search" placeholder="Digite e pressione 'ENTER'" @keyup.enter="doSearch"/>
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right me-2">
                    <button type="button" class="btn  btn-primary btn-rounded mb-2 mr-2" @click="importData()">
                      <i class="bx bx-import mr-1"></i> Importar Participantes
                    </button>
                  </div>
                  <div class="text-sm-right me-2">
                    <button type="button" class="btn  btn-primary btn-rounded mb-2 mr-2" @click="showFilters()">
                      <i class="mdi mdi-filter mr-1"></i> Filtros
                    </button>
                  </div>
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newParticipant()">
                      <i class="mdi mdi-plus mr-1"></i> Novo Participante
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th style="width: 70px;"></th>
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>Inscrição</th>
                    <th>Status</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in participants" :key="list.id">
                    <td>
                      <div>
                        <img class="rounded avatar-xs" :src="(list.avatar && list.avatar !== 'undefined') ? list.avatar : avatar" alt />
                        <i v-if="list.moderator" class="mdi mdi-shield-star-outline text-primary font-size-18" alt="Moderador" title="Moderador"></i>
                        <i v-if="list.blocked_chat" class="mdi mdi-chat-alert text-danger font-size-18" alt="Chat bloqueado" title="Chat bloqueado"></i>
                      </div>
                    </td>
                    <td>{{ list.name }}</td>
                    <td>{{ list.email }}</td>
                    <td>{{ list.inscricao }}</td>
                    <td>
                      <span class="badge badge-pill font-size-12" :class="{
                        'badge-soft-success': `${parseInt(list.status)}` === '1',
                        'badge-soft-danger': `${parseInt(list.status)}` === '0',
                      }">{{ statusLabel[list.status] }}</span>
                      <span v-if="list.sympla" class="badge badge-soft-primary font-size-11 m-1">Sympla</span>
                    </td>
                    <td>
                      <!-- <a href="javascript: void(0);" @click="editParticipant(list.id)" class="text-gray" alt="Editar"><i class="bx bx bxs-edit h3 m-0"></i></a>
                      <a href="javascript: void(0);" @click="toggleStatus(list.id)" class="text-dark">
                        <i class="bx h3 m-0" :class="{ 'bx-toggle-right': parseInt(list.status) === 1, 'bx-toggle-left': parseInt(list.status) === 0 }"></i>
                      </a>
                      <a href="javascript: void(0);" @click="deleteParticipant(list.id)" class="text-gray" alt="Excluir"><i class="bx bx bxs-trash h3 m-0"></i></a> -->
                      <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>
                        <b-dropdown-item @click="editParticipant(list.id)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="toggleStatus(list.id)">
                          <i class="fas fa-circle mr-1" :class="{ 'text-danger': parseInt(list.status) === 1, 'text-success': parseInt(list.status) === 0 }"></i> {{ list.status ? 'Bloquear' : 'Desbloquear' }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteUser(list.id)">
                          <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="showDetailsIndication(list.id)">
                          <i class="fas fa-link mr-1 text-primary"></i> Indicações
                        </b-dropdown-item>
                        <b-dropdown-item @click="toggleModerator(list.id)">
                          <i class="fas fa-circle mr-1" :class="{ 'text-primary': parseInt(list.moderator) === 0, 'text-default': parseInt(list.moderator) === 1 }"></i> {{ list.moderator ? 'Rem. Moderação' : 'Add. Moderação' }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" :idParticipant="idParticipant" ref="formParticipant" @created="updateList"></modal-form>
    <modal-filter ref="modalFilter" @participantFiltered="getReport"></modal-filter>
    <modal-import ref="modalImport" @usersImported="updateList"></modal-import>
    <indication-details v-model="modalDetail" ref="modalDetails" @closed="updateList"></indication-details>
  </Layout>
</template>
<script>
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import ModalForm from './Form'
import modalFilter from '@/components/modals/filters/ParticipantFilter'
import modalImport from '@/components/modals/ImportUsers'
import IndicationDetails from '@/components/modals/IndicationDetails'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    ModalForm,
    modalImport,
    modalFilter,
    IndicationDetails
  },
  name: 'Participantes',
  page: {
    title: 'Participantes',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      avatar: require('@/assets/images/users/user-default.png'),
      loading: true,
      search: '',
      timeout: null,
      modalForm: false,
      modalDetail: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      idParticipant: null,
      perPage: 20,
      statusLabel: ['Bloqueado', 'Ativo'],
      submitted: false,
      title: 'Participantes',
      items: [{
        text: 'Participantes'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('participant', ['participants', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('participant', {
      getAll: 'getAll',
      changeStatus: 'changeStatus',
      changeModerator: 'changeModerator',
      deleteParticipant: 'deleteUser'
    }),
    importData () {
      this.$refs.modalImport.open()
    },
    showFilters () {
      this.$refs.modalFilter.open()
    },
    newParticipant () {
      this.$refs.formParticipant.open('')
      this.modalForm = true
    },
    editParticipant (id) {
      this.$refs.formParticipant.open(id)
      this.modalForm = true
      this.idParticipant = id
    },
    showDetailsIndication (userId) {
      this.modalDetail = true
      this.$refs.modalDetails.open(userId)
    },
    async toggleStatus (id) {
      try {
        await this.changeStatus(id)
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async toggleModerator (id) {
      try {
        await this.changeModerator(id)
        let message = 'Sucesso: Usuário atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    deleteUser (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteParticipant(id).then((res) => {
              successMessage('Sucesso: Participante excluído com sucesso.', 2500)
              this.updateList()
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        sympla: filter.sympla || null,
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage,
        domain: filter.domain || null,
        city: filter.city || null,
        district: filter.district || null,
        created_start: filter.created_start ? this.$moment(filter.created_start).format('YYYY-MM-DD') : null,
        created_end: filter.created_end ? this.$moment(filter.created_end).format('YYYY-MM-DD') : null,
        status: filter.status || null
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  },
  watch: {
    // currentPage () { this.updateList() }
    // total () { this.updateList() },
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
