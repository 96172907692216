<template>
  <Layout>
    <PageHeader :title="customTitle" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <ContentPartner />
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import ContentPartner from '@/components/widgets/content-partner'
import appConfig from '@/app.config'
import { errorMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    ContentPartner
  },
  name: 'Parceiro',
  page: {
    title: 'Parceiro',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      partner: {
        id: '',
        name: '',
        title: '',
        description: '',
        thumb_image: '',
        allow_users: 0
      },
      loading: true,
      submitted: false,
      title: 'Parceiro',
      items: [{
        text: 'Parceiros',
        href: '/partners'
      },
      {
        text: 'Configurações da Parceiro',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.loadItem()
  },
  computed: {
    ...mapState('partner', { data: 'partner' }),
    customTitle () {
      return 'Parceiro - ' + this.partner.name
    }
  },
  methods: {
    ...mapActions('partner', {
      fetch: 'show'
    }),
    cancelar () {
      this.$router.push({ name: 'partners' })
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.fetch(this.$route.params.id)
          .then(() => {
            this.partner = this.data
          }).then(() => { loader.hide() })
          .catch((error) => {
            console.error(error)
            loader.hide()
            errorMessage('Atenção:' + error.message)
          })
      }
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.link { cursor: pointer; }
.thumb {
  max-width: 100%;
  max-height: 168px;
  min-height: 168px;
}
</style>
