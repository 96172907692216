<template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <!-- <div class="col-sm-6">
                <div class="search-box mr-2 mb-2 d-inline-block ">
                  <div class="position-relative">
                    <input type="text" class="form-control" v-model="search" placeholder="Digite e pressione 'ENTER'" @keyup.enter="doSearch"/>
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div> -->
              <div class="col-sm-6">
                <!-- <div class="float-sm-end d-flex">
                  <div class="text-sm-right me-2">
                    <button type="button" class="btn  btn-primary btn-rounded mb-2 mr-2" @click="showFilters()">
                      <i class="mdi mdi-filter mr-1"></i> Filtros
                    </button>
                  </div>
                </div> -->
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th width="25%">Título</th>
                    <th width="40%">Mensagem</th>
                    <th width="10%">Status</th>
                    <th width="10%">Enviado</th>
                    <th width="5%">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in notificationList" :key="list.id">
                    <td>{{ list.title }}</td>
                    <td>{{ list.message }}</td>
                    <td>
                      <span class="badge badge-pill font-size-12" :class="{
                        'badge-soft-success': `${parseInt(list.status)}` === '1',
                        'badge-soft-dark': `${parseInt(list.status)}` === '0',
                      }">{{ statusLabel[list.status] }}</span>
                    </td>
                    <td>{{ list.send_data ? $moment(list.send_data).format('DD/MM/Y HH:mm') : `` }}</td>
                    <td><i v-if="!list.status" @click="sendNotification(list.id)" class="bx bx-send mr-1 link" :class="{
                        'color-green': `${parseInt(list.status)}` === '1',
                        'color-default': `${parseInt(list.status)}` === '0',
                      }"></i></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
</template>
<script>
import Swal from 'sweetalert2'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
  },
  name: 'notificationlist',
  page: {
    title: 'Lista de notificacoes',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      search: '',
      timeout: null,
      modalForm: false,
      modalDetail: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      idPost: null,
      perPage: 20,
      statusLabel: ['Aguardando envio', 'Enviado'],
      submitted: false,
      title: 'Feed',
      items: [{
        text: 'Feed'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.paginationnl.total
    this.currentPage = this.paginationnl.current_page
    this.loading = false
    this.$on('createdNotification', this.updateList)
  },
  computed: {
    ...mapState('event', ['notificationList', 'paginationnl']),
    rows () {
      return this.paginationnl.total
    }
  },
  methods: {
    ...mapActions('event', {
      getNotificationAll: 'getNotificationAll',
      postNotification: 'createNotification',
      sendNotificationById: 'sendNotification'
    }),
    deletePost (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.remPost(id).then((res) => {
              successMessage('Sucesso: post excluído com sucesso.', 2500)
              this.updateList()
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    async sendNotification (id) {
      let loader = this.$loading.show()
      try {
        await this.sendNotificationById(id).then(() => {
          let message = 'Sucesso: Notificalçao enviada com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
          this.updateList()
        })
      } catch (err) {
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    getReport (filter) {
      let params = {
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getNotificationAll(this.params).then((res) => {
        // this.feeds = res.data
        this.totalRows = this.paginationnl.total
        this.currentPage = this.paginationnl.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  },
  watch: {
    // currentPage () { this.updateList() }
    // total () { this.updateList() },
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
