var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"title":_vm.title,"size":"lg","no-enforce-focus":true,"no-close-on-backdrop":true},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancelar")]),_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"},on:{"click":function($event){return _vm.saveUser()}}},[_vm._v("Salvar")])]},proxy:true}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('loading',{attrs:{"active":_vm.loading,"color":"#556ee6","loader":"dots","can-cancel":false,"is-full-page":false},on:{"update:active":function($event){_vm.loading=$event}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.saveUser()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nome")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.name),expression:"user.name"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.user.name.$error
                },attrs:{"id":"name","name":"name","type":"text"},domProps:{"value":(_vm.user.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo nome é obrigatório. ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("E-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.user.email.$error
                },attrs:{"id":"email","name":"email","type":"text"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "email", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.email.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo email é obrigatório. ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Senha")]),(!_vm.user.id)?_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.user.password.$error
                  },attrs:{"id":"password","name":"password","type":"text"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "password", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo senha é obrigatório. ")]):_vm._e()]):_vm._e(),(_vm.user.id)?_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",attrs:{"id":"password","name":"password","type":"text"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "password", $event.target.value)}}})]):_vm._e()])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"role"}},[_vm._v("Função")]),_c('multiselect',{staticClass:"helo",class:{
                  'is-invalid': _vm.submitted && _vm.$v.user.role.$error,
                },attrs:{"options":_vm.roles,"max-height":180,"placeholder":"Selecione uma função","label":"name","track-by":"id","classError":_vm.$v.user.role.$error ? true : false},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.submitted && !_vm.$v.user.role.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo função é obrigatório. ")]):_vm._e()],1)])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }