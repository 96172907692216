<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveFeed()" autocomplete="off">
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label for="name">Nome</label>
                <input
                  id="name"
                  v-model="feed.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.feed.name.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.feed.name.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="color">Pode postar</label>
                <select
                  id="color"
                  v-model="feed.can_post"
                  name="color"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.feed.can_post.$error
                  }"
                >
                  <option></option>
                  <option value="1">SIM</option>
                  <option value="0">NÃO</option>
                </select>
                <div
                  v-if="submitted && !$v.feed.can_post.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="color">Pode Curtir</label>
                <select
                  id="color"
                  v-model="feed.can_like"
                  name="color"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.feed.can_like.$error
                  }"
                >
                  <option></option>
                  <option value="1">SIM</option>
                  <option value="0">NÃO</option>
                </select>
                <div
                  v-if="submitted && !$v.feed.can_like.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="color">Pode Comentar</label>
                <select
                  id="color"
                  v-model="feed.can_comment"
                  name="color"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.feed.can_comment.$error
                  }"
                >
                  <option></option>
                  <option value="1">SIM</option>
                  <option value="0">NÃO</option>
                </select>
                <div
                  v-if="submitted && !$v.feed.can_comment.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveFeed()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    Loading
  },
  name: 'setting_feed_new',
  data () {
    return {
      title: 'Novo Feed',
      feed: {},
      loading: true,
      fullPage: true,
      modal: false,
      submitted: false
    }
  },
  validations: {
    feed: {
      name: { required },
      can_post: { required },
      can_like: { required },
      can_comment: { required }
    }
  },
  mounted () {
    this.loadItem()
  },
  computed: {
    ...mapState('settingFeed', { data: 'feed' })
  },
  methods: {
    ...mapActions('settingFeed', {
      fetch: 'show',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.title = 'Editar Feed'
        this.loading = true
        this.fetch(id)
          .then(() => {
            this.feed = this.data
          }).then(() => {
            this.loading = false
          })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveFeed () {
      this.submitted = true
      this.$v.feed.$touch()
      if (this.$v.feed.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.feed).then(res => {
        let message = 'Sucesso: Dados atualizados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.loading = false
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.feed = {}
      this.$v.$reset()
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
  z-index: 1051 !important;
}
</style>
