var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),(!_vm.setting.id)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-body overflow-hidden text-center mt-4"},[_c('h5',{staticClass:"text-truncate font-size-15"},[_vm._v("Olá, "+_vm._s(_vm.user.name)+".")]),_c('p',{staticClass:"text-muted"},[_vm._v("Indentificamos que a sua plataforma ainda não está configurada.")]),_c('b-button',{staticClass:"mt-4 mb-4",attrs:{"variant":"primary","size":"lg"},on:{"click":function($event){return _vm.initSetting()}}},[_c('i',{staticClass:"bx bx-cog font-size-16 align-middle mr-2"}),_vm._v(" CONFIGURAR AGORA! ")])],1)])])])])]):_vm._e(),(_vm.setting.id || _vm.configEvent)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveSetting()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nome do evento")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.setting.name),expression:"setting.name"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitted && _vm.$v.setting.name.$error,
                    },attrs:{"id":"name","name":"name","type":"text","placeholder":"Inform o nome do evento"},domProps:{"value":(_vm.setting.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.setting, "name", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("E-mail de Contato")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.setting.contact_email),expression:"setting.contact_email"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitted && _vm.$v.setting.contact_email.$error,
                    },attrs:{"id":"name","name":"name","type":"text","placeholder":"Informe um e-mail para contato"},domProps:{"value":(_vm.setting.contact_email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.setting, "contact_email", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"phone"}},[_vm._v("Telefone para suporte")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.setting.phone),expression:"setting.phone"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitted && _vm.$v.setting.phone.$error,
                    },attrs:{"id":"phone","name":"phone","type":"text"},domProps:{"value":(_vm.setting.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.setting, "phone", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Descrição do Evento")]),_c('div',{staticClass:"form-group"},[_c('ckeditor',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.setting.description.$error,
                    },attrs:{"editor":_vm.editor,"config":_vm.editorConfig},model:{value:(_vm.setting.description),callback:function ($$v) {_vm.$set(_vm.setting, "description", $$v)},expression:"setting.description"}})],1)])]),_c('div',{staticClass:"mt-1 float-right"},[_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v(" Salvar ")])])])])])]),_c('div',{staticClass:"col-lg-12"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveSettingAccount()}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-4"},[_vm._v("Parâmetros")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"access_type"}},[_vm._v("Tipo de acesso")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sa.access_type),expression:"sa.access_type"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.sa.access_type.$error,
                  },attrs:{"name":"access_type","id":"access_type"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.sa, "access_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Email e senha")]),_c('option',{attrs:{"value":"2"}},[_vm._v("E-mail")])]),(_vm.submitted && !_vm.$v.sa.access_type.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"complete_profile"}},[_vm._v("Completar perfil?")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sa.complete_profile),expression:"sa.complete_profile"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.sa.complete_profile.$error,
                  },attrs:{"name":"complete_profile","id":"complete_profile"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.sa, "complete_profile", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Sim")]),_c('option',{attrs:{"value":"0"}},[_vm._v("Não")])]),(_vm.submitted && !_vm.$v.sa.complete_profile.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"send_email_registration"}},[_vm._v("E-mail após cadastro")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sa.send_email_registration),expression:"sa.send_email_registration"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.sa.send_email_registration.$error,
                  },attrs:{"name":"send_email_registration","id":"send_email_registration"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.sa, "send_email_registration", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Sim! Enviar e-mail após cadastro")]),_c('option',{attrs:{"value":"0"}},[_vm._v("Não enviar e-mail")])]),(_vm.submitted && !_vm.$v.sa.send_email_registration.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"session_login"}},[_vm._v("Acesso liberado?")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sa.session_login),expression:"sa.session_login"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.sa.session_login.$error,
                  },attrs:{"name":"session_login","id":"session_login"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.sa, "session_login", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Autorizado")]),_c('option',{attrs:{"value":"0"}},[_vm._v("Suspenso")])]),(_vm.submitted && !_vm.$v.sa.session_login.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"session_register"}},[_vm._v("Cadastro liberado?")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sa.session_register),expression:"sa.session_register"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.sa.session_register.$error,
                  },attrs:{"name":"session_register","id":"session_register"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.sa, "session_register", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Autorizado")]),_c('option',{attrs:{"value":"0"}},[_vm._v("Suspenso")])]),(_vm.submitted && !_vm.$v.sa.session_register.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-6"},[(parseInt(_vm.sa.session_login) === 0)?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"message_login_disabled"}},[_vm._v("Mensagem de acesso bloqueio")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.setting.message_login_disabled),expression:"setting.message_login_disabled"}],staticClass:"form-control",attrs:{"id":"message_login_disabled","name":"message_login_disabled","type":"text","placeholder":"Informe uma mensagem para o login suspenso."},domProps:{"value":(_vm.setting.message_login_disabled)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.setting, "message_login_disabled", $event.target.value)}}})]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"rsvp"}},[_vm._v("RSVP Ativo?")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sa.rsvp),expression:"sa.rsvp"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.sa.rsvp.$error,
                  },attrs:{"name":"rsvp","id":"rsvp"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.sa, "rsvp", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Sim")]),_c('option',{attrs:{"value":"0"}},[_vm._v("Não")])]),(_vm.submitted && !_vm.$v.sa.rsvp.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"after_login"}},[_vm._v("Página principal")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sa.after_login),expression:"sa.after_login"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.sa.after_login.$error,
                  },attrs:{"name":"after_login","id":"after_login"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.sa, "after_login", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Feed")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Dashboard")])]),(_vm.submitted && !_vm.$v.sa.after_login.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório ")]):_vm._e()])])]),_c('div',{staticClass:"mt-1 float-right"},[_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v(" Salvar ")])])])])])])]):_vm._e(),_c('b-modal',{ref:"modal-logo",attrs:{"id":"modal-center","centered":"","title":"Alterar Logotipo","title-class":"font-18"},on:{"hidden":function($event){return _vm.cancel('logo', 1)},"ok":_vm.cropImage}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.imgSrc),expression:"imgSrc"}],staticClass:"img-cropper border-dashed"},[_c('vue-cropper',{ref:"cropper",attrs:{"src":_vm.imgSrc,"preview":".preview"}})],1)]),_c('b-modal',{ref:"modal-banner-home",attrs:{"id":"modal-center","size":"lg","centered":"","title":"Alterar Banner Home","title-class":"font-18"},on:{"hidden":function($event){return _vm.cancel('bannerhome', 3)},"ok":_vm.cropBH}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.imgSrcBannerHome),expression:"imgSrcBannerHome"}],staticClass:"img-cropper border-dashed"},[_c('vue-cropper',{ref:"cropperBH",attrs:{"src":_vm.imgSrcBannerHome,"preview":".preview"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }