<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="table-responsive">
          <table class="table project-list-table table-nowrap table-centered table-borderless">
            <thead>
              <tr>
                <th scope="col" width="10%">Data do Pedido</th>
                <th scope="col" width="15%">Número do Pedido</th>
                <th scope="col" width="40%">Participante</th>
                <th scope="col" width="10%">Total Pontos</th>
                <th scope="col" width="15%">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(s, i) in orders" :key="i" class="link" :class="{ 'product-archived': s.archived }">
                <td @click="openOrder(s.id)" title="Click para editar">{{ s.order_at }}</td>
                <td @click="openOrder(s.id)" title="Click para editar">#{{ s.id }}</td>
                <td @click="openOrder(s.id)" title="Click para editar">{{ s.user? s.user.data.name : '' }}</td>
                <td @click="openOrder(s.id)" title="Click para editar">{{ s.total }}</td>
                <td>
                  <span class="badge badge-pill font-size-12" :class="{
                    'badge-soft-success': `${parseInt(s.status)}` === '2',
                    'badge-soft-primary': `${parseInt(s.status)}` === '1',
                    'badge-soft-warning': `${parseInt(s.status)}` === '0'
                  }">{{ statusLabel[s.status] }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row mt-4" v-show="orders.length > 0">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
              </ul>
            </div>
          </div>
        </div>
        <order v-model="modalOrder" ref="modalOrder" @created="updateList"></order>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import Order from './Order'

export default {
  components: {
    Layout,
    PageHeader,
    Order
  },
  name: 'store-orders',
  page: {
    title: 'Pedidos'
  },
  data () {
    return {
      submitted: false,
      modalCategory: false,
      modalOrder: false,
      statusLabel: ['Em processamento', 'Em Transporte', 'Entregue'],
      params: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      title: 'Pedidos',
      items: [{
        text: 'Pedidos'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  beforeMount () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  computed: {
    ...mapState('order', ['orders', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('order', {
      fetchData: 'getList',
      changeStatus: 'changeStatus'
    }),
    openOrder (id) {
      this.$refs.modalOrder.open(id)
      this.modalOrder = true
    },
    async toggleStatus (id, status = '') {
      try {
        await this.changeStatus({ id, status })
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    updateList () {
      this.fetchData({ limit: this.perPage, page: this.currentPage, include: 'user' })
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.product-archived { color: #909090; background: #fff0f0}
.mt-15 { margin-top:15px }
</style>
