<template>
  <div style="margin-left:-15px">
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="text-sm-right">
          <button type="button" class="btn btn-success btn-sm btn-rounded" @click="newWordCloud()">
            <i class="mdi mdi-plus"></i> Inserir Word Cloud
          </button>
        </div>
      </div>
      <!-- end col-->
    </div>
    <div class="row">
      <div class="col-md-12 text-center" v-show="words_cloud.length === 0">
        <h5 class="text-truncate font-size-14 p-4 mt-2">Nenhuma nuvem de palavra configurada. </h5>
        <i class="bx bx-tired bx-lg"></i>
        <br />
      </div>
      <div class="table-responsive mt-3 mb-0" v-show="words_cloud.length > 0">
        <table class="table table-centered table-nowrap table-hover">
          <thead>
            <tr>
              <th width="60%">Pergunta</th>
              <th>Palavras</th>
              <th>Liberada</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(wordcloud, i) in words_cloud" :key="i" class="link" :class="{ 'poll-archived': wordcloud.archived }">
              <td @click="showWordCloud(wordcloud.id)" title="Click para editar">{{ wordcloud.description.length > 80 ? wordcloud.description.substring(0, 80) + '...' : wordcloud.description }}</td>
              <td @click="showWords(wordcloud.id)">{{ wordcloud.words_group.length }} <i class="bx bx-window-open"></i></td>
              <td>
                <b-form-checkbox v-model="wordcloud.status" switch @change="toggleStatus(wordcloud.id)" :disabled="wordcloud.archived ? true : false"></b-form-checkbox>
              </td>
              <td>
                <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal font-size-18"></i>
                  </template>
                  <b-dropdown-item @click="showWordCloud(wordcloud.id)">
                    <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="toggleStatus(wordcloud.id, 1)">
                    <i class="fas fa-archive mr-1" :class="{'text-success': wordcloud.archived === 1, 'text-danger': wordcloud.archived === 0}"></i> {{ wordcloud.archived === 1 ? 'Desarquivar' : 'Arquivar' }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteWordCloud(wordcloud.id)">
                    <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="showWordResult(wordcloud.id)">
                    <i class="fas fa-chart-bar text-primary mr-1"></i> Visualizar
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-4" v-show="words_cloud.length > 0">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
          </ul>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-center"
      ref="modal-wordCloud"
      :title="wordCloud.id ? 'Editar Nuvem de Palavra' : 'Cadastrar Nuvem de Palavra'"
      title-class="font-18"
      size="lg"
      :no-close-on-backdrop="true"
    >
      <form @submit.prevent="createWordCloud()">
        <div class="row mt-2">
          <div class="col-sm-8">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="description">Pergunta</label>
                  <input
                    v-model="wordCloud.description"
                    type="text"
                    class="form-control"
                    placeholder="Exemplo: Qual o seu sentimento hoje?"
                    :class="{
                      'is-invalid': submitted && $v.wordCloud.description.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.wordCloud.description.required"
                    class="invalid-feedback"
                  >
                  O campo descrição é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="word_limit">Palavras por Usuário</label>
                  <select
                    class="custom-select"
                    name="word_limit"
                    id="word_limit"
                    v-model="wordCloud.word_limit"
                    :class="{
                      'is-invalid': submitted && $v.wordCloud.word_limit.$error,
                    }">
                    <option value="0">Sem limites</option>
                    <option :value="w" v-for="(w, i) in wordLimits" :key="i">{{ w }} {{ w === 1 ? 'Palavra' : 'Palavras'}}</option>
                  </select>
                  <div
                    v-if="submitted && !$v.wordCloud.word_limit.required"
                    class="invalid-feedback"
                  >
                  Palavras por usuário é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="block_bad_words">Filtro de Palavrões</label>
                  <select
                    class="custom-select"
                    name="block_bad_words"
                    disabled
                    id="block_bad_words"
                    v-model="wordCloud.block_bad_words">
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="description">Bloquear Palavras</label>
                <textarea
                  v-model="wordCloud.bad_words"
                  type="text"
                  rows="5"
                  placeholder="Separe por virgula"
                  class="form-control"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeModal()">Cancelar</b-button>
        <b-button variant="primary" @click="createWordCloud()">Salvar</b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-center"
      ref="modal-words"
      :title="wordCloud.description"
      title-class="font-18"
      size="lg"
      :no-close-on-backdrop="true"
    >
      <div class="row">
        <div class="col-sm-12">
          <div class="table-responsive mt-3 mb-0">
            <table class="table table-centered table-nowrap table-hover">
              <thead>
                <tr>
                  <th width="60%">Palavra</th>
                  <th>Qtd</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(word, i) in wordCloud.words_group" :key="i">
                  <td>{{ word.word }}</td>
                  <td width="7%">{{ word.total }}</td>
                  <td width="5%">
                    <i class="fas fa-trash-alt text-danger mr-1 link" @click="deleteWord(wordCloud.id, i, word.word)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeModalWords()">Fechar</b-button>
      </template>
    </b-modal>
    <modal-word-result v-model="modalWordResult" ref="resultWord"></modal-word-result>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage, warningMessage } from '@/components/alertsMessages'
import modalWordResult from '@/components/modals/WordResult'

export default {
  components: {
    modalWordResult
  },
  name: 'word-cloud',
  data () {
    return {
      submitted: false,
      params: {},
      modalWordResult: false,
      wordCloud: {
        status: false,
        description: '',
        word_limit: 0,
        block_bad_words: 1,
        words: []
      },
      wordLimits: [1, 2, 3, 4, 5, 10],
      totalRows: 1,
      currentPage: 1,
      perPage: 20
    }
  },
  validations: {
    wordCloud: {
      word_limit: { required },
      description: { required }
    }
  },
  beforeMount () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  computed: {
    ...mapState('wordCloud', ['words_cloud', 'pagination']),
    ...mapState('wordCloud', { data: 'word_cloud' }),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('wordCloud', {
      fetchData: 'getList',
      fetchItem: 'show',
      postData: 'save',
      changeStatus: 'changeStatus',
      deleteItem: 'deleteWordCloud',
      deleteItemOption: 'deleteOption'
    }),
    cleanWordCloud () {
      this.wordCloud = { status: false, description: '', word_limit: 0, block_bad_words: 1, words: [] }
    },
    addItem () {
      const option = { option: '', picture: '' }
      this.wordCloud.options = [...this.wordCloud.options, option]
    },
    delItem (indice) {
      if (this.wordCloud.options.length === 1) {
        warningMessage('Atenção: Enquete deve conter ao menos uma opção!')
        return
      }
      let item = this.wordCloud.options[indice]
      if (item.hasOwnProperty('id')) {
        this.deleteOption(item.id, indice)
      } else {
        this.wordCloud.options.splice(indice, 1)
      }
    },
    newWordCloud () {
      this.$refs['modal-wordCloud'].show()
    },
    showWordCloud (id) {
      let loader = this.$loading.show()
      this.fetchItem({ room_id: this.$route.params.id, id }).then((res) => {
        this.$refs['modal-wordCloud'].show()
        this.wordCloud = this.data
        let bads = this.data.badWords.data.map((b, i) => {
          return b.word
        })
        this.wordCloud.bad_words = bads.join(', ')
        loader.hide()
      })
    },
    showWordResult (id) {
      this.$refs.resultWord.open(id)
      this.modalWordResult = true
    },
    showWords (id) {
      let loader = this.$loading.show()
      this.fetchItem({ room_id: this.$route.params.id, id }).then((res) => {
        this.$refs['modal-words'].show()
        this.wordCloud = this.data
        loader.hide()
      })
    },
    closeModal () {
      this.$refs['modal-wordCloud'].hide()
      this.cleanWordCloud()
      this.$v.$reset()
    },
    closeModalWords () {
      this.$refs['modal-words'].hide()
      this.cleanWordCloud()
    },
    deleteWord (id, index, word) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItemOption({ room_id: this.$route.params.id, id, word }).then((res) => {
              successMessage('Sucesso: Opção excluída com sucesso.', 2500)
              this.wordCloud.words_group.splice(index, 1)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    deleteWordCloud (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem({ room_id: this.$route.params.id, id: id }).then((res) => {
              successMessage('Sucesso: Nuvem de palavra excluída com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    async toggleStatus (id, status = '') {
      try {
        await this.changeStatus({ room_id: this.$route.params.id, id, status })
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async createWordCloud () {
      this.submitted = true
      this.wordCloud.room_id = this.$route.params.id
      this.$v.wordCloud.$touch()
      if (this.$v.wordCloud.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      await this.postData(this.wordCloud).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        loader.hide()
        this.closeModal()
        this.updateList()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    },
    updateList () {
      let loader = this.$loading.show()
      this.fetchData({ room_id: this.$route.params.id, limit: this.perPage, page: this.currentPage, include: 'words' }).then(() => {
        loader.hide()
      })
    }
  },
  watch: {
    // currentPage () { this.updateList() },
    // total () { this.updateList() },
    dataPerPage () { this.updateList() }
  }
}
</script>

<style scoped>
.table-responsive { min-height: 400px;}
.dropzone {min-height: 60px !important;}
.thumb-poll { max-width: 60px !important; max-height: 60px !important;}
.rounded-drop .dropdown-toggle { border-radius: 1rem !important}
.title-asking { font-weight: 600;}
.poll-archived { color: #909090; background: #fff0f0}
.asking {
  background: #f6f6f6;
  border-top: 1px solid #e2e2e2;
  margin-top: 0px;
  padding:1rem;
}
.swal2-popup {
  font-size: 11px !important;
}
.mt-15 { margin-top:15px }
</style>
