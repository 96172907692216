<template>
  <div style="margin-left:-15px">
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="text-sm-right">
          <button type="button" class="btn btn-success btn-sm btn-rounded" @click="newStreaming()">
            <i class="mdi mdi-plus"></i> Inserir Transmissão
          </button>
        </div>
      </div>
      <!-- end col-->
    </div>
    <div class="row">
      <div class="col-md-12 text-center" v-show="streamings.length === 0">
        <h5 class="text-truncate font-size-14 p-4 mt-2">Nenhuma transmissão configurada. </h5>
        <i class="bx bx-tired bx-lg"></i>
        <br />
      </div>
      <div class="table-responsive mt-3 mb-0" v-show="streamings.length > 0">
        <table class="table table-centered table-nowrap table-hover">
          <thead>
            <tr>
              <th width="10%">Tipo</th>
              <th width="10%">Idioma</th>
              <th width="10%">Principal</th>
              <th width="35%">URL</th>
              <th width="15%">Meeting ID</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(s, i) in streamings" :key="i" class="link" :class="{ 'streaming-archived': s.archived }">
              <td @click="showStreaming(s.id)" title="Click para editar">{{ streaming_methods[s.streaming_method] }}</td>
              <td>{{ s.streaming_lang }}</td>
              <td>{{ s.streaming_main ? 'SIM' : 'NÂO'}}</td>
              <td>{{ s.url_video }}</td>
              <td>{{ s.meeting_id }}</td>
              <td>
                <b-form-checkbox v-model="s.status" switch @change="toggleStatus(s.id)" :disabled="s.archived ? true : false"></b-form-checkbox>
              </td>
              <td>
                <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal font-size-18"></i>
                  </template>
                  <b-dropdown-item @click="showStreaming(s.id)">
                    <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="toggleStatus(s.id, 1)">
                    <i class="fas fa-archive mr-1" :class="{'text-success': s.archived === 1, 'text-danger': s.archived === 0}"></i> {{ s.archived === 1 ? 'Desarquivar' : 'Arquivar' }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteStreaming(s.id)">
                    <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-4" v-show="streamings.length > 0">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
          </ul>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-center"
      ref="modal-streaming"
      :title="streaming.id ? 'Editar Transmissão' : 'Cadastrar Transmissão'"
      title-class="font-18"
      size="lg"
      :no-close-on-backdrop="true"
    >
      <form @submit.prevent="createStreaming()">
        <div class="row mt-2">
          <div class="col-sm-3">
            <div class="form-group">
              <label for="streaming_method">Meio de Transmissão</label>
              <select
                class="custom-select"
                name="streaming_method"
                id="streaming_method"
                v-model="streaming.streaming_method"
                :class="{ 'is-invalid': submitted && $v.streaming.streaming_method.$error }">
                <option value="1">Vimeo</option>
                <option value="2">Youtube</option>
                <option value="3">Próprio</option>
                <option value="4">Zoom</option>
              </select>
              <div
                v-if="submitted && !$v.streaming.streaming_method.required"
                class="invalid-feedback"
              >
                O campo meio de transmissão é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lang">Idioma</label>
              <select
                class="custom-select"
                name="lang"
                id="lang"
                v-model="streaming.streaming_lang"
                :class="{ 'is-invalid': submitted && $v.streaming.streaming_lang.$error }">
                <option value="PT-BR">Português (BR)</option>
                <option value="EN-US">Inglês (US)</option>
                <option value="ES-ES">Espanhol (ES)</option>
                <option value="FR-FR">Francês (FR)</option>
              </select>
              <div
                v-if="submitted && !$v.streaming.streaming_lang.required"
                class="invalid-feedback"
              >
                O campo idioma é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-6" v-if="parseInt(streaming.streaming_method) !== 4">
            <div class="form-group">
              <label for="url_video">Link de Transmissão</label>
              <input
                id="url_video"
                v-model="streaming.url_video"
                name="url_video"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.streaming.url_video.$error }"
              />
              <div
                v-if="submitted && !$v.streaming.url_video.required"
                class="invalid-feedback"
              >
                O campo link de transmissão é obrigatório
              </div>
            </div>
          </div>

          <div class="col-sm-4" v-if="parseInt(streaming.streaming_method) === 4">
            <div class="form-group">
              <label for="meeting_id">Meeting ID</label>
              <input
                id="meeting_id"
                v-model="streaming.meeting_id"
                name="meeting_id"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.streaming.meeting_id.$error }"
              />
              <div
                v-if="submitted && !$v.streaming.meeting_id.required"
                class="invalid-feedback"
              >
                O campo ID Meeting é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-2" v-if="parseInt(streaming.streaming_method) === 4">
            <div class="form-group">
              <label for="meeting_pass">Senha</label>
              <input
                id="meeting_pass"
                v-model="streaming.meeting_pass"
                name="meeting_pass"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6" v-if="parseInt(streaming.streaming_method) === 4">
            <div class="form-group">
              <label for="meeting_host_user_id">Anfritião - Meeting</label>
              <multiselect
                  tag-placeholder="Selecione um anfritião"
                  placeholder="Selecione um anfritião"
                  v-model="hostSelected"
                  :options="optionsUsers"
                  @search-change="onSearch"
                  :searchable="true"
                  class="helo"
                  selectLabel="Click para selecionar"
                  selectedLabel="Selecionado"
                  deselectLabel="Click para remover"
                  :loading="isLoading"
                  label="name"
                  track-by="id"
                  :max-height="180"
                  open-direction="bottom"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :class="{ 'is-invalid': submitted && $v.streaming.meeting_host_user_id.$error }"
                >
              </multiselect>
              <div
                v-if="submitted && !$v.streaming.meeting_host_user_id.required"
                class="invalid-feedback"
              >
                Você precisa seleciona um Anfritião
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <b-form-checkbox
            class="mb-3"
            v-model="streaming.streaming_main"
            plain
            >Tornar essa transmissão como principal</b-form-checkbox>
          </div>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeModal()">Cancelar</b-button>
        <b-button variant="primary" @click="createStreaming()">Salvar</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import 'cropperjs/dist/cropper.css'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  name: 'streamings',
  data () {
    return {
      submitted: false,
      hostSelected: '',
      isLoading: false,
      params: {},
      streaming: {
        status: false
      },
      streaming_methods: ['', 'Vimeo', 'Youtube', 'Próprio', 'Zoom Meeting'],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      optionsUsers: []
    }
  },
  validations () {
    if (parseInt(this.streaming.streaming_method) === 4) {
      return {
        streaming: {
          streaming_method: { required },
          streaming_lang: { required },
          meeting_id: { required },
          meeting_host_user_id: { required }
        }
      }
    } else {
      return {
        streaming: {
          streaming_method: { required },
          streaming_lang: { required },
          url_video: { required }
        }
      }
    }
  },
  beforeMount () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  computed: {
    ...mapState('streaming', ['streamings', 'pagination']),
    ...mapState('streaming', { data: 'streaming' }),
    ...mapState('participant', ['participants']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('streaming', {
      fetchData: 'getList',
      fetchItem: 'show',
      postData: 'save',
      changeStatus: 'changeStatus',
      deleteItem: 'deleteStreaming'
    }),
    ...mapActions('participant', { getParticipants: 'getAll' }),
    cleanStreaming () {
      this.streaming = {}
    },
    newStreaming () {
      this.$refs['modal-streaming'].show()
    },
    showStreaming (id) {
      this.initParticipants()
      this.fetchItem({ room_id: this.$route.params.id, id }).then((res) => {
        this.$refs['modal-streaming'].show()
        this.streaming = this.data
      })
    },
    closeModal () {
      this.$refs['modal-streaming'].hide()
      this.cleanStreaming()
      this.$v.$reset()
    },
    deleteStreaming (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem({ room_id: this.$route.params.id, id: id }).then((res) => {
              successMessage('Sucesso: Transmissão excluída com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    async toggleStatus (id, status = '') {
      try {
        await this.changeStatus({ room_id: this.$route.params.id, id, status })
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async createStreaming () {
      this.submitted = true
      this.streaming.room_id = this.$route.params.id
      this.$v.streaming.$touch()
      if (this.$v.streaming.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      await this.postData(this.streaming).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.closeModal()
        this.updateList()
        loader.hide()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    },
    updateList () {
      this.fetchData({ room_id: this.$route.params.id, limit: this.perPage, page: this.currentPage, include: 'options' })
    },
    initParticipants (param = '') {
      this.getParticipants(param).then((res) => {
        this.optionsUsers = this.participants
      }).catch(error => {
        console.log(error)
      })
    },
    onSearch (search, loading) {
      this.isLoading = true
      this.search(search, this)
    },
    // eslint-disable-next-line no-undef
    search: _.debounce((search, vm) => {
      vm.initParticipants({ search: search.normalize('NFD').replace(/[\u0300-\u036f]/g, '') })
      vm.isLoading = false
    }, 350)
  },
  watch: {
    hostSelected (value) {
      if (value) {
        this.streaming.meeting_host_user_id = value.id
      }
    },
    'streaming.meeting_host_user_id' (value) {
      this.hostSelected = this.optionsUsers.filter(o => o.id === value)[0]
    }
    // currentPage () { this.updateList() },
    // total () { this.updateList() },
    // dataPerPage () { this.updateList() }
  }
}
</script>

<style scoped>
.streaming-archived { color: #909090; background: #fff0f0}
.mt-15 { margin-top:15px }
</style>
