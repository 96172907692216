var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_vm._m(0),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-centered table-nowrap table-hover"},[_vm._m(1),_c('tbody',_vm._l((_vm.notificationList),function(list){return _c('tr',{key:list.id},[_c('td',[_vm._v(_vm._s(list.title))]),_c('td',[_vm._v(_vm._s(list.message))]),_c('td',[_c('span',{staticClass:"badge badge-pill font-size-12",class:{
                    'badge-soft-success': `${parseInt(list.status)}` === '1',
                    'badge-soft-dark': `${parseInt(list.status)}` === '0',
                  }},[_vm._v(_vm._s(_vm.statusLabel[list.status]))])]),_c('td',[_vm._v(_vm._s(list.send_data ? _vm.$moment(list.send_data).format('DD/MM/Y HH:mm') : ``))]),_c('td',[(!list.status)?_c('i',{staticClass:"bx bx-send mr-1 link",class:{
                    'color-green': `${parseInt(list.status)}` === '1',
                    'color-default': `${parseInt(list.status)}` === '0',
                  },on:{"click":function($event){return _vm.sendNotification(list.id)}}}):_vm._e()])])}),0)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"dataTables_paginate paging_simple_numbers float-right"},[_c('ul',{staticClass:"pagination pagination-rounded mb-0"},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage},on:{"input":_vm.updateList},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-sm-6"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"width":"25%"}},[_vm._v("Título")]),_c('th',{attrs:{"width":"40%"}},[_vm._v("Mensagem")]),_c('th',{attrs:{"width":"10%"}},[_vm._v("Status")]),_c('th',{attrs:{"width":"10%"}},[_vm._v("Enviado")]),_c('th',{attrs:{"width":"5%"}},[_vm._v("Ações")])])])
}]

export { render, staticRenderFns }