<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12">
          <div class="row mb-2">
            <div class="col-sm-12">
              <div class="text-sm-right">
                <button type="button" class="btn btn-success btn-sm btn-rounded mr-2" @click="newFeed()">
                  <i class="mdi mdi-plus mr-1"></i> Novo Feed
                </button>
              </div>
            </div>
            <!-- end col-->
          </div>
          <div class="table-responsive">
            <table class="table project-list-table table-nowrap table-centered table-borderless">
              <thead>
                <tr>
                  <th scope="col" style="width: 20px"></th>
                  <th scope="col" style="width: 20px"></th>
                  <th scope="col" width="30%">Feed</th>
                  <th scope="col" width="10%">Post publico</th>
                  <th scope="col" width="10%">Curtir</th>
                  <th scope="col" width="10%">Comentar</th>
                  <th scope="col">Status</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <draggable
                v-model="feeds"
                :element="'tbody'"
                tag="tbody"
                :options="{animation:200, handle:'.mover'}"
                @change="updateSequence"
              >
                <tr v-for="(feed, i) in feeds" :key="i" class="link">
                  <td class="mover"><i class="bx bx-move"></i></td>
                  <td class="mover">
                    <span class="badge font-size-13 badge-soft-primary p-1 badge-num">{{ feed.sequence }}</span>
                  </td>
                  <td @click="editFeed(feed.id)">
                    <h5 class="text-truncate font-size-14">
                      <a href="javascript: void(0);" class="text-dark">{{ feed.name }}</a>
                    </h5>
                  </td>
                  <td @click="editFeed(feed.id)">
                    <span class="badge badge-pill font-size-12" :class="{
                        'badge-soft-success': `${parseInt(feed.can_post)}` === '1',
                        'badge-soft-danger': `${parseInt(feed.can_post)}` === '0',
                      }">{{ feed.can_post ? 'SIM' : 'NÃO' }}</span>
                  </td>
                  <td @click="editFeed(feed.id)">
                    <span class="badge badge-pill font-size-12" :class="{
                        'badge-soft-success': `${parseInt(feed.can_like)}` === '1',
                        'badge-soft-danger': `${parseInt(feed.can_like)}` === '0',
                      }">{{ feed.can_like ? 'SIM' : 'NÃO' }}</span>
                  </td>
                  <td @click="editFeed(feed.id)">
                    <span class="badge badge-pill font-size-12" :class="{
                        'badge-soft-success': `${parseInt(feed.can_comment)}` === '1',
                        'badge-soft-danger': `${parseInt(feed.can_comment)}` === '0',
                      }">{{ feed.can_comment ? 'SIM' : 'NÃO' }}</span>
                  </td>
                  <td @click="editFeed(feed.id)">
                    <span class="badge badge-pill font-size-12" :class="{
                        'badge-soft-success': `${parseInt(feed.status)}` === '1',
                        'badge-soft-danger': `${parseInt(feed.status)}` === '0',
                      }">{{ feed.can_comment ? 'ATIVO' : 'INATIVO' }}</span>
                  </td>
                  <td>
                    <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>
                        <b-dropdown-item @click="editFeed(feed.id)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="toggleStatus(feed.id)">
                          <i class="fas fa-circle mr-1" :class="{ 'text-danger': parseInt(feed.status) === 1, 'text-success': parseInt(feed.status) === 0 }"></i> {{ feed.status ? 'Inativar' : 'Ativar' }}</b-dropdown-item>
                        <b-dropdown-item @click="deleteFeed(feed.id)">
                          <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                        </b-dropdown-item>
                      </b-dropdown>
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <modal-form v-model="modal" ref="modalForm" @created="updateList"></modal-form>
      </div>
  </Layout>
</template>
<script>
import draggable from 'vuedraggable'
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState, mapGetters } from 'vuex'
import ModalForm from './Form'

export default {
  components: {
    draggable,
    Layout,
    PageHeader,
    ModalForm
  },
  name: 'setting_feed',
  page: {
    title: 'Configuração do Feed',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      params: {},
      totalRows: 1,
      modal: false,
      currentPage: 1,
      perPage: 20,
      statusLabel: ['Inativo', 'Ativo'],
      imgSrc: '',
      speakersList: [],
      cropImg: '',
      submitted: false,
      title: 'Configuração do Feed',
      items: [{
        text: 'Parametrização'
      },
      {
        text: 'Configuração do Feed',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapGetters('settingFeed', { data: 'FEEDS' }),
    ...mapState('settingFeed', ['pagination']),
    rows () {
      return this.pagination.total
    },
    feeds: {
      get () {
        return this.data
      },
      set (value) {
        let loader = this.$loading.show()
        this.updateSequence({ itens: value }).then(res => {
          loader.hide()
          successMessage('Sucesso: Ordem atualizada.', 2500)
        })
      }
    }
  },
  methods: {
    ...mapActions('settingFeed', {
      getList: 'list',
      changeStatus: 'changeStatus',
      deleteItem: 'destroy',
      updateSequence: 'orderList'
    }),
    newFeed () {
      this.$refs.modalForm.open()
      this.modal = true
    },
    editFeed (id) {
      this.$refs.modalForm.open(id)
      this.modal = true
    },
    async toggleStatus (id) {
      try {
        await this.changeStatus(id)
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    deleteFeed (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem(id).then((res) => {
              successMessage('Sucesso: Feed excluído com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    updateList () {
      let loader = this.$loading.show()
      this.getList({ limit: this.perPage, page: this.currentPage }).then(() => {
        loader.hide()
      })
    }
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 400px;}
</style>
