<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveSetting()" autocomplete="off">
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label for="objetivo">Objetivo</label>
                <multiselect
                  v-model="selected"
                  @input="setSetting"
                  :options="settings" class="helo"
                  :max-height="180"
                  trackBy="value"
                  label="label"
                  placeholder="Selecione um objetivo"
                  :class="{
                    'is-invalid': submitted && $v.gamification.gamification_id.$error
                  }"
                >
                </multiselect>
                <div
                  v-if="submitted && !$v.gamification.gamification_id.required"
                  class="invalid-feedback"
                >
                  O campo nome é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="points">Pontos</label>
                <input
                  id="points"
                  v-model="gamification.points"
                  name="points"
                  type="number"
                  class="form-control text-right"
                  :class="{
                    'is-invalid': submitted && $v.gamification.points.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.gamification.points.required"
                  class="invalid-feedback"
                >
                  O campo email é obrigatório.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveSetting()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import http from '@/http'

export default {
  components: {
    Loading,
    Multiselect
  },
  props: {
    idGamification: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Objetivo',
      loading: true,
      selected: '',
      fullPage: true,
      submitted: false,
      settings: [],
      gamification: {
        gamification_id: '',
        points: 0
      },
      modal: false
    }
  },
  validations: {
    gamification: {
      gamification_id: { required },
      points: { required }
    }
  },
  beforeMount () {
    this.gamificationSettings()
  },
  methods: {
    ...mapActions('gamification', {
      getGamification: 'show',
      postData: 'save'
    }),
    async gamificationSettings () {
      await http.get('/admin/selects/gamification-settings').then((res) => {
        this.settings = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    setSetting (value) {
      if (typeof (value) === 'object' && value) {
        this.gamification.gamification_id = value.value
        return
      }
      this.gamification.gamification_id = ''
    },
    loadItem (id) {
      if (id) {
        this.title = 'Editar Objetivo'
        this.getGamification(id).then((data) => {
          this.gamification = this.data
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveSetting () {
      this.submitted = true
      console.log(this.gamification)
      console.log(this.$v.gamification)

      this.$v.gamification.$touch()
      if (this.$v.gamification.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.gamification).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.submitted = false
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.selected = ''
      this.submitted = false
      this.gamification = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('gamification', { data: 'gamification' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    },
    'gamification.gamification_id' (value) {
      this.selected = this.settings.filter(o => o.value === value)[0]
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
