<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="applyFilter()" autocomplete="off">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="room_id">Sala</label>
                <room-select
                  id="room_id"
                  :value="roomSelected"
                  @input="setRoom"
                >
                </room-select>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="district">Pergunta</label>
                <multiselect
                  v-model="askingSelected"
                  :options="askings" class="helo"
                  selectLabel="Click para selecionar"
                  selectedLabel="Selecionado"
                  deselectLabel="Click para remover"
                  :max-height="180"
                  trackBy="value"
                  label="label"
                  placeholder="Selecione"
                >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.label }}</span><br/>
                    <span class="option__small">Sala: {{ props.option.room_name }}</span>
                  </div>
                </template>
                </multiselect>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Fechar</b-button>
      <b-button class="btn btn-dark" @click="clean()">Limpar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="applyFilter()">Aplicar</button>
    </template>
  </b-modal>
</template>
<script>
import Multiselect from 'vue-multiselect'
import roomSelect from '@/components/selects/room-select'
import http from '@/http'

export default {
  name: 'quiz-filter',
  components: {
    Multiselect,
    roomSelect
  },
  props: {},
  data () {
    return {
      title: 'Filtro - Quiz',
      askingSelected: '',
      roomSelected: '',
      askings: [],
      filter: {},
      modal: false
    }
  },
  beforeMount () {
    this.quizAskings()
  },
  methods: {
    open () {
      this.modal = true
      if (this.filter.room_id) {
        this.roomSelected = this.filter.room_id
      }
    },
    async quizAskings (param = '') {
      await http.get('/admin/selects/quiz-askings', { params: param }).then((res) => {
        this.askings = res.data
        if (this.filter.room_id) {
          this.roomSelected = this.filter.room_id
        }
      }).catch(error => {
        console.log(error)
      })
    },
    setRoom (value) {
      this.filter.room_id = value
      this.quizAskings({ room_id: value })
    },
    applyFilter () {
      if (typeof (this.askingSelected) === 'object') {
        this.filter.quiz_id = this.askingSelected.value
      }
      this.$emit('quizFiltered', this.filter)
      this.close()
    },
    close () {
      this.modal = false
    },
    clean () {
      this.filter = {}
      this.askingSelected = ''
      this.roomSelected = ''
    }
  },
  computed: {},
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
