<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveCategory()" autocomplete="off">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="name">Nome</label>
                <input
                  id="name"
                  v-model="category.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.category.name.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.category.name.required"
                  class="invalid-feedback"
                >
                  O campo nome é obrigatório.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="table-responsive mt-3 mb-0" v-show="categories.length > 0">
          <table class="table table-centered table-nowrap table-hover">
            <thead>
              <tr>
                <th width="80%">Categoria</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(s, i) in categories" :key="i" class="link">
                <td @click="loadItem(s.id)" title="Click para editar">{{ s.name }}</td>
                <td><i class="fas fa-trash-alt text-danger mr-1" @click="deleteItem(s.id)"></i></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveCategory()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import Swal from 'sweetalert2'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import service from './services'

export default {
  components: {
    Loading
  },
  data () {
    return {
      title: 'Nova Categoria',
      loading: true,
      fullPage: true,
      submitted: false,
      categories: [],
      category: {},
      modal: false
    }
  },
  validations () {
    return {
      category: {
        name: { required }
      }
    }
  },
  methods: {
    getCategories () {
      service.getCategories().then(({ data }) => {
        this.categories = data
      })
    },
    loadItem (id) {
      if (id) {
        this.title = 'Editar Categoria'
        this.loading = true
        service.findCategory({ id: id }).then(({ data }) => {
          this.category = data
        }).then(() => {
          this.loading = false
        })
      }
    },
    deleteItem (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            service.deleteCategory({ id: id }).then((res) => {
              successMessage('Sucesso: Categoria excluída com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.getCategories()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.getCategories()
      this.loading = false
    },
    async saveCategory () {
      this.submitted = true
      this.$v.category.$touch()
      if (this.$v.category.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      this.category.partner_id = this.$route.params.id
      await service.saveCategory(this.category).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.getCategories()
        this.category = {}
        this.$v.$reset()
        this.loading = false
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.category = {}
      this.$v.$reset()
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
