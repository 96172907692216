<template>
  <b-modal v-model="modal" :title="title" size="lg" scrollable :no-enforce-focus="true" :no-close-on-backdrop="true">
    <!-- loading spinner -->
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>

    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveActivity()" autocomplete="off">
           <div class="row">
            <div class="col-sm-9">
              <div class="form-group">
                <label for="name">Nome da atividade</label>
                <input
                  id="name"
                  v-model="activity.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.activity.name.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.activity.name.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="points">Pontos</label>
                <input
                  id="points"
                  v-model="activity.points"
                  name="points"
                  type="number"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.activity.points.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.activity.points.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="description">Descrição</label>
                <textarea
                  id="name"
                  rows="2"
                  v-model="activity.description"
                  name="name"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="table-responsive mt-3 mb-0" v-show="activities && activities.length > 0">
          <table class="table table-centered table-nowrap table-hover">
            <thead>
              <tr>
                <th width="20%">Código</th>
                <th width="50%">Atividade</th>
                <th width="10%">Pontos</th>
                <th width="10%">Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(s, i) in activities" :key="i" class="link">
                <td @click="loadItem(s.id)" title="Click para editar">
                  <vue-qrcode
                    :value="s.code"
                    :options="{ width: 800 }"
                    tag="img"
                    :ref="'qr' + s.id"
                    style="display: none">
                  </vue-qrcode>
                  {{ s.code }}
                </td>
                <td @click="loadItem(s.id)" title="Click para editar">{{ s.name }}</td>
                <td @click="loadItem(s.id)" title="Click para editar">{{ s.points }}</td>
                <td class="green-custom-check">
                  <b-form-checkbox v-model="s.status" switch @change="toggleStatus(s.id)"></b-form-checkbox>
                </td>
                <td>
                  <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18"></i>
                    </template>
                    <b-dropdown-item @click="openQrCode(s.id)">
                      <i class="fas fa-print text-success mr-1"></i> Imprimir QRCode
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveActivity()">Salvar</button>
    </template>
  </b-modal>
</template>

<script>
// Import component
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { required } from 'vuelidate/lib/validators'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import service from './services'

export default {
  components: {
    Loading,
    VueQrcode
  },
  data () {
    return {
      activity: {},
      activities: [],
      title: 'Atividades presenciais',
      qrCodeSrc: '',
      loading: true,
      submitted: false,
      modal: false
    }
  },
  validations () {
    return {
      activity: {
        name: { required },
        points: { required }
      }
    }
  },
  methods: {
    openQrCode (id) {
      this.$nextTick(() => {
        const qrImg = this.$refs[`qr${id}`]
        if (qrImg && qrImg[0] && qrImg[0].$el) {
          const src = qrImg[0].$el.currentSrc
          const newWindow = window.open()
          newWindow.document.write(`<img src="${src}" alt="QR Code">`)
          newWindow.document.title = 'QR Code'
        } else {
          console.error('QR code src não está disponível.')
        }
      })
    },
    loadItem (id) {
      if (id) {
        this.title = 'Editar Atividade'
        this.loading = true
        service.findActivity({ id: id }).then(({ data }) => {
          this.activity = data.data
        }).finally(() => {
          this.loading = false
        })
      }
    },
    async toggleStatus (id) {
      try {
        await service.changeStatusActivity(id).then(() => {
          successMessage('Sucesso: Status atualizado com sucesso.', 2500)
          this.loadItems()
        }).catch((error) => {
          errorMessage('Alerta: ' + error.message)
        })
      } catch (err) {
        console.error(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    loadItems () {
      this.loading = true
      service.getActivities().then(({ data }) => {
        this.activities = data.data
      }).finally(() => {
        this.loading = false
      })
    },
    async saveActivity () {
      this.submitted = true
      this.loading = true
      await service.saveActivity(this.activity).then(() => {
        successMessage('Sucesso: Ação registrada com sucesso.', 2500)
        this.loadItems()
        this.activity = {}
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
      }).finally(() => {
        this.loading = false
      })
    },
    open () {
      this.modal = true
      this.loadItems()
    },
    close () {
      this.modal = false
      this.submitted = false
      this.activity = {}
      this.activities = {}
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
