<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6">
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newInput()">
                      <i class="mdi mdi-plus mr-1"></i> Novo campo
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th scope="col" style="width: 20px">#</th>
                    <th width="10%">Tipo</th>
                    <th width="20%">Identificação</th>
                    <th width="40%">Título do campo</th>
                    <th width="10%">Obrigatório</th>
                    <th width="10%">Status</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <draggable
                  v-model="menus"
                  :element="'tbody'"
                  tag="tbody"
                  :options="{animation:200, handle:'.mover'}"
                  @change="updateSequence"
                >
                  <tr v-for="list in menus" :key="list.id" class="link">
                    <td class="mover">
                      <span class="badge font-size-13 badge-soft-primary p-1 badge-num">{{ list.sequence }}</span>
                    </td>
                    <td class="mover">{{ list.type_input }}</td>
                    <td class="mover">{{ list.name }}</td>
                    <td class="mover">{{ list.label }}</td>
                    <td class="mover">{{ list.required ?  'SIM' : 'NÃO' }}</td>
                    <td class="mover">
                       <span class="badge badge-pill font-size-12" :class="{
                        'badge-soft-success': `${parseInt(list.status)}` === '1',
                        'badge-soft-danger': `${parseInt(list.status)}` === '0',
                      }">{{ statusLabel[list.status] }}</span>
                    </td>
                    <td>
                      <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>
                        <b-dropdown-item @click="editInput(list.id)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="toggleStatus(list.id)">
                          <i class="fas fa-circle mr-1" :class="{ 'text-danger': parseInt(list.status) === 1, 'text-success': parseInt(list.status) === 0 }"></i> {{ list.status ? 'Inativar' : 'Ativar' }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteInput(list.id)">
                          <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" :idInput="idInput" ref="formInput" @created="updateList"></modal-form>
  </Layout>
</template>
<script>
import Swal from 'sweetalert2'
import draggable from 'vuedraggable'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import ModalForm from './Form'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  components: {
    draggable,
    Layout,
    ModalForm,
    PageHeader
  },
  name: 'form',
  page: {
    title: 'Formulário de cadastro',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      modalForm: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      idInput: null,
      perPage: 20,
      statusLabel: ['Inativo', 'Ativo'],
      submitted: false,
      title: 'Formulário de cadastro',
      items: [{
        text: 'Formulário de cadastro'
      },
      {
        text: 'Configurações',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.GetSetting()
    this.loading = false
  },
  computed: {
    ...mapGetters('form', { data: 'FORMS' }),
    ...mapState('event', ['setting']),
    menus: {
      get () {
        return this.data
      },
      set (value) {
        let loader = this.$loading.show()
        this.updateSequence({ itens: value }).then(res => {
          loader.hide()
          successMessage('Sucesso: Ordem atualizada.', 2500)
        })
      }
    }
  },
  methods: {
    ...mapActions('form', {
      getAll: 'getAll',
      changeStatus: 'changeStatus',
      updateSequence: 'orderList',
      deleteInputs: 'deleteInput'
    }),
    ...mapActions('event', {
      GetSetting: 'GetSetting'
    }),
    newInput () {
      this.$refs.formInput.open('')
      this.modalForm = true
    },
    editInput (id) {
      this.$refs.formInput.open(id)
      this.modalForm = true
      this.idInput = id
    },
    async toggleStatus (id) {
      try {
        await this.changeStatus(id).then((res) => {
          let message = 'Sucesso: Status atualizado com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          this.updateList()
        }).catch((error) => {
          errorMessage('Alerta: ' + error.message)
        })
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    deleteInput (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteInputs(id).then((res) => {
              successMessage('Sucesso: Menu excluído com sucesso.', 2500)
              this.updateList()
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {}
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  },
  watch: {
    // currentPage () { this.updateList() }
    // total () { this.updateList() },
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 400px;}
.float-sm-end { float: right }
</style>
