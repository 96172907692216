<template>
  <div style="margin-left:-15px">
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="text-sm-right">
          <button type="button" class="btn btn-success btn-sm btn-rounded" @click="updateList()">
            <i class="mdi mdi-reload"></i> Atualizar
          </button>
        </div>
      </div>
      <!-- end col-->
    </div>
    <div class="row">
      <div class="col-md-12 text-center" v-show="publicAskings.length === 0">
        <h5 class="text-truncate font-size-14 p-4 mt-4">Ainda não recebemos perguntas. </h5>
        <i class="bx bx-tired bx-lg"></i>
      </div>
      <div class="table-responsive mt-3 mb-0" v-show="publicAskings.length > 0">
        <table class="table table-centered table-nowrap table-hover">
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Pergunta</th>
              <th>Votos</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(asking, i) in publicAskings" :key="i" class="link">
              <td @click="showAsking(asking.id)">{{ asking.user.data.name }}</td>
              <td @click="showAsking(asking.id)">{{ asking.user.data.email }}</td>
              <td @click="showAsking(asking.id)" title="Click para visualizar">{{ asking.asking.length > 10 ? asking.asking.substring(0, 10) + '...' : asking.asking }}</td>
              <td @click="showAsking(asking.id)">{{ asking.total_votes }}</td>
              <td>
                <b-dropdown
                  size="sm"
                  :variant="(parseInt(asking.status)) === 0 ? 'danger' : parseInt(asking.status) === 1 ? 'success' : 'primary'"
                  class="m-0 rounded-drop" :disabled="asking.status > 0">
                  <template v-slot:button-content >
                    {{ statusLabel[asking.status] }}
                    <i class="mdi mdi-chevron-down" v-if="asking.status === 0"></i>
                  </template>
                  <span v-if="asking.status === 0">
                    <!-- <b-dropdown-item-button @click="toggleStatus(asking.id, 0)">Não respondida</b-dropdown-item-button> -->
                    <b-dropdown-item-button @click="toggleStatus(asking.id, 1)">Respondida</b-dropdown-item-button>
                    <b-dropdown-item-button @click="toggleStatus(asking.id, 2)">Respondida ao vivo</b-dropdown-item-button>
                  </span>
                </b-dropdown>
              </td>
              <td>
                <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal font-size-18"></i>
                  </template>
                  <b-dropdown-item @click="showAsking(asking.id)">
                    <i class="fas fa-pencil-alt text-success mr-1"></i> Responder
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteAsking(asking.id)">
                    <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-4" v-show="publicAskings.length > 0">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-center"
      ref="modal-asking"
      title="Pergunta"
      title-class="font-18"
      :no-close-on-backdrop="true"
    >
      <div class="table-responsive mt-0">
        <table class="table table-nowrap mb-0" style="border-top:none !important">
          <tbody>
            <tr>
              <th scope="row" width="60px" style="border-top:none !important">Nome :</th>
              <td style="border-top:none !important">{{ replyAsking.user.data.name }}</td>
            </tr>
            <tr>
              <th scope="row" width="60px">E-mail :</th>
              <td>{{ replyAsking.user.data.email }}</td>
            </tr>
            <tr>
              <th scope="row" width="60px">Votos :</th>
              <td>{{ replyAsking.total_votes }}</td>
            </tr>
          </tbody>
        </table>
        <div class="asking ml-2">
          <p class="text-muted font-size-12">{{ replyAsking.asking }}</p>
          <span class="font-size-11 float-right text-muted"> {{ $moment(replyAsking.created_at).format('DD/MM/YYYY HH:mm') }} </span>
        </div>
        <form @submit.prevent="saveRoom()">
          <div class="col-sm-12 mt-2">
            <div class="form-group">
              <label for="answer">Resposta</label>
              <textarea
                :disabled="replyAsking.status > 0"
                v-model.lazy="replyAsking.answer"
                type="text"
                rows="3"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.replyAsking.answer.$error,
                }"
              ></textarea>
              <div
                v-if="submitted && !$v.replyAsking.answer.required"
                class="invalid-feedback"
              >
              O campo resposta é obrigatório
              </div>
            </div>
          </div>
        </form>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeModal()">Cancelar</b-button>
        <b-button variant="primary" @click="reply()" :disabled="replyAsking.status > 0">Responder</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
  },
  name: 'public-asking',
  data () {
    return {
      submitted: false,
      params: {},
      replyAsking: {
        asnwer: '',
        user: {
          data: {}
        }
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      statusLabel: ['Não respondida', 'Respondida', 'Respondida ao vivo']
    }
  },
  validations: {
    replyAsking: {
      answer: { required }
    }
  },
  beforeMount () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  computed: {
    ...mapState('publicAsking', ['publicAskings', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('publicAsking', {
      fetchData: 'getList',
      postData: 'replyAsking',
      changeStatus: 'changeStatus',
      deleteItem: 'deleteAsking'
    }),
    showAsking (id) {
      this.$refs['modal-asking'].show()
      this.replyAsking = this.publicAskings.filter(a => parseInt(a.id) === parseInt(id))[0]
    },
    closeModal () {
      this.$refs['modal-asking'].hide()
      this.$v.$reset()
    },
    deleteAsking (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem({ room_id: this.$route.params.id, id: id }).then((res) => {
              successMessage('Sucesso: Pergunta excluída com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    async reply () {
      this.submitted = true
      this.$v.replyAsking.$touch()
      if (this.$v.replyAsking.$invalid) {
        return
      }
      let loader = this.$loading.show()
      this.replyAsking.status = 1
      await this.postData(this.replyAsking).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.$refs['modal-asking'].hide()
        this.updateList()
        loader.hide()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    },
    async toggleStatus (id, status) {
      try {
        await this.changeStatus({ room_id: this.$route.params.id, id: id, status: status })
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async saveStreaming () {
      this.submitted = true
      this.transmission.room_id = this.$route.params.id
      this.$v.transmission.$touch()
      if (this.$v.transmission.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      await this.postData(this.transmission).then(res => {
        let message = 'Sucesso: Dados atualizados com sucesso.'
        let delay = 2500
        loader.hide()
        successMessage(message, delay)
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    },
    updateList () {
      let loader = this.$loading.show()
      this.fetchData({ room_id: this.$route.params.id, limit: this.perPage, include: 'user', page: this.currentPage }).then(() => {
        loader.hide()
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.rounded-drop .dropdown-toggle { border-radius: 1rem !important}
.title-asking { font-weight: 600;}
.asking {
  background: #f6f6f6;
  border-top: 1px solid #e2e2e2;
  margin-top: 0px;
  padding:1rem;
}
.swal2-popup {
  font-size: 11px !important;
}
</style>
