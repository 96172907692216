<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="applyFilter()" autocomplete="off">
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label for="feed">Feed</label>
                <select
                  name="feed"
                  id="feed"
                  class="form-control"
                  v-model="filter.feed"
                >
                  <option></option>
                  <option v-for="(g,idx) in feeds" :key="idx" :value="g.id">{{ g.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="user">Nome usuário</label>
                <input
                  id="user"
                  v-model="filter.user"
                  name="user"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="created_start">Publicados de</label>
                <date-picker
                  id="created_end"
                  v-model="filter.created_start"
                  :first-day-of-week="1"
                  lang="pt-br"
                  format="DD/MM/YYYY"
                >
                </date-picker>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="created_end">Publicados até</label>
                <date-picker
                  id="created_end"
                  v-model="filter.created_end"
                  :first-day-of-week="1"
                  lang="pt-br"
                  format="DD/MM/YYYY"
                >
                </date-picker>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Fechar</b-button>
      <b-button class="btn btn-dark" @click="clean()">Limpar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="applyFilter()">Aplicar</button>
    </template>
  </b-modal>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import service from '@/pages/event/feed/services'

export default {
  name: 'participant-filter',
  components: {
    DatePicker
  },
  props: {},
  data () {
    return {
      title: 'Filtro - Feed',
      selected: '',
      feeds: [],
      filter: {},
      modal: false
    }
  },
  methods: {
    getFeeds () {
      service.list().then(({ data }) => {
        this.feeds = data.data
      })
    },
    open () {
      this.modal = true
      this.getFeeds()
    },
    applyFilter () {
      this.$emit('feedFiltered', this.filter)
      this.close()
    },
    close () {
      this.modal = false
    },
    clean () {
      this.filter = {}
      this.selected = ''
    }
  },
  computed: {
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.form-check-label { padding-top: 2px !important }
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
