<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row mb-2">
                <!-- <div class="col-sm-4">
                  <div class="search-box mr-2 mb-2 d-inline-block">
                    <div class="position-relative">
                      <input type="text" class="form-control" placeholder="Search..." />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div> -->
                <div class="col-sm-12">
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-dark btn-sm btn-rounded mr-2" @click="openCategory()">
                      <i class="mdi mdi-plus"></i> Categorias
                    </button>
                    <button type="button" class="btn btn-success btn-sm btn-rounded mr-2" @click="newFAQ()">
                      <i class="mdi mdi-plus mr-1"></i> Novo FAQ
                    </button>
                  </div>
                </div>
                <!-- end col-->
              </div>
              <div class="table-responsive">
                <table class="table table-centered table-nowrap table-hover">
                  <thead>
                    <tr>
                      <th scope="col" width="15%">Categoria</th>
                      <th scope="col" width="45%">Pergunta</th>
                      <th scope="col" width="40%">Resposta</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(faq, i) in faqs" :key="i" class="link">
                      <td @click="showFAQ(faq.id)">
                        {{ faq.category ? faq.category.name : 'Sem categoria' }}
                      </td>
                      <td @click="showFAQ(faq.id)">{{ faq.asking.length > 100 ? faq.asking.substring(0, 100) + '...' : faq.asking }}</td>
                      <td @click="showFAQ(faq.id)">{{ faq.answer.length > 80 ? faq.answer.substring(0, 80) + '...' : faq.answer }}</td>
                      <td>
                        <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                            <template v-slot:button-content>
                              <i class="mdi mdi-dots-horizontal font-size-18"></i>
                            </template>
                            <b-dropdown-item @click="showFAQ(faq.id)">
                              <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                            </b-dropdown-item>
                            <b-dropdown-item @click="deleteFAQ(faq.id)">
                              <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                            </b-dropdown-item>
                          </b-dropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <category v-model="modalCategory" ref="modalCategory"></category>
      <b-modal
      id="modal-center"
      ref="modal-faq"
      title="FAQ"
      size="lg"
      title-class="font-18"
      :no-close-on-backdrop="true"
      >
      <div class="table-responsive mt-0">
        <form @submit.prevent="saveFAQ()">
          <div class="col-sm-4">
            <div class="form-group">
              <label for="faq_category_id">Categoria</label>
              <select
                v-model.lazy="faq.faq_category_id"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.faq.faq_category_id.$error,
                }"
              >
              <option v-for="(category,idx) in categories" :key="idx" :value="category.id">{{ category.name }}</option>
              </select>
              <div
                v-if="submitted && !$v.faq.faq_category_id.required"
                class="invalid-feedback"
              >
              O campo é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-12 mt-2">
            <div class="form-group">
              <label for="answer">Pergunta</label>
              <textarea
                v-model.lazy="faq.asking"
                type="text"
                rows="4"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.faq.asking.$error,
                }"
              ></textarea>
              <div
                v-if="submitted && !$v.faq.asking.required"
                class="invalid-feedback"
              >
              O campo é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-12 mt-2">
            <div class="form-group">
              <label for="answer">Resposta</label>
              <textarea
                v-model.lazy="faq.answer"
                type="text"
                rows="5"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.faq.answer.$error,
                }"
              ></textarea>
              <div
                v-if="submitted && !$v.faq.answer.required"
                class="invalid-feedback"
              >
              O campo é obrigatório
              </div>
            </div>
          </div>
        </form>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeModal()">Cancelar</b-button>
        <b-button variant="primary" @click="saveFAQ()">Salvar</b-button>
      </template>
    </b-modal>
  </Layout>
</template>
<script>
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import Category from './Category'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import service from './services'

export default {
  components: {
    Layout,
    PageHeader,
    Category
  },
  name: 'faqs',
  page: {
    title: 'FAQ',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      params: {},
      totalRows: 1,
      modalCategory: false,
      currentPage: 1,
      perPage: 20,
      statusLabel: ['Inativo', 'Ativo'],
      categories: [],
      faq: {},
      submitted: false,
      title: 'FAQ',
      items: [{
        text: 'FAQ'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  validations: {
    faq: {
      faq_category_id: { required },
      asking: { required },
      answer: { required }
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.total
    this.currentPage = this.current_page
    this.loading = false
  },
  computed: {
    ...mapState('faq', ['faqs', 'pagination']),
    ...mapState('faq', { dataf: 'faq' }),
    rows () {
      return this.total
    }
  },
  methods: {
    ...mapActions('faq', {
      getList: 'list',
      fetchItem: 'show',
      postData: 'save',
      deleteItem: 'destroy'
    }),
    cleanFaq () {
      this.faq = {}
    },
    showFAQ (id) {
      let loader = this.$loading.show()
      this.getCategories()
      this.fetchItem(id).then((res) => {
        this.$refs['modal-faq'].show()
        this.faq = this.dataf
        loader.hide()
      })
    },
    getCategories () {
      service.getCategories().then(({ data }) => {
        this.categories = data
      })
    },
    openCategory () {
      this.$refs.modalCategory.open()
    },
    newFAQ () {
      this.getCategories()
      this.$refs['modal-faq'].show()
    },
    closeModal () {
      this.$refs['modal-faq'].hide()
      this.cleanFaq()
      this.$v.$reset()
    },
    deleteFAQ (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem(id).then((res) => {
              successMessage('Sucesso: Sala excluído com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    async saveFAQ () {
      this.submitted = true
      this.$v.faq.$touch()
      if (this.$v.faq.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      console.log('chamou1')

      await this.postData(this.faq).then(res => {
        let message = 'Sucesso: Dados atualizados com sucesso.'
        let delay = 2500
        loader.hide()
        successMessage(message, delay)
        this.closeModal()
        this.updateList()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    },
    updateList () {
      let loader = this.$loading.show()
      this.getList({ limit: this.perPage, include: 'category', page: this.currentPage }).then(() => {
        loader.hide()
      })
    }
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.avatar-thumb { height: 4rem; width: 6rem;}
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 400px;}
</style>
