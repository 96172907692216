<template>
  <div style="margin-left:-15px">
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="text-sm-right">
          <button type="button" class="btn btn-success btn-sm btn-rounded" @click="exporPartnerContacts()">
            <i class="mdi mdi-plus"></i> Exportar
          </button>
        </div>
      </div>
      <!-- end col-->
    </div>
    <div class="row">
      <div class="col-md-12 text-center" v-show="contacts.length === 0">
        <h5 class="text-truncate font-size-14 p-4 mt-2">Nenhuma solicitação de contato.</h5>
        <i class="bx bx-tired bx-lg"></i>
        <br />
      </div>
      <div class="table-responsive mt-3 mb-0" v-show="contacts.length > 0">
        <table class="table table-centered table-nowrap table-hover">
          <thead>
            <tr>
              <th width="20%">Nome</th>
              <th width="25%">E-mail</th>
              <th width="60%">Mensagem</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(s, i) in contacts" :key="i" class="link">
              <td @click="showContact(s.id)">
                {{ s.user.data.name }} <br />
                <small> {{ s.user.data.role }}</small>
              </td>
              <td @click="showContact(s.id)">{{ s.user.data.email }}</td>
              <td @click="showContact(s.id)">{{ s.message }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-4" v-show="contacts.length > 0">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
          </ul>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-center"
      ref="modal-contact"
      v-model="modal"
      title="Detalhes"
      title-class="font-18"
      size="lg"
      :no-close-on-backdrop="true"
    >
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="name">Nome</label>
            <input
              id="name"
              v-model="contact.user.name"
              name="name"
              type="text"
              readonly
              class="form-control"
            />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="email">E-mail</label>
            <input
              id="email"
              v-model="contact.user.email"
              name="email"
              type="text"
              readonly
              class="form-control"
            />
          </div>
        </div>
      </div>
            <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label for="role">Cargo</label>
            <input
              id="role"
              v-model="contact.user.role"
              name="role"
              type="text"
              readonly
              class="form-control"
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label for="department">Área</label>
            <input
              id="department"
              v-model="contact.user.department"
              name="department"
              type="text"
              readonly
              class="form-control"
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label for="company">Empresa</label>
            <input
              id="company"
              v-model="contact.user.company"
              name="company"
              type="text"
              readonly
              class="form-control"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="message">Mensagem</label>
            <textarea
              id="message"
              v-model="contact.message"
              name="message"
              rows="4"
              readonly
              class="form-control"
            ></textarea>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeModal()">Fechar</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import service from './services'
// import { errorMessage, successMessage } from '@/components/alertsMessages'
export default {
  components: {
  },
  name: 'contacts',
  data () {
    return {
      submitted: false,
      params: {},
      modal: false,
      contact: {
        status: false,
        user: {}
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20
    }
  },
  beforeMount () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  computed: {
    ...mapState('partnerContacts', ['contacts', 'pagination']),
    ...mapState('partnerContacts', { data: 'contact' }),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('partnerContacts', {
      fetchData: 'getList',
      fetchItem: 'show'
    }),
    cleanArchive () {
      this.archive = {}
    },
    showContact (id) {
      this.fetchItem({ partner_id: this.$route.params.id, id }).then((res) => {
        this.$refs['modal-contact'].show()
        this.contact = this.data
        if (this.data.user) {
          this.contact.user = this.data.user.data
        }
      })
    },
    exporPartnerContacts () {
      console.log('chamou')
      let loeader = this.$loading.show()
      service.exportContacts({ partner_id: this.$route.params.id, include: 'user' }).then((res) => {
        this.download(res)
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    closeModal () {
      this.$refs['modal-contact'].hide()
      this.cleanArchive()
    },
    updateList () {
      this.fetchData({ partner_id: this.$route.params.id, limit: this.perPage, page: this.currentPage, include: 'user' })
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.closeModal()
      }
    }
  }
}
</script>

<style scoped>
.archive-archived { color: #909090; background: #fff0f0}
.mt-15 { margin-top:15px }
</style>
