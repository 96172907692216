<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveSchedule()" autocomplete="off">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Informações da Programação</h4>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="room_id">Sala</label>
                    <room-select
                      :value="roomSelected"
                      @input="setRoom"
                      :class="{
                        'is-invalid': submitted && $v.schedule.room_id.$error,
                      }"
                      :classError="$v.schedule.room_id.$error ? true : false"
                    >
                    </room-select>
                    <div v-if="submitted && !$v.schedule.room_id.required" class="invalid-feedback">
                      O campo sala é obrigatório.
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="name">Data</label>
                    <date-picker
                      v-model="schedule.schedule_date"
                      :first-day-of-week="1"
                      lang="pt-br"
                      format="DD/MM/YYYY"
                      :class="{
                        'is-invalid': submitted && $v.schedule.schedule_date.$error,
                      }"
                    >
                    </date-picker>
                    <div v-if="submitted && !$v.schedule.schedule_date.required" class="invalid-feedback">
                      O campo data é obrigatório.
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="name">Exibição</label>
                    <select name="display" id="display" class="form-control" v-model="schedule.display" :class="{'is-invalid': submitted && $v.schedule.display.$error,}">
                      <option value="1">Web</option>
                      <option value="0">App</option>
                      <option value="10">Web e App</option>
                    </select>
                    <div v-if="submitted && !$v.schedule.display.required" class="invalid-feedback">
                      O campo Exibição é obrigatório.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <b-card no-body>
                <b-tabs>
                  <div v-for="(lang, index) in schedule.langs" :key="index">
                    <b-tab :title="(lang.lang === 'pt') ? 'Português' : 'Inglês'" :active="lang.lang === 'pt'">
                      <b-card-text>
                        <div class="row mt-3">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="title">Título</label>
                              <input
                                id="title"
                                v-model="lang.title"
                                name="title"
                                type="text"
                                class="form-control"
                                :class="{
                                  'is-invalid': submitted && $v.schedule.langs.$each[index].title.$error,
                                }"
                              />
                              <div
                                v-if="submitted && !$v.schedule.langs.$each[index].title.required"
                                class="invalid-feedback"
                              >
                                O campo título é obrigatório.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="schedule_type">Tipo</label>
                              <multiselect
                              v-model="lang.schedule_type"
                              :options="lang.lang === 'pt' ? options : optionsEn" class="helo"
                              :max-height="180"
                              placeholder="Selecione uma opção"
                              :class="{
                                'is-invalid': submitted && $v.schedule.langs.$each[index].schedule_type.$error,
                              }"
                              :classError="$v.schedule.langs.$each[index].schedule_type.$error ? true : false"
                              >
                              </multiselect>
                              <div v-if="submitted && !$v.schedule.langs.$each[index].schedule_type.required" class="invalid-feedback">
                                O campo tipo é obrigatório.
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="form-group">
                              <label for="name">Horário de Início</label>
                              <date-picker
                                v-model="lang.start_time"
                                format="HH:mm"
                                value-type="format"
                                :showSecond="false"
                                type="time"
                                placeholder="HH:mm"
                                :class="{
                                  'is-invalid': submitted && $v.schedule.langs.$each[index].start_time.$error,
                                }"
                              ></date-picker>
                              <div v-if="submitted && !$v.schedule.langs.$each[index].start_time.required" class="invalid-feedback">
                                O campo horário de início é obrigatório.
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="form-group">
                              <label for="name">Horário de Término</label>
                              <date-picker
                                v-model="lang.end_time"
                                format="HH:mm"
                                :showSecond="false"
                                value-type="format"
                                type="time"
                                placeholder="HH:mm"
                                :class="{
                                  'is-invalid': submitted && $v.schedule.langs.$each[index].end_time.$error,
                                }"
                              ></date-picker>
                              <div v-if="submitted && !$v.schedule.langs.$each[index].end_time.required" class="invalid-feedback">
                                O campo horário de início é obrigatório.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="title">Descrição</label>
                              <textarea
                                class="form-control"
                                rows="3"
                                v-model="lang.description"
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                  </div>
                </b-tabs>
              </b-card>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Palestrantes</h4>
              <div class="row">
                <div class="list-speakers " v-for="(ls,k) in speakersList" :key="k">
                  <span class="border-light">
                    <img :src="ls.photo" class="rounded avatar-xl">
                  </span>
                  <p class="text-center font-size-9 mb-0 mt-1">{{ ls.name }}</p>
                </div>
                <div class="list-speakers link">
                  <div class="vue-dropzone dropzone dz-clickable text-center" @click="modalSpeakers()">
                    <img :src="avatar" class=" rounded avatar-xl link">
                    <p>Adicionar/Remover</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="float-right">
                <button type="buton" @click="cancelar()" class="btn btn-dark mr-4">
                  Cancelar
                </button>
                <button type="submit" class="btn btn-primary mr-1">
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <modal-palestrantes :selecteds="speakersList" ref="modalPalestrantes" @success="defineSpeakers"></modal-palestrantes>
  </Layout>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import Moment from 'moment'
import 'vue2-datepicker/locale/pt-br'
import Multiselect from 'vue-multiselect'
import Layout from '@/components/layout/vertical'
import ModalPalestrantes from '@/components/widgets/modal-palestrantes'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import roomSelect from '@/components/selects/room-select'
export default {
  components: {
    roomSelect,
    Multiselect,
    DatePicker,
    Layout,
    ModalPalestrantes,
    PageHeader
  },
  name: 'Programação',
  page: {
    title: 'Programação',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      autoClose: '',
      schedule: {
        id: '',
        title: '',
        description: '',
        schedule_date: '',
        start_time: '',
        end_time: '',
        room_id: '',
        langs: [
          {
            lang: 'pt',
            title: '',
            description: '',
            start_time: '',
            end_time: '',
            schedule_type: ''
          },
          {
            lang: 'en',
            title: '',
            description: '',
            start_time: '',
            end_time: '',
            schedule_type: ''
          }
        ]
      },
      roomSelected: {},
      rooms: [],
      speakersList: [],
      searchSpeark: '',
      avatar: require('@/assets/images/users/user-default.png'),
      submitted: false,
      options: [
        'Premiação',
        'Abertura',
        'Almoço ',
        'Apresentação ',
        'Apresentação Artística',
        'Atividade Prática',
        'Aula',
        'Coffee break',
        'Competição',
        'Conferência ',
        'Coordenação da atividade',
        'Credenciamento ',
        'Curso',
        'Encerramento ',
        'Encontro com especialista',
        'Estudo de Caso',
        'Exposição',
        'Feira',
        'Festa',
        'Fórum',
        'Imersão',
        'Intervalo',
        'Jantar ',
        'Mesa Temática',
        'Mesa-redonda',
        'Oficina',
        'Painel',
        'Palestra',
        'Palestra Sessão Internacional',
        'Palestra Sessão Nacional',
        'Relatos de Experiência',
        'Roda de Conversa',
        'Seminário',
        'Sessão de Perguntas',
        'Sessão de Vídeos ',
        'Sessão Solene',
        'Sessão técnica',
        'Visita técnica',
        'Webinário',
        'Workshop'
      ],
      optionsEn: [
        'Award',
        'Opening',
        'Lunch ',
        'Presentation ',
        'Artistic Presentation',
        'Practical Activity',
        'Class',
        'Coffee break',
        'Competition',
        'Conference',
        'Coordination of activity',
        'Accreditation',
        'Course',
        'Closing',
        'Meeting with an expert',
        'Case study',
        'Exposure',
        'Fair',
        'Party',
        'Forum',
        'Immersion',
        'Break',
        'Dinner ',
        'Thematic Table',
        'Round table',
        'Workshop',
        'Panel',
        'Lecture',
        'International Session Lecture',
        'National Session Lecture',
        'Experience Reports',
        'Talk Wheel',
        'Seminar',
        'Question Session',
        'Video Session',
        'Solemn Session',
        'Technical session',
        'Technical visit',
        'Webinar',
        'Workshop'
      ],
      title: 'Programação',
      items: [{
        text: 'Programação',
        href: '/schedules'
      },
      {
        text: 'Novo Programação',
        active: true
      }
      ]
    }
  },
  validations: {
    schedule: {
      room_id: { required },
      schedule_date: { required },
      display: { required },
      langs: {
        required,
        $each: {
          title: { required },
          schedule_type: { required },
          start_time: { required },
          end_time: { required }
        }
      }
    }
  },
  mounted () {
    this.loadItem()
  },
  computed: {
    ...mapState('schedule', { data: 'schedule' })
  },
  methods: {
    ...mapActions('schedule', {
      fetch: 'GetSchedule',
      postData: 'SaveSchedule'
    }),
    modalSpeakers () {
      this.$refs.modalPalestrantes.open()
    },
    defineSpeakers (data) {
      this.speakersList = [...data]
    },
    setRoom (room) {
      this.schedule.room_id = room
    },
    cancelar () {
      this.$router.push({ name: 'schedules' })
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.items[1].text = 'Editar Programação'
        this.fetch(this.$route.params.id)
          .then(() => {
            this.schedule = this.data
            this.schedule.langs = this.data.langs.data
            this.schedule.schedule_date = Moment(this.schedule.schedule_date, 'YYYY-MM-DD').toDate()
            this.roomSelected = this.data.room_id
            if (this.data.participants.data) {
              this.defineSpeakers(this.data.participants.data)
            }
          }).then(() => {
            loader.hide()
          })
      }
    },
    async saveSchedule () {
      this.submitted = true
      this.$v.schedule.$touch()
      if (this.$v.schedule.$invalid) {
        window.scroll(0, 0)
        return
      }
      if (this.speakersList.length === 0) return errorMessage('Alerta: Você precisa vincular ao menos um palestrante para essa programação.', 3000)
      let loader = this.$loading.show()
      this.schedule.speakers = [...this.speakersList]
      this.schedule.schedule_date = this.$moment(this.schedule.schedule_date, 'YYYY-MM-DD').format('YYYY-MM-DD')

      window.scroll(0, 0)
      await this.postData(this.schedule).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.cancelar()
        loader.hide()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    }
  }
}
</script>
<style scoped>
.list-speakers {padding:5px}
.link { cursor:pointer !important}
.border-light {
  padding: 4px;
  display: flex;
  background: #f1f1f1;
  border-radius: 0.25rem;
}
.dropzone { padding: 5px; max-height: 150px; max-width: 150px;}
.mx-datepicker.is-invalid {
  border: 1px solid #f86c6b !important;
  border-radius: 4px !important
}
</style>
