<template>
  <div style="margin-left:-15px">
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="text-sm-right">
          <button type="button" class="btn btn-dark btn-sm btn-rounded mr-2" @click="openCategory()">
            <i class="mdi mdi-plus"></i> Categorias
          </button>
          <button type="button" class="btn btn-success btn-sm btn-rounded" @click="newProduct()">
            <i class="mdi mdi-plus"></i> Inserir Produto
          </button>
        </div>
      </div>
      <!-- end col-->
    </div>
    <div class="row">
      <div class="col-md-12 text-center" v-show="products.length === 0">
        <h5 class="text-truncate font-size-14 p-4 mt-2">Nenhum produto cadastrado.</h5>
        <i class="bx bx-tired bx-lg"></i>
        <br />
      </div>
      <div class="table-responsive mt-3 mb-0" v-show="products.length > 0">
        <table class="table table-centered table-nowrap table-hover">
          <thead>
            <tr>
              <th width="30%">Categoria</th>
              <th width="60%">Nome</th>
              <!-- <th>Status</th> -->
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(s, i) in products" :key="i" class="link" :class="{ 'product-archived': s.archived }">
              <td @click="showProduct(s.id)" title="Click para editar">{{ s.category.data.name }}</td>
              <td @click="showProduct(s.id)" title="Click para editar">{{ s.name }}</td>
              <!-- <td>
                <b-form-checkbox v-model="s.status" switch @change="toggleStatus(s.id)"></b-form-checkbox>
              </td> -->
              <td>
                <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal font-size-18"></i>
                  </template>
                  <b-dropdown-item @click="showProduct(s.id)">
                    <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteProduct(s.id)">
                    <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-4" v-show="products.length > 0">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
          </ul>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-center"
      ref="modal-product"
      v-model="modal"
      :title="product.id ? 'Editar Produto' : 'Cadastrar Produto'"
      title-class="font-18"
      size="lg"
      :no-close-on-backdrop="true"
    >
      <form @submit.prevent="createProduct()">
        <div class="row mt-2">
          <div class="col-sm-4">
            <div class="form-group actions">
              <label for="name">Imagem</label>
              <input
                ref="photo"
                type="file"
                name="photo"
                accept="image/*"
                @change="setImage"
              />
              <div class="link " @click.prevent="showChooser()">
                <div class="dropzone" v-if="!cropImg && !product.photo">
                  <div class="dropzone-custom-content text-center ">
                    <i class="display-4 text-muted bx bxs-cloud-upload mt-4"></i>
                    <h5 class="text-muted ">Selecionar imagem</h5>
                  </div>
                </div>
                <img :src="cropImg || (product.photo)" v-if="cropImg || product.photo" class="rounded  border-light" style="width:100%;height:100%">
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="product_method">Categoria</label>
                  <multiselect
                    v-model="selected"
                    :options="categories" class="helo"
                    @input="setCategory"
                    :max-height="180"
                    trackBy="id"
                    label="name"
                    placeholder="Selecione uma opção"
                    :class="{ 'is-invalid': submitted && $v.product.partner_product_category_id.$error }"
                  >
                  </multiselect>
                  <div
                    v-if="submitted && !$v.product.partner_product_category_id.required"
                    class="invalid-feedback"
                  >
                    O campo categoria é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="name">Nome</label>
                  <input
                    id="name"
                    v-model="product.name"
                    name="name"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': submitted && $v.product.name.$error }"
                  />
                  <div
                    v-if="submitted && !$v.product.name.required"
                    class="invalid-feedback"
                  >
                    O campo nome é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="description">Descrição</label>
                  <ckeditor
                    :editor="editor"
                    v-model="product.description"
                    :config="editorConfig"
                  >
                  </ckeditor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeModal()">Cancelar</b-button>
        <b-button variant="primary" @click="createProduct()">Salvar</b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-center"
      ref="modal-photo"
      centered
      title="Foto"
      title-class="font-18"
      @hidden="cancel()"
      @ok="cropPhoto"
    >
      <div class="img-cropper border-dashed" v-show="imgSrc">
        <vue-cropper
          ref="cropper"
          :src="imgSrc"
          preview=".preview"
        />
      </div>
    </b-modal>
    <category v-model="modalCategory" ref="modalCategory"></category>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import { required } from 'vuelidate/lib/validators'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Multiselect from 'vue-multiselect'
import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import Category from './Category'
import service from './services'

export default {
  components: {
    ckeditor: CKEditor.component,
    Multiselect,
    VueCropper,
    Category
  },
  name: 'products',
  data () {
    return {
      submitted: false,
      editor: ClassicEditor,
      editorConfig: { toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ] },
      cropImg: '',
      imgSrc: '',
      thumb: require('@/assets/images/image-default.png'),
      modalCategory: false,
      modal: false,
      categories: [],
      selected: '',
      params: {},
      product: {
        status: false
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20
    }
  },
  validations: {
    product: {
      name: { required },
      partner_product_category_id: { required }
    }
  },
  beforeMount () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  computed: {
    ...mapState('partnerProducts', ['products', 'pagination']),
    ...mapState('partnerProducts', { data: 'product' }),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('partnerProducts', {
      fetchData: 'getList',
      fetchItem: 'show',
      postData: 'save',
      changeStatus: 'changeStatus',
      deleteItem: 'deleteProduct'
    }),
    cropPhoto () {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.product.binaryphoto = blob
      })
    },
    cancel () {
      this.$refs.photo.value = null
      this.imgSrc = ''
    },
    setImage (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 2MB!')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
        this.$refs['modal-photo'].show()
        this.setData()
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    setData (type = 0) {
      setTimeout(() => {
        this.$refs.cropper.setCropBoxData({ width: 400, height: 400 })
      }, 100)
    },
    showChooser () {
      this.$refs.photo.click()
    },
    setCategory (category) {
      if (category) {
        this.product.partner_product_category_id = category.id
        return
      }
      this.product.partner_product_category_id = ''
    },
    openCategory () {
      this.$refs.modalCategory.open()
    },
    cleanProduct () {
      this.product = {}
      this.selected = ''
      this.imgSrc = ''
      this.cropImg = ''
    },
    newProduct () {
      service.getCategories({ partner_id: this.$route.params.id }).then(({ data }) => {
        this.categories = data.data
      }).then(() => {
        this.$refs['modal-product'].show()
      })
    },
    showProduct (id) {
      service.getCategories({ partner_id: this.$route.params.id }).then(({ data }) => {
        this.categories = data.data
      })
      this.fetchItem({ partner_id: this.$route.params.id, id }).then((res) => {
        this.$refs['modal-product'].show()
        this.product = this.data
      })
    },
    closeModal () {
      this.$refs['modal-product'].hide()
      this.cleanProduct()
      this.$v.$reset()
    },
    deleteProduct (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem({ partner_id: this.$route.params.id, id: id }).then((res) => {
              successMessage('Sucesso: Produto excluído com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    async toggleStatus (id, status = '') {
      try {
        await this.changeStatus({ partner_id: this.$route.params.id, id, status })
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async createProduct () {
      this.submitted = true
      this.product.partner_id = this.$route.params.id
      this.$v.product.$touch()
      if (this.$v.product.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      const formData = new FormData()
      formData.append('id', this.product.id)
      formData.append('partner_id', this.product.partner_id)
      formData.append('name', this.product.name)
      formData.append('description', this.product.description)
      formData.append('partner_product_category_id', this.product.partner_product_category_id)

      if (this.product.binaryphoto) {
        formData.append('photo', this.product.binaryphoto, 'photo.jpg')
      }

      await this.postData(formData).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.closeModal()
        this.updateList()
        loader.hide()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    },
    updateList () {
      this.fetchData({ partner_id: this.$route.params.id, limit: this.perPage, page: this.currentPage, include: 'category' })
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.closeModal()
      }
      if (this.modal && this.product.partner_product_category_id) {
        this.selected = this.categories.filter(o => o.id === parseInt(this.product.partner_product_category_id))[0]
      }
    }
    // currentPage () { this.updateList() },
    // total () { this.updateList() },
    // dataPerPage () { this.updateList() }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.product-archived { color: #909090; background: #fff0f0}
.mt-15 { margin-top:15px }
</style>
