<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-sm-12">
        <table class="table table-nowrap mb-0" style="border-top:none !important">
          <tbody>
            <tr>
              <th scope="row" width="60px" style="border-top:none !important">Número do pedido :</th>
              <td style="border-top:none !important">{{ order.order_number }}</td>
            </tr>
            <tr>
              <th scope="row" width="60px">Data do Pedido :</th>
              <td>{{ order.order_at }}</td>
            </tr>
            <tr>
              <th scope="row" width="60px">Status:</th>
              <td>
                <span class="badge badge-pill font-size-12" :class="{
                    'badge-soft-success': `${parseInt(order.status)}` === '2',
                    'badge-soft-primary': `${parseInt(order.status)}` === '1',
                    'badge-soft-warning': `${parseInt(order.status)}` === '0'
                  }">{{ statusLabel[order.status] }}</span>
              </td>
            </tr>
            <tr>
              <th scope="row" width="60px">Participante :</th>
              <td>{{ order.user ? order.user.data.name : '' }}</td>
            </tr>
             <tr v-if="order.status > 0">
              <th scope="row" width="60px">Tipo de Transporte :</th>
              <td>
                {{ order.delivery_type }}
                <span v-if="order.develiry_type !== 'Retirou no local'" style="float:right"> Código de rastreio: <strong>{{ order.tracking_code }}</strong></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <table class="table project-list-table table-nowrap table-centered table-borderless">
            <thead>
              <tr>
                <th scope="col" width="10%"></th>
                <th scope="col" width="40%">Item</th>
                <th scope="col" width="5%">Qtd</th>
                <th scope="col" width="10%">Pontos</th>
                <!-- <th scope="col" width="10%">Total Pontos</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(s, i) in order.items" :key="i">
                <td><img :src="s.product.data.photo" height="80px"/></td>
                <td>{{ s.product.data ? s.product.data.name : '' }}</td>
                <td>{{ s.quantity }}</td>
                <td>{{ s.points_cost }}</td>
                <!-- <td>{{ s.total_points }}</td> -->
              </tr>
            </tbody>
          </table>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveProduct()" autocomplete="off">
          <div class="row">
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="primary" @click="openModalOrderUpdate(order.id)">Alterar status</b-button>
      <b-button variant="secondary" @click="close()">Fechar</b-button>
    </template>
    <order-update v-model="modalOrderUpdate" ref="modalOrderUpdate" @update="loadItem(order.id)"></order-update>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import OrderUpdate from './OrderUpdate'

export default {
  components: {
    Loading,
    OrderUpdate
  },
  data () {
    return {
      title: 'Detalhes do Pedido',
      loading: true,
      statusLabel: ['Em processamento', 'Em Transporte', 'Entregue'],
      fullPage: true,
      submitted: false,
      modal: false,
      modalOrderUpdate: false,
      categories: [],
      selected: '',
      order: {
      }
    }
  },
  validations () {
    return {
      product: {
        name: { required },
        stock_quantity: { required },
        points_cost: { required },
        category_id: { required }
      }
    }
  },
  computed: {
    ...mapState('order', { data: 'order' })
  },
  methods: {
    ...mapActions('order', {
      fetchItem: 'show',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.loading = true
        this.fetchItem(id).then((data) => {
          this.order = this.data
          this.order.items = this.data.items.data
          this.title = 'Detalhes do Pedido: ' + this.order.order_number
        }).then(() => {
          this.loading = false
        })
      }
    },
    openModalOrderUpdate (id) {
      this.$refs.modalOrderUpdate.open(id)
      this.modalOrderUpdate = true
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveProduct () {
      this.submitted = true
      this.$v.product.$touch()
      if (this.$v.product.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      const formData = new FormData()
      if (this.product.id) {
        formData.append('id', this.product.id)
      }
      formData.append('name', this.product.name)
      formData.append('description', this.product.description)
      formData.append('category_id', this.product.category_id)
      formData.append('points_cost', this.product.points_cost)
      formData.append('stock_quantity', this.product.stock_quantity)

      if (this.product.binaryphoto) {
        formData.append('photo', this.product.binaryphoto, 'photo.jpg')
      }
      await this.postData(formData).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
        this.loading = false
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.order = {}
      this.$emit('created')
      this.$v.$reset()
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
input[type="file"] {
  display: none;
}
.product-archived { color: #909090; background: #fff0f0}
.mt-15 { margin-top:15px }
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
