<template>
  <Layout>
    <PageHeader :title="customTitle" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <SiderbarRoom :controls="room.settings" v-if="room.id" />
        <div class="email-rightbar mb-3">
          <ContentRoom />
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import SiderbarRoom from '@/components/widgets/sidebar-room'
import ContentRoom from '@/components/widgets/content-room'
import appConfig from '@/app.config'
import { errorMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    SiderbarRoom,
    ContentRoom
  },
  name: 'Sala',
  page: {
    title: 'Sala',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      room: {
        id: '',
        name: '',
        title: '',
        description: '',
        thumb_image: '',
        allow_users: 0
      },
      loading: true,
      submitted: false,
      title: 'Sala',
      items: [{
        text: 'Salas',
        href: '/rooms'
      },
      {
        text: 'Configurações da Sala',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.loadItem()
  },
  computed: {
    ...mapState('room', { data: 'room' }),
    customTitle () {
      return 'Sala - ' + this.room.name
    }
  },
  methods: {
    ...mapActions('room', {
      fetch: 'GetRoom'
    }),
    cancelar () {
      this.$router.push({ name: 'rooms' })
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.fetch(this.$route.params.id)
          .then(() => {
            this.room = this.data
            this.room.start_date = this.$moment(this.room.start_date, 'YYYY-MM-DD HH:mm').toDate()
            this.room.end_date = this.$moment(this.room.end_date, 'YYYY-MM-DD HH:mm').toDate()
            this.room.settings = this.room.settings.data
          }).then(() => { loader.hide() })
          .catch((error) => {
            console.error(error)
            loader.hide()
            errorMessage('Atenção:' + error.message)
          })
      }
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.link { cursor: pointer; }
.thumb {
  max-width: 100%;
  max-height: 168px;
  min-height: 168px;
}
</style>
