<template>
  <b-modal v-model="modal" title="Resultado - Quiz" size="xl">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ quiz.asking }}</h4>
            <!-- Bar Chart -->
            <apexchart
              v-show="!loading"
              class="apex-charts-modal"
              height="380"
              type="bar"
              ref="quizChart"
              dir="ltr"
              :series="series"
              :options="chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Fechar</b-button>
      <b-button variant="primary" @click="openFullChart()" v-if="!loading">Fullscreen</b-button>
    </template>
  </b-modal>
</template>
<script>
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import http from '@/http'

export default {
  components: { Loading },
  data () {
    return {
      loading: true,
      modal: false,
      quizId: null,
      quiz: {},
      answers: [],
      series: [{
        data: []
      }]
    }
  },
  methods: {
    open (quizId) {
      this.quizId = quizId
      this.modal = true
      this.loading = true
      if (quizId) {
        this.getInitQuiz()
        this.connectSocket()
        return
      }
      this.loading = false
    },
    close () {
      this.quizId = null
      this.answers = []
      this.quiz = {}
      this.modal = false
    },
    openFullChart () {
      let routeData = this.$router.resolve({ name: 'room-quiz-chart', params: { quizId: this.quizId } })
      window.open(routeData.href, '_blank')
    },
    getInitQuiz () {
      http.get(`/admin/charts/quiz/${this.quizId}`).then((response) => {
        if (response.data.data.id) {
          this.quiz = response.data.data
          this.answers = response.data.result.map(o => {
            return o.answer
          })
          const totais = response.data.result.map(o => {
            return o.porcentagem
          })
          this.series[0].data = totais
          this.loading = false
        }
      }).then(() => {
        this.loading = false
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    connectSocket () {
      this.$pusher.subscribe(`chart-quiz.${this.quizId}`).bind('ChartQuiz', (response) => {
        if (response.data.id) {
          const totais = response.result.map(o => {
            return o.porcentagem
          })
          this.$refs.quizChart.updateSeries([{
            data: totais
          }])
        }
      })
    }
  },
  beforeMount () {
  },
  mounted () {

    // this.getInitQuiz()
    // Echo.channel('chart-quiz.' + this.sala)
    //   .listen('ChartQuiz', (e) => {
    //     if (e.data.id) {
    //       const totais = e.resultado.map(o => {
    //         return o.porcentagem
    //       })
    //       this.$refs.chart.updateSeries([{
    //         data: totais
    //       }])
    //     }
    //   })
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          type: 'bar',
          height: 380,
          toolbar: {
            tools: {
              zoomin: true,
              zoomout: true
            }
          },
          animations: {
            enabled: true,
            easing: 'bar',
            dynamicAnimation: {
              speed: 1000
            }
          }
        },
        plotOptions: {
          bar: {
            barHeight: '50%',
            columnWidth: '50%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom'
            }
          }
        },
        colors: ['#008ffb', '#7081ce', '#34c38f', '#a157bf', '#b5704f'],
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#FFF']
          },
          fill: {
            colors: ['#FFF']
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ': ' + val + '%'
          },
          offsetX: 0,
          dropShadow: {
            enabled: true
          }
        },
        legend: {
          show: true,
          fontSize: '16px',
          offsetY: 0
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: this.answers,
          labels: {
            tickAmount: 10,
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        title: {
          text: 'Respostas',
          align: 'center',
          floating: true,
          style: {
            color: '#3c4b64'
          }
        },
        tooltip: {
          enabled: false,
          theme: 'dark',
          x: {
            enabled: false,
            show: false
          },
          y: {
            show: true
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
.apex-charts-modal {
  min-height: 10px !important;
  text {
    fill: #FFF !important;
  }
  .apexcharts-canvas {
    margin: 0 auto;
  }
}
.apexcharts-yaxis,
.apexcharts-xaxis {
  text {
    fill: #FFF !important;
  }
}
</style>
