<template>
  <div style="margin-left:-15px">
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="text-sm-right">
          <button type="button" class="btn btn-success btn-sm btn-rounded" @click="newArchive()">
            <i class="mdi mdi-plus"></i> Inserir Arquivo
          </button>
        </div>
      </div>
      <!-- end col-->
    </div>
    <div class="row">
      <div class="col-md-12 text-center" v-show="archives.length === 0">
        <h5 class="text-truncate font-size-14 p-4 mt-2">Nenhum arquivo cadastrado. </h5>
        <i class="bx bx-tired bx-lg"></i>
        <br />
      </div>
      <div class="table-responsive mt-3 mb-0" v-show="archives.length > 0">
        <table class="table table-centered table-nowrap table-hover">
          <thead>
            <tr>
              <th width="80%">Descrição</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(s, i) in archives" :key="i" class="link">
              <td @click="showArchive(s.id)">{{ s.description }}</td>
              <td>
                <b-form-checkbox v-model="s.status" switch @change="toggleStatus(s.id)"></b-form-checkbox>
              </td>
              <td>
                <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal font-size-18"></i>
                  </template>
                  <b-dropdown-item @click="showArchive(s.id)">
                    <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteArchive(s.id)">
                    <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-4" v-show="archives.length > 0">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
          </ul>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-center"
      ref="modal-archive"
      v-model="modal"
      :title="archive.id ? 'Editar Arquivo' : 'Cadastrar Arquivo'"
      title-class="font-18"
      size="lg"
      :no-close-on-backdrop="true"
    >
      <form @submit.prevent="createArchive()">
        <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="description">Descrição</label>
                <input
                  id="description"
                  v-model="archive.description"
                  name="description"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.archive.description.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.archive.description.required"
                  class="invalid-feedback"
                >
                  O campo descrição é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="url_download">URL Download</label>
                <input
                  id="url_download"
                  v-model="archive.url_download"
                  name="url_download"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.archive.url_download.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.archive.url_download.required"
                  class="invalid-feedback"
                >
                  O campo url é obrigatório.
                </div>
                <div
              v-if="submitted && !$v.archive.url_download.url"
              class="invalid-feedback"
            >
              O campo url download deve ser uma URL válida
            </div>
              </div>
            </div>
          </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeModal()">Cancelar</b-button>
        <b-button variant="primary" @click="createArchive()">Salvar</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import 'cropperjs/dist/cropper.css'
import { required, url } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
  },
  name: 'archives',
  data () {
    return {
      submitted: false,
      params: {},
      modal: false,
      archive: {
        status: false
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20
    }
  },
  validations: {
    archive: {
      description: { required },
      url_download: { required, url }

    }
  },
  beforeMount () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  computed: {
    ...mapState('partnerArchives', ['archives', 'pagination']),
    ...mapState('partnerArchives', { data: 'archive' }),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('partnerArchives', {
      fetchData: 'getList',
      fetchItem: 'show',
      postData: 'save',
      changeStatus: 'changeStatus',
      deleteItem: 'deleteArchive'
    }),
    cleanArchive () {
      this.archive = {}
    },
    newArchive () {
      this.$refs['modal-archive'].show()
    },
    showArchive (id) {
      this.fetchItem({ partner_id: this.$route.params.id, id }).then((res) => {
        this.$refs['modal-archive'].show()
        this.archive = this.data
      })
    },
    closeModal () {
      this.$refs['modal-archive'].hide()
      this.cleanArchive()
      this.$v.$reset()
    },
    deleteArchive (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem({ partner_id: this.$route.params.id, id: id }).then((res) => {
              successMessage('Sucesso: Transmissão excluída com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    async toggleStatus (id, status = '') {
      try {
        await this.changeStatus({ partner_id: this.$route.params.id, id, status })
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async createArchive () {
      this.submitted = true
      this.archive.partner_id = this.$route.params.id
      this.$v.archive.$touch()
      if (this.$v.archive.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      await this.postData(this.archive).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.closeModal()
        this.updateList()
        loader.hide()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    },
    updateList () {
      this.fetchData({ partner_id: this.$route.params.id, limit: this.perPage, page: this.currentPage, include: 'options' })
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.closeModal()
      }
    }
  }
}
</script>

<style scoped>
.archive-archived { color: #909090; background: #fff0f0}
.mt-15 { margin-top:15px }
</style>
