<template>
  <b-modal v-model="modal" :title="title" size="xl" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <template #modal-header>
      <div class="col-lg-8" v-if="participant.id">
        <div class="row">
          <div class="col-md-2">
            <img :src="participant.avatar" class="avatar-lg rounded-circle img-thumbnail"/>
          </div>
          <div class="col-md-10" style="margin-left:-5px">
            <h5 class="font-size-15 text-truncate">{{ participant.full_name || participant.name }}</h5>
            <div>Saldo Disponível:
              <span class="badge badge-pill font-size-11 badge-soft-success">
                  {{ (participant.balance && participant.balance.data) ? participant.balance.data.balance : 0 }} pts
                </span>
            </div>
            <div class="mt-1">Pontos Gamificação:
              <span class="badge badge-pill font-size-11 badge-soft-primary">
                {{ participant.gamification_points }} pontos
              </span>
            </div>
            <p class="text-muted mb-0 font-size-11">Inscrito em {{ $moment(participant.created_at).format('D [de] MMMM YYYY [às] HH:mm') }}</p>
          </div>
        </div>
      </div>
    </template>
    <div class="row">
      <div class="col-lg-12">
        <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted" lazy>
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Cadastro</span>
            </template>
            <div role="tablist">
              <b-card no-body class="mb-1 ">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-1
                      class="text-dark"
                      href="javascript:void(0);"
                    >Dados Cadastrais</a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <form @submit.prevent="saveParticipant()" autocomplete="off">
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="form-group">
                            <label for="name">Nome</label>
                            <input
                              id="name"
                              v-model="participant.name"
                              name="name"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.name.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.name.required"
                              class="invalid-feedback"
                            >
                              O campo nome é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="name">Ultimo nome</label>
                            <input
                              id="name"
                              v-model="participant.name"
                              name="name"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="gender">Sexo</label>
                            <select
                              id="gender"
                              v-model="participant.gender"
                              name="gender"
                              class="form-control"
                            >
                              <option></option>
                              <option value="Masculino" v-if="participant.type_form !== 5">Masculino</option>
                              <option value="Feminino" v-if="participant.type_form !== 5">Feminino</option>
                              <option value="Man" v-if="participant.type_form === 5">Man</option>
                              <option value="Woman" v-if="participant.type_form === 5">Woman</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="phone">Telefone</label>
                            <input
                              id="phone"
                              v-model="participant.phone"
                              name="phone"
                              type="phone"
                              v-mask="'X##(##) #####-####'"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="email">E-mail</label>
                            <input
                              id="email"
                              v-model="participant.email"
                              name="email"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.email.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.email.required"
                              class="invalid-feedback"
                            >
                              O campo email é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="cpf">CPF</label>
                            <input
                              id="cpf"
                              v-model="participant.cpf"
                              v-mask="'###.###.###-##'"
                              name="cpf"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="zipcode">CEP</label>
                            <input
                              id="zipcode"
                              v-model="participant.zipcode"
                              name="zipcode"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="address">Endereço</label>
                            <input
                              id="address"
                              v-model="participant.address"
                              name="address"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="city">Cidade</label>
                            <input
                              id="city"
                              v-model="participant.city"
                              name="city"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="district">Estado</label>
                            <select
                              v-if="participant.type_form !== 5"
                              class="form-control"
                              v-model="participant.district"
                            >
                              <option></option>
                              <option
                                v-for="(opt,idx) in states"
                                :key="idx"
                                :value="opt.value"
                              >
                                {{ opt.value || 'no label' }}
                              </option>
                            </select>
                            <input
                              v-if="participant.type_form === 5"
                              id="state"
                              v-model="participant.district"
                              name="state"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label for="group_name">Grupo</label>
                            <input
                              id="group_name"
                              v-model="participant.group_name"
                              name="group_name"
                              type="text"
                              disabled
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- <div class="row" v-if="participant.sympla">
                        <div class="col-sm-12">
                          <span v-if="participant.sympla" class="badge badge-soft-success font-size-11 mb-1">Integração Sympla</span>
                          <table class="table table-centered table-nowrap table-hover">
                            <thead>
                              <tr>
                                <th>Nº Pedido</th>
                                <th>Cód. Ingresso</th>
                                <th>Ingresso</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{{ participant.sympla_order_id }}</td>
                                <td>{{ participant.sympla_ticket_number }}</td>
                                <td>
                                  <span v-if="participant.sympla_ticket_number" class="badge badge-soft-primary font-size-11 mr-1">{{ participant.sympla_ticket_name }}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div> -->
                    </form>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1" v-if="parseInt(setting.account.data.access_type) === 1">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-2
                      class="text-dark"
                      href="javascript: void(0);"
                    >Senha de acesso</a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="password">Nova senha</label>
                        <div class="form-group">
                          <span v-if="!participant.id && setting.account.data.access_type === 1">
                            <input
                              id="password"
                              v-model="participant.password"
                              name="password"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.password.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.password.required"
                              class="invalid-feedback"
                            >
                              O campo senha é obrigatório.
                            </div>
                          </span>
                          <span v-if="participant.id">
                            <input
                              id="password"
                              v-model="participant.password"
                              name="password"
                              type="text"
                              class="form-control"
                            />
                          </span>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label for="repassword">Confirmar senha</label>
                        <div class="form-group">
                          <input
                            id="repassword"
                            v-model="participant.repassword"
                            name="repassword"
                            type="text"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-3
                      class="text-dark"
                      href="javascript: void(0);"
                    >Permissões à salas</a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="col-sm-12">
                        <label for="name">Salas</label>
                      <div class="form-group">
                        <multiselect
                          tag-placeholder="Selecione uma ou mais salas"
                          placeholder="Selecione uma ou mais salas"
                          v-model="participant.rooms"
                          :options="optionsRooms"
                          class="helo"
                          selectLabel="Click para selecionar"
                          selectedLabel="Selecionado"
                          deselectLabel="Click para remover"
                          label="name"
                          track-by="id"
                          :max-height="180"
                          :multiple="true"
                          open-direction="bottom"
                        >
                        </multiselect>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Histórico Movimentação</span>
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Histórico Gamificação</span>
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Pedidos de Trocas</span>
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Pedidos de Trocas</span>
            </template>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveParticipant()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { states } from './statesData'
import Multiselect from 'vue-multiselect'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import roomService from '@/pages/rooms/services'

export default {
  components: {
    Loading,
    Multiselect
  },
  props: {
    idParticipant: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Participante',
      loading: true,
      closed: true,
      selected: '',
      fullPage: true,
      submitted: false,
      states: states,
      rooms: [],
      optionsRooms: [],
      participant: {},
      modal: false
    }
  },
  validations () {
    if (this.participant.id) {
      return {
        participant: {
          name: { required },
          email: { required }
        }
      }
    } else {
      if (this.setting.account.data.access_type === 1) {
        return {
          participant: {
            name: { required },
            email: { required },
            password: { required }
          }
        }
      }
      return {
        participant: {
          name: { required },
          email: { required }
        }
      }
    }
  },
  methods: {
    ...mapActions('event', {
      GetSetting: 'GetSetting'
    }),
    ...mapActions('participant', {
      getParticipant: 'getParticipant',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.title = 'Ficha do participante'
        this.getParticipant(id).then((data) => {
          this.participant = this.data
          if (this.data.rooms) {
            this.participant.rooms = this.data.rooms.data
          }
        }).then(() => {
          this.loading = false
        })
      }
    },
    loadRooms () {
      roomService.list({ type: 1 }).then((res) => {
        this.optionsRooms = res.data.data
      })
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      this.loadRooms()
      this.GetSetting()
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveParticipant () {
      this.submitted = true
      this.$v.participant.$touch()
      if (this.$v.participant.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.participant).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.participant = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('event', ['setting']),
    ...mapState('participant', { data: 'participant' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
