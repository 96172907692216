<template>
  <b-modal v-model="modal" title="Palestrantes" size="xl">
    <div class="row">
      <!-- <div class="col-sm-12">
        <div class="search-box me-2 mb-2 d-inline-block">
          <div class="position-relative">
            <input type="text" v-model="searchSpeark" @input="searchSpeaker()" placeholder="Pesquisar..." class="form-control">
            <i class="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </div> -->
      <div v-if="listSpeakers.length === 0" class="col-md-12">
        <div class="alert alert-danger text-center p-4">
          Nenhum palestrante encontrado. Para cadastrar <a href="javascript:void(0)" @click="newSpeaker()">Clique aqui</a>
        </div>
      </div>
      <div class="col-xl-4 col-sm-6" v-for="(speaker, i) in listSpeakers" :key="i">
        <div class="card-body">
          <div class="media">
            <div class="avatar-md mr-2">
              <span class="avatar-title rounded bg-light text-danger font-size-16">
                <img :src="`${speaker.photo}`" alt class="rounded avatar-md"/>
              </span>
            </div>

            <div class="media-body overflow-hidden">
              <h5 class="text-truncate font-size-14">
                  <span class="text-dark">{{ speaker.name }}</span>
              </h5>
              <span class="text-muted mb-1">{{ speaker.title }}</span>
            </div>
            <div class="card-drop" right>
              <div class="custom-control custom-checkbox">
                <input
                  :id="`customCheck${speaker.id}`"
                  @input="alterSelected($event, speaker.id, i)"
                  :value="speaker.id"
                  v-model="speaker.checked"
                  type="checkbox"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label"
                  :for="`customCheck${speaker.id}`"
                  >&nbsp;</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <b-button variant="primary" @click="success()">Salvar</b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
  },
  props: {
    selecteds: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      loading: true,
      initState: [],
      searchSpeark: '',
      speakerSelecteds: [],
      modal: false
    }
  },
  methods: {
    ...mapActions('speaker', {
      GetSpeakers: 'GetSpeakers'
    }),
    newSpeaker () {
      this.$router.push({
        name: 'speaker-new'
      })
    },
    open () {
      this.selectSpeakers()
      this.initState = this.speakerSelecteds
      this.reloadSpeakers()
      this.modal = true
    },
    success () {
      let speakers = this.speakers.filter(s => s.checked)
      this.$emit('success', speakers)
      this.close()
    },
    close () {
      this.speakerSelecteds = this.initState
      this.modal = false
    },
    reloadSpeakers () {
      this.speakers.map((speaker) => {
        speaker.checked = this.speakerSelecteds.includes(speaker.id)
        return speaker
      })
    },
    selectSpeakers () {
      if (this.selecteds.length === 0) {
        return
      }
      this.selecteds.map(a => {
        this.speakerSelecteds.push(a.id)
      })
    },
    alterSelected (payload, id, idx) {
      if (!this.speakerSelecteds.includes(id)) {
        this.speakerSelecteds = [...this.speakerSelecteds, id]
        return
      }
      const index = this.speakerSelecteds.indexOf(id)
      this.$delete(this.speakerSelecteds, index)

      this.listSpeakers.map((speaker) => {
        if (speaker.id === id) {
          speaker.checked = false
          return speaker
        }
      })
    }
    // searchSpeaker () {
    //   this.GetSpeakers({ limit: 999, status: 1, search: this.searchSpeark }).then(() => {
    //     this.loading = false
    //   })
    // }
  },
  beforeMount () {
    let loader = this.$loading.show()
    this.GetSpeakers({ limit: 999, status: 1, search: this.searchSpeark }).then(() => {
      loader.hide()
    })
  },
  mounted () {
    this.selectSpeakers()
  },
  computed: {
    ...mapState('speaker', ['speakers']),
    listSpeakers () {
      return this.speakers.map((speaker) => {
        speaker.checked = this.speakerSelecteds.includes(speaker.id)
        return speaker
      })
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
