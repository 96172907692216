<template>
  <b-modal v-model="modal" :title="title" size="md" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveOrder()" autocomplete="off">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label for="status_order">Status</label>
                <select
                  id="status_order"
                  v-model="order.status"
                  name="status_order"
                  class="custom-select"
                  :class="{
                    'is-invalid': submitted && $v.order.status.$error
                  }"
                >
                  <option value="0">Em processamento</option>
                  <option value="1">Enviado</option>
                  <option value="2">Entregue</option>
                </select>
                <div
                  v-if="submitted && !$v.order.status.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label for="delivery_type">Tipo de Entrega</label>
                <select
                  id="delivery_type"
                  v-model="order.delivery_type"
                  name="delivery_type"
                  class="custom-select"
                  :class="{
                    'is-invalid': submitted && $v.order.delivery_type.$error
                  }"
                >
                  <option value="Transportadora">Transportadora</option>
                  <option value="Correios">Correios</option>
                  <option value="Retirou no local">Retirou no local</option>
                </select>
                <div
                  v-if="submitted && !$v.order.delivery_type.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-lg-12" v-if="order.delivery_type !== 'Retirou no local'">
              <div class="form-group">
                <label for="tracking_code">Código de rastreio</label>
                <input
                  id="tracking_code"
                  v-model="order.tracking_code"
                  name="tracking_code"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Fechar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveOrder()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Loading
  },
  data () {
    return {
      title: 'Atualizar pedido',
      loading: true,
      statusLabel: ['Em processamento', 'Em Transporte', 'Entregue'],
      fullPage: true,
      submitted: false,
      modal: false,
      categories: [],
      selected: '',
      order: {
      }
    }
  },
  validations () {
    return {
      order: {
        status: { required },
        delivery_type: { required }
      }
    }
  },
  computed: {
    ...mapState('order', { data: 'order' })
  },
  methods: {
    ...mapActions('order', {
      fetchItem: 'show',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.loading = true
        this.fetchItem(id).then((data) => {
          this.order = this.data
          this.title = 'Atualizar pedido: ' + this.order.order_number
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveOrder () {
      this.submitted = true
      this.$v.order.$touch()
      if (this.$v.order.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.order).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('update')
        this.loading = false
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.order = {}
      this.$v.$reset()
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.mt-15 { margin-top:15px }
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
