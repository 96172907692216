<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ title }}</h4>
            <div class="row mb-2 ">
              <div class="col-sm-8">
              </div>
              <div class="col-sm-4">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right me-2">
                    <button type="button" class="btn  btn-primary btn-rounded mb-2 mr-2" @click="showFilters()">
                      <i class="mdi mdi-filter mr-1"></i> Filtros
                    </button>
                  </div>
                  <div class="text-sm-right">
                    <button type="button" class="btn  btn-success btn-rounded mb-2 mr-2" @click="exportGamification()">
                      <i class="mdi mdi-file-export-outline mr-1"></i> Exportar Dados
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th width="20px"></th>
                    <th width="30px"></th>
                    <th width="30%">Nome</th>
                    <th witdh="25%">E-mail</th>
                    <th width="20%">Cidade</th>
                    <th width="8%">Pontos</th>
                    <th width="8%">Log</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, index) in gamification" :key="index">
                    <td>
                      <span class="badge font-size-13 badge-soft-secondary p-1 badge-num">{{ index + 1 }}</span>
                    </td>
                    <td>
                      <img class="rounded avatar-xs" :src="(list.user.avatar && list.user.avatar !== 'undefined') ? list.user.avatar : avatar" />
                    </td>
                    <td>{{ list.user.data.name }}</td>
                    <td>{{ list.user.data.email }}</td>
                    <td>{{ list.user.data.city }}</td>
                    <td><b>{{ list.total }} pts</b></td>
                    <td><a href="javascript:void(0)" @click="showDetails(list.user_id)">Detalhes</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-filter ref="modalFilter" @participantFiltered="getReport"></modal-filter>
    <gamification-details v-model="modalDetail" ref="modalDetails"></gamification-details>
  </div>
</template>
<script>
// Import component
import appConfig from '@/app.config'
import serviceReport from '../services'
import modalFilter from '@/components/modals/filters/ParticipantFilter'
import GamificationDetails from '@/components/modals/GamificationDetails'

export default {
  components: {
    modalFilter,
    GamificationDetails
  },
  name: 'report-gamification',
  page: {
    title: 'Relatório de Gamificação',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      avatar: require('@/assets/images/users/user-default.png'),
      params: {},
      modalDetail: false,
      totalRows: 1,
      rows: 0,
      currentPage: 1,
      gamification: [],
      perPage: 20,
      title: 'Relatório de Gamificação',
      items: [{
        text: 'Relatórios'
      },
      {
        text: 'Gamificação',
        active: true
      }
      ]
    }
  },
  mounted () {
    let loeader = this.$loading.show()
    serviceReport.listGamification({ limit: this.perPage }).then((res) => {
      this.gamification = res.data
      this.rows = res.meta.pagination.total
      this.currentPage = res.meta.pagination.current_page
      loeader.hide()
    }).catch(error => {
      console.log(error)
      loeader.hide()
    })
    this.totalRows = this.rows
  },
  created () {},
  computed: {
  },
  methods: {
    showDetails (userId) {
      this.modalDetail = true
      this.$refs.modalDetails.open(userId)
    },
    showFilters () {
      this.$refs.modalFilter.open()
    },
    exportGamification () {
      let loeader = this.$loading.show()
      serviceReport.exportGamification(this.params).then((res) => {
        this.download(res)
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    getReport (filter = '') {
      let loeader = this.$loading.show()
      let params = {
        limit: this.perPage,
        page: this.currentPage,
        city: filter.city || null,
        district: filter.district || null
      }
      this.params = params
      serviceReport.listGamification(params).then((res) => {
        this.gamification = res.data
        if (res.data.length > 0) {
          this.rows = res.meta.pagination.total
          this.currentPage = res.meta.pagination.current_page
        }
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    updateList () {
      this.getReport(this.params)
    }
  },
  watch: {
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
