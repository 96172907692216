<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <ContentGuide />
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import ContentGuide from '@/components/widgets/content-guide'
import appConfig from '@/app.config'
import { mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    ContentGuide
  },
  name: 'guideevent',
  page: {
    title: 'Guia do evento',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      partner: {
        id: '',
        name: '',
        title: '',
        description: '',
        thumb_image: '',
        allow_users: 0
      },
      loading: true,
      submitted: false,
      title: 'Guia do evento',
      items: [{
        text: 'Guia do evento',
        href: '/guide/hotel'
      },
      {
        text: 'Hotel',
        active: true
      }
      ]
    }
  },
  computed: {
    ...mapState('guide', { data: 'hotel' })
  },
  methods: {
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.link { cursor: pointer; }
.thumb {
  max-width: 100%;
  max-height: 168px;
  min-height: 168px;
}
</style>
