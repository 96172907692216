<template>
    <div class="email-leftbar card">
        <h6 class="mt-1 mb-1">Controles de Interatividade</h6>
        <div class="mail-list mt-1">
          <a href="javascript: void(0);">
            Chat
            <b-form-checkbox v-model="settings.chat" switch class="ml-1 mb-0 mt-1 float-right" @change="toggleControl('chat', settings.chat)"></b-form-checkbox>
          </a>
          <a href="javascript: void(0);">
            Eomjis
            <b-form-checkbox v-model="settings.emoji" switch class="ml-1 mb-0 mt-1 float-right" @change="toggleControl('emoji', settings.emoji)"></b-form-checkbox>
          </a>
          <a href="javascript: void(0);">
            Perguntas
            <b-form-checkbox v-model="settings.asking" switch class="ml-1 mb-0 mt-1 float-right" @change="toggleControl('asking', settings.asking)"></b-form-checkbox>
          </a>
          <a href="javascript: void(0);">
            Perguntas Públicas
            <b-form-checkbox v-model="settings.asking_public" switch class="ml-1 mb-0 mt-1 float-right" @change="toggleControl('asking_public', settings.asking_public)"></b-form-checkbox>
          </a>
          <a href="javascript: void(0);">
            Quiz
            <b-form-checkbox v-model="settings.quiz" switch class="ml-1 mb-0 mt-1 float-right" @change="toggleControl('quiz', settings.quiz)"></b-form-checkbox>
          </a>
          <a href="javascript: void(0);">
            Enquete
            <b-form-checkbox v-model="settings.poll" switch class="ml-1 mb-0 mt-1 float-right" @change="toggleControl('poll', settings.poll)"></b-form-checkbox>
          </a>
          <a href="javascript: void(0);">
            Nuvem de Palavras
            <b-form-checkbox v-model="settings.word_cloud" switch class="ml-1 mb-0 mt-1 float-right" @change="toggleControl('word_cloud', settings.word_cloud)"></b-form-checkbox>
          </a>
          <a href="javascript: void(0);">
            Call to Action
            <b-form-checkbox v-model="settings.call_action" switch class="ml-1 mb-0 mt-1 float-right" @change="toggleControl('call_action', settings.call_action)"></b-form-checkbox>
          </a>
          <a href="javascript: void(0);">
            Patrocinadores
            <b-form-checkbox v-model="settings.partner" switch class="ml-1 mb-0 mt-1 float-right" @change="toggleControl('partner', settings.partner)"></b-form-checkbox>
          </a>
        </div>
    </div>
</template>
<script>
import roomService from '@/pages/rooms/services'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapGetters } from 'vuex'

export default {
  components: {
    // Switches,
  },
  props: {
    controls: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: true,
      settings: {
        chat: false,
        emoji: false,
        asking: false,
        asking_public: false,
        quiz: false,
        poll: false,
        call_action: false,
        word_cloud: false,
        limit_word_cloud: false,
        partner: false
      }
    }
  },
  mounted () {
  },
  beforeMount () {
    this.getSettings()
  },
  computed: {
    ...mapGetters('room', {
      setting: 'SETTING'
    })
  },
  methods: {
    toggleControl (key, status) {
      if (status !== '') {
        let payload = {
          [key]: status ? 1 : 0,
          id: this.$route.params.id
        }
        roomService.toogleSetting(payload)
          .then((data) => {
            successMessage('Sucesso: ' + data.data.message, 2500)
          }).catch((error) => {
            this.settings[key] = false
            errorMessage('Atenção: ' + error.message)
          })
      }
    },
    getSettings () {
      let loader = this.$loading.show()
      this.settings = this.setting
      loader.hide()
    }
  }
}
</script>
<style>
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #5fc78a;
    background-color: #33b46d;
}
</style>
