var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"title":_vm.title,"size":"lg","no-enforce-focus":true,"no-close-on-backdrop":true},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancelar")]),_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"},on:{"click":function($event){return _vm.saveVideo()}}},[_vm._v("Salvar")])]},proxy:true}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('loading',{attrs:{"active":_vm.loading,"color":"#556ee6","loader":"dots","can-cancel":false,"is-full-page":false},on:{"update:active":function($event){_vm.loading=$event}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.saveVideo()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Título")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.video.title),expression:"video.title"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.video.title.$error
                },attrs:{"id":"title","name":"title","type":"text"},domProps:{"value":(_vm.video.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.video, "title", $event.target.value)}}}),(_vm.submitted && !_vm.$v.video.title.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo título é obrigatório. ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"url_video"}},[_vm._v("URL Vídeo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.video.url_video),expression:"video.url_video"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.video.url_video.$error
                },attrs:{"id":"url_video","name":"url_video","type":"text"},domProps:{"value":(_vm.video.url_video)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.video, "url_video", $event.target.value)}}}),(_vm.submitted && !_vm.$v.video.url_video.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo url é obrigatório. ")]):_vm._e(),(_vm.submitted && !_vm.$v.video.url_video.url)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo url vídeo deve ser uma url válida ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"url_image"}},[_vm._v("URL Imagem (Vimeo)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.video.url_image),expression:"video.url_image"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.video.url_image.$error
                },attrs:{"id":"url_image","name":"url_image","type":"text"},domProps:{"value":(_vm.video.url_image)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.video, "url_image", $event.target.value)}}}),(_vm.submitted && !_vm.$v.video.url_image.url)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo url imagem deve ser uma url válida ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Descrição")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.video.description),expression:"video.description"}],staticClass:"form-control",attrs:{"id":"description","name":"description","rows":"3"},domProps:{"value":(_vm.video.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.video, "description", $event.target.value)}}})])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }