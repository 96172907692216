<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="applyFilter()" autocomplete="off">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label for="domain">E-mail (Domínio)</label>
                <input
                  id="domain"
                  v-model="filter.domain"
                  name="domain"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="city">Cidade</label>
                <input
                  id="city"
                  v-model="filter.city"
                  name="city"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="district">Estado</label>
                <multiselect
                  v-model="selected"
                  :options="states" class="helo"
                  :max-height="180"
                  trackBy="value"
                  label="label"
                  placeholder="Selecione"
                >
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label for="created_start">Gerado De</label>
                <date-picker
                  id="created_end"
                  v-model="filter.created_start"
                  :first-day-of-week="1"
                  lang="pt-br"
                  format="DD/MM/YYYY"
                >
                </date-picker>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="created_end">Gerado Até</label>
                <date-picker
                  id="created_end"
                  v-model="filter.created_end"
                  :first-day-of-week="1"
                  lang="pt-br"
                  format="DD/MM/YYYY"
                >
                </date-picker>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                <label for="status">Status</label>
                <select
                  class="custom-select"
                  name="status"
                  v-model="filter.status"
                >
                  <option value="">Todos</option>
                  <option value="1">Ativo</option>
                  <option value="0">Bloqueado</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b-form-checkbox
              class="mb-2"
              v-model="filter.sympla"
              plain
              >Usuários importados do Sympla</b-form-checkbox>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Fechar</b-button>
      <b-button class="btn btn-dark" @click="clean()">Limpar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="applyFilter()">Aplicar</button>
    </template>
  </b-modal>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import { states } from '@/pages/participants/statesData'
import Multiselect from 'vue-multiselect'

export default {
  name: 'certificates-filter',
  components: {
    Multiselect,
    DatePicker
  },
  props: {},
  data () {
    return {
      title: 'Filtro - Certificados Gerados',
      selected: '',
      states: states,
      filter: {},
      modal: false
    }
  },
  methods: {
    open () {
      this.modal = true
    },
    applyFilter () {
      if (typeof (this.selected) === 'object') {
        this.filter.district = this.selected.value
      }
      this.$emit('certificateFiltered', this.filter)
      this.close()
    },
    close () {
      this.modal = false
    },
    clean () {
      this.filter = {}
      this.selected = ''
    }
  },
  computed: {
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
