<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ title }}</h4>
            <div class="row mb-2 ">
              <div class="col-sm-8">
              </div>
              <div class="col-sm-4">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right me-2">
                    <button type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="showFilters()">
                      <i class="mdi mdi-filter mr-1"></i> Filtros
                    </button>
                  </div>
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="exportEmojis()">
                      <i class="mdi mdi-file-export-outline mr-1"></i> Exportar Dados
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>E-mail</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in []" :key="list.id">
                    <td>{{ list.name }}</td>
                    <td>{{ list.email }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-filter ref="modalFilter" @participantFiltered="getReport"></modal-filter>
  </div>
</template>
<script>
// Import component
import appConfig from '@/app.config'
import serviceReport from '../services'
import modalFilter from '@/components/modals/filters/ParticipantFilter'

export default {
  components: {
    modalFilter
  },
  name: 'report-emojis',
  page: {
    title: 'Relatório de Emojis',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      avatar: require('@/assets/images/users/user-default.png'),
      params: {},
      totalRows: 1,
      rows: 0,
      currentPage: 1,
      participants: [],
      perPage: 20,
      title: 'Relatório de Emojis'
    }
  },
  mounted () {
    // let loeader = this.$loading.show()
    // serviceReport.listParticipants({ limit: this.perPage }).then((res) => {
    //   this.participants = res.data
    //   this.rows = res.meta.pagination.total
    //   this.currentPage = res.meta.pagination.current_page
    //   loeader.hide()
    // }).catch(error => {
    //   console.log(error)
    //   loeader.hide()
    // })
    // this.totalRows = this.rows
  },
  created () {},
  computed: {
  },
  methods: {
    showFilters () {
      this.$refs.modalFilter.open()
    },
    exportEmojis () {
      let loeader = this.$loading.show()
      serviceReport.exportEmojis(this.params).then((res) => {
        this.download(res)
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    getReport (filter) {
      let loeader = this.$loading.show()
      let params = {
        limit: this.perPage,
        page: this.currentPage,
        domain: filter.domain || null,
        city: filter.city || null,
        district: filter.district || null,
        created_start: filter.created_start ? this.$moment(filter.created_start).format('YYYY-MM-DD') : null,
        created_end: filter.created_end ? this.$moment(filter.created_end).format('YYYY-MM-DD') : null,
        status: filter.status || null
      }
      this.params = params
      serviceReport.listParticipants(params).then((res) => {
        this.participants = res.data
        if (res.data.length > 0) {
          this.rows = res.meta.pagination.total
          this.currentPage = res.meta.pagination.current_page
        }
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    updateList () {
      this.getAll({ limit: this.perPage, include: 'schedules,rooms', page: this.currentPage })
    }
  },
  watch: {
    currentPage () { this.updateList() },
    total () { this.updateList() },
    dataPerPage () { this.updateList() }
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
