var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"title":_vm.title,"size":"lg","no-enforce-focus":true,"no-close-on-backdrop":true},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancelar")]),_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"},on:{"click":function($event){return _vm.saveInput()}}},[_vm._v("Salvar")])]},proxy:true}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('loading',{attrs:{"active":_vm.loading,"color":"#556ee6","loader":"dots","can-cancel":false,"is-full-page":false},on:{"update:active":function($event){_vm.loading=$event}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.saveInput()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"type_input"}},[_vm._v("Tipo de campo")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.type_input),expression:"form.type_input"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.type_input.$error
                },attrs:{"id":"type_input","name":"type_input","type":"text"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "type_input", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"cpf"}},[_vm._v("CPF")]),_c('option',{attrs:{"value":"cnpj"}},[_vm._v("CNPJ")]),_c('option',{attrs:{"value":"phone"}},[_vm._v("Telefone")]),_c('option',{attrs:{"value":"number"}},[_vm._v("Númerico")]),_c('option',{attrs:{"value":"text"}},[_vm._v("Texto")]),_c('option',{attrs:{"value":"checkbox"}},[_vm._v("Caixa de seleção")]),_c('option',{attrs:{"value":"select"}},[_vm._v("Combobox")]),_c('option',{attrs:{"value":"email"}},[_vm._v("E-mail")]),_c('option',{attrs:{"value":"cep"}},[_vm._v("CEP")]),_c('option',{attrs:{"value":"ufs"}},[_vm._v("UFs")]),_c('option',{attrs:{"value":"radio"}},[_vm._v("Radio")]),_c('option',{attrs:{"value":"textarea"}},[_vm._v("Multi Texto")])]),(_vm.submitted && !_vm.$v.form.type_input.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nome do campo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.name.$error
                },attrs:{"id":"name","name":"name","type":"text"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.form.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"required"}},[_vm._v("Obrigatório")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.required),expression:"form.required"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.required.$error
                },attrs:{"id":"required","name":"required","type":"text"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "required", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Sim")]),_c('option',{attrs:{"value":"0"}},[_vm._v("Não")])]),(_vm.submitted && !_vm.$v.form.required.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-8"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"label"}},[_vm._v("Título do campo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.label),expression:"form.label"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.label.$error
                },attrs:{"id":"label","name":"label","type":"text"},domProps:{"value":(_vm.form.label)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "label", $event.target.value)}}}),(_vm.submitted && !_vm.$v.form.label.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"placeholder"}},[_vm._v("Título secundário")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.placeholder),expression:"form.placeholder"}],staticClass:"form-control",attrs:{"id":"placeholder","name":"placeholder","type":"text"},domProps:{"value":(_vm.form.placeholder)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "placeholder", $event.target.value)}}})])])]),(_vm.form.type_input === 'select' || _vm.form.type_input === 'radio')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"options"}},[_vm._v("Opções")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.options),expression:"form.options"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.options.$error
                },attrs:{"id":"options","name":"options","type":"text"},domProps:{"value":(_vm.form.options)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "options", $event.target.value)}}}),_vm._v(" "),(_vm.submitted && !_vm.$v.form.options.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()])])]):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }