<template>
  <div style="margin-left:-15px">
    <div class="row mb-2">
      <div class="col-sm-6">
        <b-dropdown id="dropdown-dropright" :text="`${perPage} itens por página`" variant="outline-dark" dropright size="sm" class="m-2">
          <b-dropdown-item @click="limitPage(20)">20 itens por página</b-dropdown-item>
          <b-dropdown-item @click="limitPage(50)">50 itens por página</b-dropdown-item>
          <b-dropdown-item @click="limitPage(100)">100 itens por página</b-dropdown-item>
          <b-dropdown-item @click="limitPage(1000)">1000 itens por página</b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="col-sm-6">
        <div class="text-sm-right">
          <button type="button" class="btn btn-success btn-sm btn-rounded" @click="newAsking()">
            <i class="mdi mdi-plus"></i> Inserir Questão
          </button>
        </div>
      </div>
      <!-- end col-->
    </div>
    <div class="row">
      <div class="col-md-12 text-center" v-show="quizzes.length === 0">
        <h5 class="text-truncate font-size-14 p-4 mt-2">Nenhum quiz configurado. </h5>
        <i class="bx bx-tired bx-lg"></i>
        <br />
      </div>
      <div class="table-responsive mt-3 mb-0" v-show="quizzes.length > 0">
        <table class="table table-centered table-nowrap table-hover">
          <thead>
            <tr>
              <th width="20px"></th>
              <th width="20px">Seq</th>
              <th width="60%">Pergunta</th>
              <th>Respostas</th>
              <th>Liberada</th>
              <th>Ações</th>
            </tr>
          </thead>
          <draggable
            v-model="quizzes"
            :element="'tbody'"
            :options="{animation:200, handle:'.mover'}"
            @change="updateSequence"
          >
            <tr v-for="(quiz, i) in quizzes" :key="i" class="link" :class="{ 'quiz-archived': quiz.archived }">
              <td class="mover"><i class="bx bx-move"></i></td>
              <td>
                <span class="badge font-size-13 badge-soft-primary p-1 badge-num">{{ quiz.sequence }}</span>
              </td>
              <td @click="showAsking(quiz.id)">{{ quiz.asking.length > 80 ? quiz.asking.substring(0, 80) + '...' : quiz.asking }}</td>
              <td @click="showAsking(quiz.id)">{{ quiz.answers.data.length }}</td>
              <td>
                <b-form-checkbox v-model="quiz.status" switch @change="toggleStatus(quiz.id)" :disabled="quiz.archived ? true : false"></b-form-checkbox>
              </td>
              <td>
                <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal font-size-18"></i>
                  </template>
                  <b-dropdown-item @click="showAsking(quiz.id)">
                    <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="toggleStatus(quiz.id, 1)">
                    <i class="fas fa-archive mr-1" :class="{'text-success': quiz.archived === 1, 'text-danger': quiz.archived === 0}"></i> {{ quiz.archived === 1 ? 'Desarquivar' : 'Arquivar' }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteAsking(quiz.id)">
                    <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="showChartResult(quiz.id)">
                    <i class="fas fa-chart-bar text-primary mr-1"></i> Gráfico
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </draggable>
        </table>
      </div>
    </div>
    <div class="row mt-4" v-show="quizzes.length > 0">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
          </ul>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-center"
      ref="modal-quiz-asking"
      :title="quiz.id ? 'Editar questão' : 'Cadastre uma questão'"
      title-class="font-18"
      size="lg"
      :no-close-on-backdrop="true"
    >
      <form @submit.prevent="createQuiz()">
        <div class="row mt-2">
          <div class="col-sm-9">
            <div class="form-group">
              <label for="asking">Questão</label>
              <input
                v-model="quiz.asking"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.quiz.asking.$error,
                }"
              />
              <div
                v-if="submitted && !$v.quiz.asking.required"
                class="invalid-feedback"
              >
              O campo questão é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="show_answer">Exibir Resposta</label>
              <select
                class="custom-select"
                name="show_answer"
                id="show_answer"
                v-model="quiz.show_answer"
                :class="{
                  'is-invalid': submitted && $v.quiz.show_answer.$error,
                }">
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </select>
              <div
                v-if="submitted && !$v.quiz.show_answer.required"
                class="invalid-feedback"
              >
              Exibir resposta é obrigatório
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="text-truncate font-size-14 float-left mr-3">Respostas</h5>
            <span class="link text-success" @click="addItem()"><i class="mdi mdi-plus text-success"></i> Adicionar</span>
            <!-- <hr class="mt-0" /> -->
          </div>
        </div>
        <div class="row" v-for="(askingAnswer, index) in quiz.answers" :key="'answ-'+index">
          <div class="col-sm-8">
            <div class="form-group">
              <label for="asking">Resposta</label>
              <input
                v-model="askingAnswer.answer"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.quiz.answers.$each[index].answer.$error,
                }"
              />
              <div
                v-if="submitted && !$v.quiz.answers.$each[index].answer.required"
                class="invalid-feedback"
              >
              O campo resposta é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="correct">Correta</label>
              <select
                class="custom-select"
                name="correct"
                id="correct"
                v-model="askingAnswer.correct"
                :class="{
                  'is-invalid': submitted && $v.quiz.answers.$each[index].correct.$error,
                }">
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </select>
              <div
                v-if="submitted && !$v.quiz.answers.$each[index].required"
                class="invalid-feedback"
              >
              O campo correto é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-1">
            <div class="form-group mt-4">
              <i class="fas fa-trash-alt text-danger link mt-15" @click="delItem(index)"></i>
            </div>
          </div>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeModal()">Cancelar</b-button>
        <b-button variant="primary" @click="createQuiz()">Salvar</b-button>
      </template>
    </b-modal>
    <modal-chart-quiz v-model="modalQuizChart" ref="resultQuiz"></modal-chart-quiz>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import Swal from 'sweetalert2'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState, mapGetters } from 'vuex'
import { errorMessage, successMessage, warningMessage } from '@/components/alertsMessages'
import modalChartQuiz from '@/components/modals/charts/QuizResult'

export default {
  components: {
    modalChartQuiz,
    draggable
  },
  name: 'quizzes',
  data () {
    return {
      modalQuizChart: false,
      submitted: false,
      params: {},
      quiz: {
        status: false,
        answers: [{
          answer: '',
          correct: ''
        }]
      },
      replyAsking: {
        asnwer: '',
        user: {
          data: {}
        }
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20
    }
  },
  validations: {
    quiz: {
      show_answer: { required },
      asking: { required },
      answers: {
        $each: {
          answer: { required },
          correct: { required }
        }
      }
    }
  },
  beforeMount () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  computed: {
    ...mapGetters('quiz', { listQuiz: 'QUIZZES' }),
    ...mapState('quiz', ['pagination']),
    ...mapState('quiz', { data: 'quiz' }),
    rows () {
      return this.pagination.total
    },
    quizzes: {
      get () {
        return this.listQuiz
      },
      set (value) {
        let loader = this.$loading.show()
        this.updateSequence({ room_id: this.$route.params.id, itens: value }).then(res => {
          loader.hide()
          successMessage('Sucesso: Ordem atualizada.', 2500)
        })
      }
    }
  },
  methods: {
    ...mapActions('quiz', {
      fetchData: 'getList',
      fetchItem: 'show',
      postQuiz: 'save',
      changeStatus: 'changeStatus',
      deleteItem: 'deleteQuiz',
      deleteItemAnswer: 'deleteAnswer',
      updateSequence: 'orderList'
    }),
    cleanQuiz () {
      this.quiz = { answers: [{ answer: '', correct: '' }] }
    },
    addItem () {
      const asnwer = { answer: '', correct: '' }
      this.quiz.answers = [...this.quiz.answers, asnwer]
    },
    delItem (indice) {
      if (this.quiz.answers.length === 1) {
        warningMessage('Atenção: Quiz deve conter ao menos uma resposta!')
        return
      }
      let item = this.quiz.answers[indice]
      if (item.hasOwnProperty('id')) {
        this.deleteAnswer(item.id, indice)
      } else {
        this.quiz.answers.splice(indice, 1)
      }
    },
    showChartResult (quizId) {
      this.$refs.resultQuiz.open(quizId)
      this.modalQuizChart = true
    },
    newAsking () {
      this.$refs['modal-quiz-asking'].show()
    },
    showAsking (id) {
      let loader = this.$loading.show()
      this.fetchItem({ room_id: this.$route.params.id, id }).then((res) => {
        this.$refs['modal-quiz-asking'].show()
        this.quiz = this.data
        loader.hide()
      })
    },
    closeModal () {
      this.$refs['modal-quiz-asking'].hide()
      this.cleanQuiz()
      this.$v.$reset()
    },
    deleteAnswer (id, indice) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItemAnswer({ room_id: this.$route.params.id, id: id }).then((res) => {
              successMessage('Sucesso: Pergunta excluída com sucesso.', 2500)
              this.quiz.answers.splice(indice, 1)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    deleteAsking (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem({ room_id: this.$route.params.id, id: id }).then((res) => {
              successMessage('Sucesso: Pergunta excluída com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    async reply () {
      this.submitted = true
      this.$v.replyAsking.$touch()
      if (this.$v.replyAsking.$invalid) {
        return
      }
      let loader = this.$loading.show()
      this.replyAsking.status = 1
      await this.postData(this.replyAsking).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.$refs['modal-asking'].hide()
        loader.hide()
        this.updateList()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    async toggleStatus (id, status = '') {
      try {
        await this.changeStatus({ room_id: this.$route.params.id, id, status })
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async createQuiz () {
      this.submitted = true
      this.quiz.room_id = this.$route.params.id
      this.$v.quiz.$touch()
      if (this.$v.quiz.$invalid) {
        window.scroll(0, 0)
        return
      }
      await this.postQuiz(this.quiz).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.closeModal()
        this.updateList()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    updateList () {
      let loader = this.$loading.show()
      this.fetchData({ room_id: this.$route.params.id, limit: this.perPage, page: this.currentPage, include: 'answers' }).then(() => {
        loader.hide()
      })
    },
    limitPage (limit) {
      this.perPage = limit
      this.updateList()
    }
  },
  watch: {
    // currentPage () { this.updateList() },
    // total () { this.updateList() },
    dataPerPage () { this.updateList() }
  }
}
</script>

<style scoped>
.table-responsive { min-height: 400px;}
.rounded-drop .dropdown-toggle { border-radius: 1rem !important}
.title-asking { font-weight: 600;}
.quiz-archived { color: #909090; background: #fff0f0}
.asking {
  background: #f6f6f6;
  border-top: 1px solid #e2e2e2;
  margin-top: 0px;
  padding:1rem;
}
.swal2-popup {
  font-size: 11px !important;
}
.mt-15 { margin-top:15px }
</style>
