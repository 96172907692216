<template>
  <div style="margin-left:-15px">
    <div class="row mb-2">
      <div class="col-sm-6">
        <b-dropdown id="dropdown-dropright" :text="`${perPage} itens por página`" variant="outline-dark" dropright size="sm" class="m-2">
          <b-dropdown-item @click="limitPage(20)">20 itens por página</b-dropdown-item>
          <b-dropdown-item @click="limitPage(50)">50 itens por página</b-dropdown-item>
          <b-dropdown-item @click="limitPage(100)">100 itens por página</b-dropdown-item>
          <b-dropdown-item @click="limitPage(1000)">1000 itens por página</b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="col-sm-6">
        <div class="text-sm-right">
          <button type="button" class="btn btn-success btn-sm btn-rounded" @click="newPoll()">
            <i class="mdi mdi-plus"></i> Inserir Enquete
          </button>
        </div>
      </div>
      <!-- end col-->
    </div>
    <div class="row">
      <div class="col-md-12 text-center" v-show="polls.length === 0">
        <h5 class="text-truncate font-size-14 p-4 mt-2">Nenhuma enquete configurada. </h5>
        <i class="bx bx-tired bx-lg"></i>
        <br />
      </div>
      <div class="table-responsive mt-3 mb-0" v-show="polls.length > 0">
        <table class="table table-centered table-nowrap table-hover">
          <thead>
            <tr>
              <th width="20px"></th>
              <th width="20px">Seq</th>
              <th width="60%">Enquete</th>
              <th>Opções</th>
              <th>Liberada</th>
              <th>Ações</th>
            </tr>
          </thead>
          <draggable
            v-model="polls"
            :element="'tbody'"
            :options="{animation:200, handle:'.mover'}"
            @change="updateSequence"
          >
            <tr v-for="(poll, i) in polls" :key="i" class="link" :class="{ 'poll-archived': poll.archived }">
              <td class="mover"><i class="bx bx-move"></i></td>
              <td>
                <span class="badge font-size-13 badge-soft-primary p-1 badge-num">{{ poll.sequence }}</span>
              </td>
              <td @click="showPoll(poll.id)" title="Click para editar">{{ poll.description.length > 70 ? poll.description.substring(0, 70) + '...' : poll.description }}</td>
              <td @click="showPoll(poll.id)" title="Click para editar">{{ poll.options.data.length }}</td>
              <td>
                <b-form-checkbox v-model="poll.status" switch @change="toggleStatus(poll.id)" :disabled="poll.archived ? true : false"></b-form-checkbox>
              </td>
              <td>
                <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal font-size-18"></i>
                  </template>
                  <b-dropdown-item @click="showPoll(poll.id)">
                    <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="toggleStatus(poll.id, 1)">
                    <i class="fas fa-archive mr-1" :class="{'text-success': poll.archived === 1, 'text-danger': poll.archived === 0}"></i> {{ poll.archived === 1 ? 'Desarquivar' : 'Arquivar' }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="deletePoll(poll.id)">
                    <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="showChartResult(poll.id)">
                    <i class="fas fa-chart-bar text-primary mr-1"></i> Gráfico
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </draggable>
        </table>
      </div>
    </div>
    <div class="row mt-4" v-show="polls.length > 0">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
          </ul>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-center"
      ref="modal-poll"
      :title="poll.id ? 'Editar Enquete' : 'Cadastre uma enquete'"
      title-class="font-18"
      size="lg"
      :no-close-on-backdrop="true"
    >
      <form @submit.prevent="createPoll()">
        <div class="row mt-2">
          <div class="col-sm-9">
            <div class="form-group">
              <label for="description">Descrição</label>
              <input
                v-model="poll.description"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.poll.description.$error,
                }"
              />
              <div
                v-if="submitted && !$v.poll.description.required"
                class="invalid-feedback"
              >
              O campo descrição é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="show_result">Exibir Resultado</label>
              <select
                class="custom-select"
                name="show_result"
                id="show_result"
                v-model="poll.show_result"
                :class="{
                  'is-invalid': submitted && $v.poll.show_result.$error,
                }">
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </select>
              <div
                v-if="submitted && !$v.poll.show_result.required"
                class="invalid-feedback"
              >
              Exibir resultado é obrigatório
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="text-truncate font-size-14 float-left mr-3">Opções</h5>
            <span class="link text-success" @click="addItem()"><i class="mdi mdi-plus text-success"></i> Adicionar</span>
            <!-- <hr class="mt-0" /> -->
          </div>
        </div>
        <div class="row" v-for="(option, index) in poll.options" :key="'opt-'+index">
          <div class="col-sm-2">
            <div class="form-group">
              <label for="picture">Imagem</label>
              <input
                :ref="`thumb${index}`"
                type="file"
                name="thumb"
                accept="image/*"
                @change="setImage"
              />
              <div class="link" @click.prevent="showChooser(index)">
                <div class="dropzone" v-if="!option.cropImg && !option.picture" style="width:60px;height:60px">
                  <div class="dropzone-custom-content text-center">
                    <i class="text-muted bx bxs-cloud-upload"></i>
                  </div>
                </div>
                <img :src="option.cropImg || (option.picture)" v-if="option.cropImg || option.picture" class="rounded thumb-poll border-light" >
              </div>
            </div>
          </div>
          <div class="col-sm-9">
            <div class="form-group">
              <label for="asking">Opção</label>
              <input
                v-model="option.option"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.poll.options.$each[index].option.$error,
                }"
              />
              <div
                v-if="submitted && !$v.poll.options.$each[index].option.required"
                class="invalid-feedback"
              >
              O campo opção é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-1">
            <div class="form-group mt-4">
              <i class="fas fa-trash-alt text-danger link mt-15" @click="delItem(index)"></i>
            </div>
          </div>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeModal()">Cancelar</b-button>
        <b-button variant="primary" @click="createPoll()">Salvar</b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-center"
      ref="modal-thumb"
      centered
      size="sm"
      title="Adicionar Imagem"
      title-class="font-18"
      @hidden="cancel('thumb')"
      @ok="cropImage"
    >
      <div class="img-cropper border-dashed" v-show="imgSrc">
        <vue-cropper
          ref="cropper"
          :src="imgSrc"
          preview=".preview"
        />
      </div>
    </b-modal>
    <modal-chart-poll v-model="modalPollChart" ref="resultPoll"></modal-chart-poll>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import Swal from 'sweetalert2'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState, mapGetters } from 'vuex'
import { errorMessage, successMessage, warningMessage } from '@/components/alertsMessages'
import modalChartPoll from '@/components/modals/charts/PollResult'

export default {
  components: {
    modalChartPoll,
    draggable,
    VueCropper
  },
  name: 'polls',
  data () {
    return {
      submitted: false,
      params: {},
      modalPollChart: false,
      imgSrc: '',
      indexImage: '',
      poll: {
        status: false,
        options: [{
          cropImg: '',
          option: '',
          picture: ''
        }]
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20
    }
  },
  validations: {
    poll: {
      show_result: { required },
      description: { required },
      options: {
        $each: {
          option: { required }
        }
      }
    }
  },
  beforeMount () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  computed: {
    ...mapGetters('poll', { listPolls: 'POLLS' }),
    ...mapState('poll', ['pagination']),
    ...mapState('poll', { data: 'poll' }),
    rows () {
      return this.pagination.total
    },
    polls: {
      get () {
        return this.listPolls
      },
      set (value) {
        let loader = this.$loading.show()
        this.updateSequence({ room_id: this.$route.params.id, itens: value }).then(res => {
          loader.hide()
          successMessage('Sucesso: Ordem atualizada.', 2500)
        })
      }
    }
  },
  methods: {
    ...mapActions('poll', {
      fetchData: 'getList',
      fetchItem: 'show',
      postPoll: 'save',
      changeStatus: 'changeStatus',
      deleteItem: 'deletePoll',
      deleteItemOption: 'deleteOption',
      updateSequence: 'orderList'
    }),
    cancel (ref) {
      this.$refs[`${ref}${this.indexImage}`].value = null
      this.imgSrc = ''
    },
    showChartResult (pollId) {
      this.$refs.resultPoll.open(pollId)
      this.modalPollChart = true
    },
    cropImage () {
      // get image data for post processing, e.g. upload or setting image src
      this.poll.options[this.indexImage].cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        console.log(blob)
        this.poll.options[this.indexImage].binary = blob
      })
      setTimeout(() => {
        this.$refs.cropper.reset()
      }, 100)
    },
    setImage (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 1
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 1MB!')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
        this.$refs['modal-thumb'].show()
        this.setData()
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    setData () {
      setTimeout(() => {
        this.$refs.cropper.setCropBoxData({ width: 120, height: 120 })
      }, 200)
    },
    showChooser (index) {
      this.$refs['thumb' + index][0].click()
      this.indexImage = index
    },
    cleanPoll () {
      this.poll = { options: [{ option: '', picture: '' }] }
    },
    addItem () {
      const option = { option: '', picture: '' }
      this.poll.options = [...this.poll.options, option]
    },
    delItem (indice) {
      if (this.poll.options.length === 1) {
        warningMessage('Atenção: Enquete deve conter ao menos uma opção!')
        return
      }
      let item = this.poll.options[indice]
      if (item.hasOwnProperty('id')) {
        this.deleteOption(item.id, indice)
      } else {
        this.poll.options.splice(indice, 1)
      }
    },
    newPoll () {
      this.$refs['modal-poll'].show()
    },
    showPoll (id) {
      let loader = this.$loading.show()
      this.fetchItem({ room_id: this.$route.params.id, id }).then((res) => {
        this.$refs['modal-poll'].show()
        this.poll = this.data
        loader.hide()
      })
    },
    closeModal () {
      this.$refs['modal-poll'].hide()
      this.cleanPoll()
      this.$v.$reset()
    },
    deleteOption (id, indice) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItemOption({ room_id: this.$route.params.id, id: id }).then((res) => {
              successMessage('Sucesso: Opção excluída com sucesso.', 2500)
              this.poll.options.splice(indice, 1)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    deletePoll (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem({ room_id: this.$route.params.id, id: id }).then((res) => {
              successMessage('Sucesso: Enquete excluída com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    async toggleStatus (id, status = '') {
      try {
        await this.changeStatus({ room_id: this.$route.params.id, id, status })
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async createPoll () {
      this.submitted = true
      this.poll.room_id = this.$route.params.id
      this.$v.poll.$touch()
      if (this.$v.poll.$invalid) {
        window.scroll(0, 0)
        return
      }
      const formData = new FormData()
      if (this.poll.id) {
        formData.append('id', this.poll.id)
      }
      formData.append('room_id', this.poll.room_id)
      formData.append('description', this.poll.description)
      formData.append('show_result', this.poll.show_result)
      this.poll.options.forEach((option, index) => {
        formData.append(`options[${index}][option]`, option.option)
        if (option.binary) {
          formData.append(`options[${index}][picture]`, option.binary, `thumb_picture_${index}.jpg`)
        }
        if (option.id) {
          formData.append(`options[${index}][id]`, option.id)
          formData.append(`options[${index}][poll_id]`, option.poll_id)
        }
      })

      await this.postPoll(formData).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.closeModal()
        this.updateList()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loadingPoll = false
      })
    },
    updateList () {
      let loader = this.$loading.show()
      this.fetchData({ room_id: this.$route.params.id, limit: this.perPage, page: this.currentPage, include: 'options' }).then(() => {
        loader.hide()
      })
    },
    limitPage (limit) {
      this.perPage = limit
      this.updateList()
    }
  },
  watch: {
    // currentPage () { this.updateList() },
    // total () { this.updateList() },
    dataPerPage () { this.updateList() }
  }
}
</script>

<style scoped>
.table-responsive { min-height: 400px;}
.dropzone {min-height: 60px !important;}
.thumb-poll { max-width: 60px !important; max-height: 60px !important;}
.rounded-drop .dropdown-toggle { border-radius: 1rem !important}
.title-asking { font-weight: 600;}
.poll-archived { color: #909090; background: #fff0f0}
.asking {
  background: #f6f6f6;
  border-top: 1px solid #e2e2e2;
  margin-top: 0px;
  padding:1rem;
}
.swal2-popup {
  font-size: 11px !important;
}
.mt-15 { margin-top:15px }
input[type="file"] {
  display: none !important;
}
</style>
