<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="!setting.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden text-center mt-4">
                <h5 class="text-truncate font-size-15 ">Olá, {{ user.name }}.</h5>
                <p class="text-muted">Indentificamos que a sua plataforma ainda não está configurada.</p>

                <b-button variant="primary" size="lg" class="mt-4 mb-4" @click="initSetting()">
                  <i class="bx bx-cog font-size-16 align-middle mr-2"></i>
                  CONFIGURAR AGORA!
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="setting.id || configEvent">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="saveSetting()">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="name">Nome do evento</label>
                    <input
                      id="name"
                      name="name"
                      v-model="setting.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.setting.name.$error,
                      }"
                      placeholder="Inform o nome do evento"
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="name">E-mail de Contato</label>
                    <input
                      id="name"
                      name="name"
                      v-model="setting.contact_email"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.setting.contact_email.$error,
                      }"
                      placeholder="Informe um e-mail para contato"
                    />
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="phone">Telefone para suporte</label>
                    <input
                      id="phone"
                      name="phone"
                      v-model="setting.phone"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.setting.phone.$error,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <label for="description">Descrição do Evento</label>
                  <div class="form-group">
                    <ckeditor
                      :editor="editor"
                      v-model="setting.description"
                      :config="editorConfig"
                      :class="{
                        'is-invalid': submitted && $v.setting.description.$error,
                      }"
                    >
                    </ckeditor>
                  </div>
                </div>
              </div>
              <div class="mt-1 float-right">
                <button type="submit" class="btn btn-primary mr-1">
                  Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <form @submit.prevent="saveSettingAccount()">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Parâmetros</h4>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="access_type">Tipo de acesso</label>
                  <select
                    class="custom-select"
                    name="access_type"
                    id="access_type"
                    v-model="sa.access_type"
                    :class="{
                      'is-invalid': submitted && $v.sa.access_type.$error,
                    }">
                    <option value="1">Email e senha</option>
                    <option value="2">E-mail</option>
                  </select>
                  <div
                    v-if="submitted && !$v.sa.access_type.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="complete_profile">Completar perfil?</label>
                  <select
                    class="custom-select"
                    name="complete_profile"
                    id="complete_profile"
                    v-model="sa.complete_profile"
                    :class="{
                      'is-invalid': submitted && $v.sa.complete_profile.$error,
                    }">
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                  <div
                    v-if="submitted && !$v.sa.complete_profile.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="send_email_registration">E-mail após cadastro</label>
                  <select
                    class="custom-select"
                    name="send_email_registration"
                    id="send_email_registration"
                    v-model="sa.send_email_registration"
                    :class="{
                      'is-invalid': submitted && $v.sa.send_email_registration.$error,
                    }">
                    <option value="1">Sim! Enviar e-mail após cadastro</option>
                    <option value="0">Não enviar e-mail</option>
                  </select>
                  <div
                    v-if="submitted && !$v.sa.send_email_registration.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="session_login">Acesso liberado?</label>
                  <select
                    class="custom-select"
                    name="session_login"
                    id="session_login"
                    v-model="sa.session_login"
                    :class="{
                      'is-invalid': submitted && $v.sa.session_login.$error,
                    }">
                    <option value="1">Autorizado</option>
                    <option value="0">Suspenso</option>
                  </select>
                  <div
                    v-if="submitted && !$v.sa.session_login.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="session_register">Cadastro liberado?</label>
                  <select
                    class="custom-select"
                    name="session_register"
                    id="session_register"
                    v-model="sa.session_register"
                    :class="{
                      'is-invalid': submitted && $v.sa.session_register.$error,
                    }">
                    <option value="1">Autorizado</option>
                    <option value="0">Suspenso</option>
                  </select>
                  <div
                    v-if="submitted && !$v.sa.session_register.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" v-if="parseInt(sa.session_login) === 0">
                  <label for="message_login_disabled">Mensagem de acesso bloqueio</label>
                  <input
                    id="message_login_disabled"
                    name="message_login_disabled"
                    v-model="setting.message_login_disabled"
                    type="text"
                    class="form-control"
                    placeholder="Informe uma mensagem para o login suspenso."
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="rsvp">RSVP Ativo?</label>
                  <select
                    class="custom-select"
                    name="rsvp"
                    id="rsvp"
                    v-model="sa.rsvp"
                    :class="{
                      'is-invalid': submitted && $v.sa.rsvp.$error,
                    }">
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                  <div
                    v-if="submitted && !$v.sa.rsvp.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </div>
                </div>
              </div>
              <!-- <div class="col-sm-3">
                <div class="form-group">
                  <label for="only_moderators_post_feed">Feed Privado</label>
                  <select
                    class="custom-select"
                    name="only_moderators_post_feed"
                    id="only_moderators_post_feed"
                    v-model="sa.only_moderators_post_feed"
                    :class="{
                      'is-invalid': submitted && $v.sa.only_moderators_post_feed.$error,
                    }">
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                  <div
                    v-if="submitted && !$v.sa.only_moderators_post_feed.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </div>
                </div>
              </div> -->
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="after_login">Página principal</label>
                  <select
                    class="custom-select"
                    name="after_login"
                    id="after_login"
                    v-model="sa.after_login"
                    :class="{
                      'is-invalid': submitted && $v.sa.after_login.$error,
                    }">
                    <option value="1">Feed</option>
                    <option value="2">Dashboard</option>
                  </select>
                  <div
                    v-if="submitted && !$v.sa.after_login.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-1 float-right">
              <button type="submit" class="btn btn-primary mr-1">
                Salvar
              </button>
            </div>
          </div>
        </div>
        </form>
      </div>
    </div>
    <b-modal
      id="modal-center"
      ref="modal-logo"
      centered
      title="Alterar Logotipo"
      title-class="font-18"
      @hidden="cancel('logo', 1)"
      @ok="cropImage"
    >
      <div class="img-cropper border-dashed" v-show="imgSrc">
        <vue-cropper
          ref="cropper"
          :src="imgSrc"
          preview=".preview"
        />
      </div>
    </b-modal>
    <b-modal
      id="modal-center"
      ref="modal-banner-home"
      size="lg"
      centered
      title="Alterar Banner Home"
      title-class="font-18"
      @hidden="cancel('bannerhome', 3)"
      @ok="cropBH"
    >
      <div class="img-cropper border-dashed" v-show="imgSrcBannerHome">
        <vue-cropper
          ref="cropperBH"
          :src="imgSrcBannerHome"
          preview=".preview"
        />
      </div>
    </b-modal>
  </Layout>
</template>
<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import CKEditor from '@ckeditor/ckeditor5-vue'
import FullEditor from 'ckeditor5-build-full'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    ckeditor: CKEditor.component,
    VueCropper,
    Layout,
    PageHeader
  },
  name: 'Evento',
  page: {
    title: 'Configurações',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      editor: FullEditor,
      editorConfig: {},
      sa: {},
      loading: true,
      singleImageBH: false,
      imgSrc: '',
      imgSrcBannerHome: '',
      cropImg: '',
      cropBannerHome: '',
      cropLogo: false,
      resetLogo: false,
      submitted: false,
      configEvent: false,
      title: 'Configurações',
      items: [{
        text: 'Meu Evento',
        href: '/'
      },
      {
        text: 'Configurações',
        active: true
      }
      ]
    }
  },
  mounted () {
    let loader = this.$loading.show()
    this.GetSetting().then(() => {
      this.sa = this.setting.account.data
      loader.hide()
    })
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('event', ['setting'])
  },
  methods: {
    ...mapActions('event', {
      GetSetting: 'GetSetting',
      postSetting: 'SaveSetting',
      postSettingAccount: 'SaveSettingAccount'
    }),
    initSetting () {
      this.configEvent = true
    },
    cropBH () {
      // get image data for post processing, e.g. upload or setting image src
      this.cropBannerHome = this.$refs.cropperBH.getCroppedCanvas().toDataURL()
      this.$refs.cropperBH.getCroppedCanvas().toBlob((blob) => {
        this.setting.binaryBH = blob
      })
    },
    cropImage () {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas({ width: 200, height: 65 }).toDataURL()
      this.$refs.cropper.getCroppedCanvas({ width: 200, height: 65 }).toBlob((blob) => {
        this.setting.binarylogo = blob
      })
    },
    cancel (ref, clean) {
      this.$refs[`${ref}`].value = null
      if (clean === 1) {
        this.imgSrc = ''
      }
      if (clean === 3) {
        this.imgSrcBannerHome = ''
      }
    },
    setImage (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 1
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 1MB!')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
        this.$refs['modal-logo'].show()
        this.setData(200, 65, 1)
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    setData (w, h, type) {
      setTimeout(() => {
        if (type === 1) {
          this.$refs.cropper.setCropBoxData({ width: w, height: h })
        } else {
          this.$refs.cropperBH.setCropBoxData({ width: w, height: h })
        }
      }, 100)
    },
    setImageBH (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 2MB!')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrcBannerHome = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropperBH.replace(event.target.result)
        }
        reader.readAsDataURL(file)
        this.$refs['modal-banner-home'].show()
        this.setData(1160, 260, 2)
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    showFileChooser () {
      this.$refs.logo.click()
    },
    showBannerHChooser () {
      this.$refs.bannerhome.click()
    },
    async saveSetting () {
      let loader = this.$loading.show()
      try {
        const formData = new FormData()
        formData.append('id', this.setting.id)
        formData.append('name', this.setting.name)
        formData.append('description', this.setting.description)
        formData.append('contact_email', this.setting.contact_email)
        formData.append('phone', this.setting.phone)
        await this.postSetting(formData).then(() => {
          let message = 'Sucesso: Dados atualizados com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
        })
      } catch (err) {
        console.log(err)
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async saveSettingAccount () {
      let loader = this.$loading.show()
      try {
        this.sa.setting_id = this.setting.id
        await this.postSettingAccount(this.sa).then(() => {
          let message = 'Sucesso: Dados atualizados com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
        })
      } catch (err) {
        console.log(err)
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.link { cursor: pointer; }
.preview-lg {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.logo-preview {
  width:200px;
  overflow:hidden;
  height: 65px;
}
.actions {
  margin-top: 1rem;
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.bg-black { background-color: #afafaf; opacity: 1; }
.border-dashed {
  border: 2px dashed #ced4da !important;
  padding: 4px;
}
</style>
