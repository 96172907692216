<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="initRaffle()" autocomplete="off">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group actions">
                <label for="name">Imagem</label>
                <input
                  ref="photo"
                  type="file"
                  name="photo"
                  accept="image/*"
                  @change="setImage"
                />
                <div class="link " @click.prevent="showChooser()">
                  <div class="dropzone" v-if="!cropImg && !raffle.photo">
                    <div class="dropzone-custom-content text-center ">
                      <i class="display-4 text-muted bx bxs-cloud-upload mt-4"></i>
                      <h5 class="text-muted ">Selecionar imagem</h5>
                    </div>
                  </div>
                  <img :src="cropImg || (raffle.photo)" v-if="cropImg || raffle.photo" class="rounded  border-light" style="width:100%;height:100%">
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="type_raffle">Tipo</label>
                    <select
                        class="custom-select"
                        name="type_raffle"
                        id="type_raffle"
                        v-model="raffle.type_raffle"
                        :class="{
                          'is-invalid': submitted && $v.raffle.type_raffle.$error,
                        }">
                        <option value="1">Aberto</option>
                        <option value="2">Privado</option>
                      </select>
                    <div
                      v-if="submitted && !$v.raffle.type_raffle.required"
                      class="invalid-feedback"
                    >
                      O campo tipo é obrigatório.
                    </div>
                  </div>
                </div>
                <div class="col-sm-8">
                  <div class="form-group">
                    <label for="name">Nome</label>
                    <input
                      id="name"
                      v-model="raffle.name"
                      name="name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.raffle.name.$error
                      }"
                    />
                    <div
                      v-if="submitted && !$v.raffle.name.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="description">Descrição</label>
                    <textarea
                      class="form-control"
                      rows="3"
                      v-model="raffle.description"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="date_raffle">Data que acontecerá o  sorteio</label>
                    <date-picker
                      v-model="raffle.date_raffle"
                      type="datetime"
                      :first-day-of-week="1"
                      lang="pt-br"
                      format="DD/MM/YYYY HH:mm"
                      :class="{
                        'is-invalid': submitted && $v.raffle.date_raffle.$error,
                      }"
                    >
                    </date-picker>
                    <div
                      v-if="submitted && !$v.raffle.date_raffle.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <b-modal
      id="modal-center"
      ref="modal-photo"
      centered
      title="Foto"
      title-class="font-18"
      @hidden="cancel()"
      @ok="cropPhoto"
    >
      <div class="img-cropper border-dashed" v-show="imgSrc">
        <vue-cropper
          ref="cropper"
          :src="imgSrc"
          preview=".preview"
        />
      </div>
    </b-modal>
    <template v-slot:modal-footer>
      <b-button variant="danger" @click="deleteRaffle(raffle.id)" v-if="parseInt(raffle.status) === 0">Excluir</b-button>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="initRaffle()">Sortear</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pt-br'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import Swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading,
    DatePicker,
    VueCropper
  },
  data () {
    return {
      title: 'Novo Sorteio',
      loading: true,
      thumb: require('@/assets/images/image-default.png'),
      cropImg: '',
      imgSrc: '',
      fullPage: true,
      submitted: false,
      raffle: {
      },
      modal: false
    }
  },
  validations: {
    raffle: {
      type_raffle: { required },
      date_raffle: { required },
      name: { required }
    }
  },
  computed: {
    ...mapState('raffle', { data: 'raffle' })
  },
  methods: {
    ...mapActions('raffle', {
      postData: 'save',
      getRaffle: 'show',
      deleteItem: 'deleteRaffle'
    }),
    cropPhoto () {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.raffle.binaryphoto = blob
      })
    },
    cancel () {
      this.$refs.photo.value = null
      this.imgSrc = ''
    },
    setImage (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 4
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 4MB!')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
        this.$refs['modal-photo'].show()
        this.setData()
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    setData (type = 0) {
      setTimeout(() => {
        this.$refs.cropper.setCropBoxData({ width: 600, height: 600 })
      }, 100)
    },
    showChooser () {
      this.$refs.photo.click()
    },
    deleteRaffle (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem(id).then((res) => {
              successMessage('Sucesso: Sorteio excluído com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.close()
            this.$emit('created')
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    loadItem (id) {
      if (id) {
        this.title = 'Editar Sorteio'
        this.getRaffle(id).then((data) => {
          this.raffle = this.data
          this.raffle.date_raffle = this.$moment(this.raffle.date_raffle, 'YYYY-MM-DD HH:mm').toDate()
          if (this.raffle.dateend_raffle) {
            this.raffle.dateend_raffle = this.$moment(this.raffle.dateend_raffle, 'YYYY-MM-DD HH:mm').toDate()
          }
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    dentroy (id) {

    },
    async initRaffle () {
      this.submitted = true
      this.$v.raffle.$touch()
      if (this.$v.raffle.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      const formData = new FormData()
      if (this.raffle.id) {
        formData.append('id', this.raffle.id)
      }
      formData.append('name', this.raffle.name)
      formData.append('description', this.raffle.description ? this.raffle.description : '')
      formData.append('type_raffle', this.raffle.type_raffle)
      formData.append('date_raffle', this.$moment(this.raffle.date_raffle).format('YYYY-MM-DD HH:mm:ss'))

      if (this.raffle.binaryphoto) {
        formData.append('photo', this.raffle.binaryphoto, 'photo.jpg')
      }
      await this.postData(formData).then(res => {
        let message = 'Sucesso: Sorteio cadastrado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.submitted = false
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.submitted = false
      this.raffle = {}
      this.$v.$reset()
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
input[type="file"] {
  display: none;
}
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
