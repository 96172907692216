import http from '@/http'
import * as storage from '@/modules/auth/storage'

const URL = '/admin/users'
const URL_CHAT = 'https://chat.eventfy.live'

export const getMessages = async (params) => {
  const list = (await http.get(URL_CHAT + `/messages/${params.group_id}`, { params: params, headers: { 'Authorization': `Bearer ${storage.getLocalTokenChat()}` } }))
  return list
}

export const blockUserChat = async (id) => {
  return http.get(URL + `/${id}/blockChat`)
}

export const saveGroup = (payload) => {
  return http.post(URL_CHAT + `/groups/show`, payload, { headers: { 'Authorization': `Bearer ${storage.getLocalTokenChat()}` } })
}

export default {
  getMessages,
  blockUserChat,
  saveGroup
}
