<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveTerms()">
          <div class="card">
            <div class="card-body">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="terms">Termos e Condições de Uso</label>
                      <ckeditor
                        :editor="editor"
                        v-model="terms.terms"
                        :config="editorConfig"
                      >
                      </ckeditor>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="float-right">
                <button type="submit" class="btn btn-primary mr-1">
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import CKEditor from '@ckeditor/ckeditor5-vue'
import FullEditor from 'ckeditor5-build-full'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import service from './services'

export default {
  components: {
    Layout,
    ckeditor: CKEditor.component,
    PageHeader
  },
  name: 'terms-conditions',
  page: {
    title: 'Termos e Condições de Uso',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      editor: FullEditor,
      terms: {},
      editorConfig: {},
      submitted: false,
      title: 'Termos e Condições de Uso',
      items: [{
        text: 'Meu Evento',
        href: '/'
      },
      {
        text: 'Termos e Condições de Uso',
        active: true
      }
      ]
    }
  },
  mounted () {
    let loader = this.$loading.show()
    service.getTerms().then((data) => {
      this.terms = data.data
      loader.hide()
    })
  },
  computed: {
  },
  methods: {
    async saveTerms () {
      this.submitted = true
      let loader = this.$loading.show()
      await service.saveTerms(this.terms).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        loader.hide()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: block !important;
}
.emojis { max-width: 64px; overflow: hidden;}
.link { cursor: pointer; }
.preview-lg {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.logo-preview {
  width:200px;
  overflow:hidden;
  height: 65px;
}
.actions {
  margin-top: 1rem;
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.bg-black { background-color: #afafaf; opacity: 1; }
.border-dashed {
  border: 2px dashed #ced4da !important;
  padding: 4px;
}
</style>
