<template>
  <b-modal v-model="modal" :title="title" size="xl" :no-enforce-focus="true" :no-close-on-backdrop="true" scrollable>
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="col-lg-12">
      <p>Detalhamento do usuário <b>{{ this.user.name }}</b></p>
      <div class="table-responsive mt-3 mb-0">
        <table class="table table-centered table-nowrap table-hover">
          <thead>
            <tr>
              <th width="55%">Objetivo</th>
              <th>Pontos</th>
              <th>Horário</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(g, i) in gamification" :key="i">
              <td>
                {{ g.setting.data.gamification.data.description }}<br />
                <span class="text-muted font-size-11" style="word-break: break-all;">{{ g.details }}</span>
              </td>
              <td width="7%" class="text-center">
                <b-badge variant="success" class="font-size-13 bg-gradient p-1" style="min-width:30px">{{ g.points }}</b-badge>
              </td>
              <td width="8%">{{ $moment(g.created_at).format('DD/MM/YYYY HH:mm') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <template v-slot:modal-footer>
      <span class="float-left" style="justify-content: left;">
        TOTAL DE PONTOS:  <b-badge variant="primary" class="font-size-15 p-2" style="min-width:30px">{{ totalRanking }} pts</b-badge>
      </span>
      <b-button variant="secondary" @click="close()">Fechar</b-button>
    </template>
  </b-modal>
</template>
<script>
import serviceReport from '@/pages/reports/services'
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'gamification-details',
  components: {
    Loading
  },
  props: {},
  data () {
    return {
      loading: false,
      title: '',
      gamification: [],
      user: {},
      modal: false
    }
  },
  methods: {
    loadItem (id) {
      if (id) {
        console.log(id)
        serviceReport.getGamificationDetail(id).then((data) => {
          this.gamification = data.data
          this.user = data.user.data
          this.title = `Jornada - ${this.user.name}`
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    close () {
      this.modal = false
      this.gamification = {}
    }
  },
  computed: {
    totalRanking () {
      if (this.gamification.length) {
        return this.gamification.reduce((total, g) => total + g.points, 0)
      }
      return 0
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
