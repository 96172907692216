<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="applyFilter()" autocomplete="off">
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label for="description">Descrição</label>
                <input type="text" name="description" model="filter.description" class="form-control">
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="status">Status</label>
                <select
                  class="custom-select"
                  name="status"
                  v-model="filter.status"
                >
                  <option value="">Todos</option>
                  <option value="0">Inativo</option>
                  <option value="1">Ativo</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Fechar</b-button>
      <b-button class="btn btn-dark" @click="clean()">Limpar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="applyFilter()">Aplicar</button>
    </template>
  </b-modal>
</template>
<script>

export default {
  name: 'archive-filter',
  components: {
  },
  props: {},
  data () {
    return {
      title: 'Filtro - Arquivos',
      roomSelected: '',
      filter: {},
      modal: false
    }
  },
  methods: {
    open () {
      this.modal = true
    },
    applyFilter () {
      this.$emit('archiveFiltered', this.filter)
      this.close()
    },
    close () {
      this.modal = false
    },
    clean () {
      this.filter = {}
    }
  },
  computed: {
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
