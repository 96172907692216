<template>
  <div style="margin-left:-15px">
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="text-sm-right">
          <button type="button" class="btn btn-success btn-sm btn-rounded" @click="newActivitie()">
            <i class="mdi mdi-plus"></i> Adicionar Atividade
          </button>
        </div>
      </div>
      <!-- end col-->
    </div>
    <div class="row">
      <div class="col-md-12 text-center" v-show="activities.length === 0">
        <h5 class="text-truncate font-size-14 p-4 mt-2">Nenhuma atividade cadastrada. </h5>
        <br />
      </div>
      <div class="table-responsive mt-3 mb-0" v-show="activities.length > 0">
        <table class="table table-centered table-nowrap table-hover">
          <thead>
            <tr>
              <th width="20%">Nome</th>
              <th width="20%">Localização</th>
              <th width="20%">Local</th>
              <th width="10%">Data</th>
              <th width="10%">Inscrições</th>
              <th>Ativo</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(s, i) in activities" :key="i" class="link">
              <td @click="showActivitie(s.id)">{{ s.name }}</td>
              <td @click="showActivitie(s.id)">{{ s.local }}</td>
              <td @click="showActivitie(s.id)">{{ s.local_activitie }}</td>
              <td @click="showActivitie(s.id)">{{ s.date_hour }}</td>
              <td @click="showActivitie(s.id)">{{ s.total_participants }}</td>
              <td>
                <b-form-checkbox v-model="s.status" switch @change="toggleStatus(s.id)"></b-form-checkbox>
              </td>
              <td>
                <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal font-size-18"></i>
                  </template>
                  <b-dropdown-item @click="showActivitie(s.id)">
                    <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="exportSubscribers(s.id)">
                    <i class="fas fa-file-download text-success mr-1"></i> Exportar Inscritos
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteActivitie(s.id)">
                    <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-4" v-show="activities.length > 0">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
          </ul>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-center"
      ref="modal-activitie"
      v-model="modal"
      :title="activitie.id ? 'Editar Atividade' : 'Cadastrar Atividade'"
      title-class="font-18"
      size="lg"
      :no-close-on-backdrop="true"
    >
      <form @submit.prevent="createActivitie()">
        <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="name">Descrição</label>
                <input
                  id="name"
                  v-model="activitie.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.activitie.name.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.activitie.name.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="local">Localização</label>
                <input
                  id="local"
                  v-model="activitie.local"
                  name="local"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.activitie.local.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.activitie.local.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="local_activitie">Local da atividade</label>
                <input
                  id="local_activitie"
                  v-model="activitie.local_activitie"
                  name="local_activitie"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.activitie.local_activitie.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.activitie.local_activitie.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="date_hour">Data e Horário</label>
                <input
                  id="date_hour"
                  v-model="activitie.date_hour"
                  name="date_hour"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.activitie.date_hour.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.activitie.date_hour.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="obs">Observações</label>
                <textarea
                  id="obs"
                  v-model="activitie.obs"
                  name="obs"
                  class="form-control"
                />
              </div>
            </div>
          </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeModal()">Cancelar</b-button>
        <b-button variant="primary" @click="createActivitie()">Salvar</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import 'cropperjs/dist/cropper.css'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import service from './services'

export default {
  components: {
  },
  name: 'activities',
  data () {
    return {
      submitted: false,
      params: {},
      modal: false,
      activitie: {
        status: false
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20
    }
  },
  validations: {
    activitie: {
      name: { required },
      local: { required },
      local_activitie: { required },
      date_hour: { required }
    }
  },
  beforeMount () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  computed: {
    ...mapState('activities', ['activities', 'pagination']),
    ...mapState('activities', { data: 'activitie' }),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('activities', {
      fetchData: 'getList',
      fetchItem: 'show',
      postData: 'save',
      changeStatus: 'changeStatus',
      deleteItem: 'deleteActivitie'
    }),
    cleanActivitie () {
      this.activitie = {}
    },
    newActivitie () {
      this.$refs['modal-activitie'].show()
    },
    showActivitie (id) {
      this.fetchItem({ id }).then((res) => {
        this.$refs['modal-activitie'].show()
        this.activitie = this.data
      })
    },
    closeModal () {
      this.$refs['modal-activitie'].hide()
      this.cleanActivitie()
      this.$v.$reset()
    },
    deleteActivitie (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem({ id }).then((res) => {
              successMessage('Sucesso: Atividade excluída com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    exportSubscribers (id) {
      let loeader = this.$loading.show()
      service.exportParticipants({ id, include: 'user' }).then((res) => {
        this.download(res)
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    async toggleStatus (id, status = '') {
      try {
        await this.changeStatus({ id, status })
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async createActivitie () {
      this.submitted = true
      this.$v.activitie.$touch()
      if (this.$v.activitie.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      await this.postData(this.activitie).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.closeModal()
        this.updateList()
        loader.hide()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    },
    updateList () {
      this.fetchData({ limit: this.perPage, page: this.currentPage })
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.closeModal()
      }
    }
  }
}
</script>

<style scoped>
.activitie-activitied { color: #909090; background: #fff0f0}
.mt-15 { margin-top:15px }
</style>
