<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveInput()" autocomplete="off">
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label for="type_input">Tipo de campo</label>
                <select
                  id="type_input"
                  v-model="form.type_input"
                  name="type_input"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.form.type_input.$error
                  }"
                >
                  <option value="cpf">CPF</option>
                  <option value="cnpj">CNPJ</option>
                  <option value="phone">Telefone</option>
                  <option value="number">Númerico</option>
                  <option value="text">Texto</option>
                  <option value="checkbox">Caixa de seleção</option>
                  <option value="select">Combobox</option>
                  <option value="email">E-mail</option>
                  <option value="cep">CEP</option>
                  <option value="ufs">UFs</option>
                  <option value="radio">Radio</option>
                  <option value="textarea">Multi Texto</option>
                </select>
                <div
                  v-if="submitted && !$v.form.type_input.required"
                  class="invalid-feedback"
                >
                O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="name">Nome do campo</label>
                <input
                  id="name"
                  v-model="form.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.form.name.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.form.name.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="required">Obrigatório</label>
                <select
                  id="required"
                  v-model="form.required"
                  name="required"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.form.required.$error
                  }"
                >
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </select>
                <div
                  v-if="submitted && !$v.form.required.required"
                  class="invalid-feedback"
                >
                O campo é obrigatório.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label for="label">Título do campo</label>
                <input
                  id="label"
                  v-model="form.label"
                  name="label"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.form.label.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.form.label.required"
                  class="invalid-feedback"
                >
                O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="placeholder">Título secundário</label>
                <input
                  id="placeholder"
                  v-model="form.placeholder"
                  name="placeholder"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="row" v-if="form.type_input === 'select' || form.type_input === 'radio'">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="options">Opções</label>
                <textarea
                  id="options"
                  v-model="form.options"
                  name="options"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.form.options.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.form.options.required"
                  class="invalid-feedback"
                >
                O campo é obrigatório.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveInput()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading
  },
  props: {
    idInput: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo campo',
      loading: true,
      fullPage: true,
      submitted: false,
      form: {},
      modal: false
    }
  },
  validations () {
    const formDefault = {
      name: { required },
      type_input: { required },
      required: { required },
      label: { required }
    }
    const formFull = {
      name: { required },
      type_input: { required },
      required: { required },
      options: { required },
      label: { required }
    }

    if (this.form.type_input === 'select' || this.form.type_input === 'radio') {
      let form = formFull
      return {
        form: form
      }
    }
    return { form: formDefault }
  },
  methods: {
    ...mapActions('form', {
      getInput: 'show',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.title = 'Editar Campo'
        this.getInput(id).then((data) => {
          this.form = this.data
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveInput () {
      this.submitted = true
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.form).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.form = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('form', { data: 'form' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
