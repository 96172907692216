<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveProduct()" autocomplete="off">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group actions">
                <label for="name">Imagem</label>
                <input
                  ref="photo"
                  type="file"
                  name="photo"
                  accept="image/*"
                  @change="setImage"
                />
                <div class="link " @click.prevent="showChooser()">
                  <div class="dropzone" v-if="!cropImg && !product.photo">
                    <div class="dropzone-custom-content text-center ">
                      <i class="display-4 text-muted bx bxs-cloud-upload mt-4"></i>
                      <h5 class="text-muted ">Selecionar imagem</h5>
                    </div>
                  </div>
                  <img :src="cropImg || (product.photo)" v-if="cropImg || product.photo" class="rounded  border-light" style="width:100%;height:100%">
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="product_method">Categoria</label>
                    <select
                      class="custom-select"
                      name="type"
                      v-model="product.category_id"
                    >
                      <option value=""></option>
                      <option v-for="(g,idx) in categories" :key="idx" :value="g.id">{{ g.name }}</option>
                    </select>
                    <div
                      v-if="submitted && !$v.product.category_id.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="name">Nome</label>
                    <input
                      id="name"
                      v-model="product.name"
                      name="name"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.product.name.$error }"
                    />
                    <div
                      v-if="submitted && !$v.product.name.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="description">Descrição</label>
                    <textarea
                      class="form-control"
                      rows="3"
                      v-model="product.description"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="points_cost">Pontos (Custo)</label>
                    <input
                      id="points_cost"
                      v-model="product.points_cost"
                      name="points_cost"
                      type="number"
                      class="form-control text-right"
                      :class="{ 'is-invalid': submitted && $v.product.points_cost.$error }"
                    />
                    <div
                      v-if="submitted && !$v.product.points_cost.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="stock_quantity">Quantidade disponível</label>
                    <input
                      id="stock_quantity"
                      v-model="product.stock_quantity"
                      name="stock_quantity"
                      type="number"
                      class="form-control text-right"
                      :class="{ 'is-invalid': submitted && $v.product.stock_quantity.$error }"
                    />
                    <div
                      v-if="submitted && !$v.product.stock_quantity.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <b-modal
      id="modal-center"
      ref="modal-photo"
      centered
      title="Foto"
      title-class="font-18"
      @hidden="cancel()"
      @ok="cropPhoto"
    >
      <div class="img-cropper border-dashed" v-show="imgSrc">
        <vue-cropper
          ref="cropper"
          :src="imgSrc"
          preview=".preview"
        />
      </div>
    </b-modal>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveProduct()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import service from './services'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Loading,
    VueCropper
  },
  data () {
    return {
      title: 'Novo Produto',
      thumb: require('@/assets/images/image-default.png'),
      loading: true,
      cropImg: '',
      imgSrc: '',
      fullPage: true,
      submitted: false,
      modal: false,
      categories: [],
      selected: '',
      product: {
        status: false
      }
    }
  },
  validations () {
    return {
      product: {
        name: { required },
        stock_quantity: { required },
        points_cost: { required },
        category_id: { required }
      }
    }
  },
  computed: {
    ...mapState('product', { data: 'product' })
  },
  methods: {
    ...mapActions('product', {
      fetchItem: 'show',
      postData: 'save'
    }),
    getCategories () {
      service.getCategories().then(({ data }) => {
        this.categories = data.data
      })
    },
    cropPhoto () {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.product.binaryphoto = blob
      })
    },
    cancel () {
      this.$refs.photo.value = null
      this.imgSrc = ''
    },
    setImage (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 2MB!')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
        this.$refs['modal-photo'].show()
        this.setData()
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    setData (type = 0) {
      setTimeout(() => {
        this.$refs.cropper.setCropBoxData({ width: 400, height: 400 })
      }, 100)
    },
    showChooser () {
      this.$refs.photo.click()
    },
    loadItem (id) {
      if (id) {
        this.title = 'Editar Produto'
        this.loading = true
        this.fetchItem(id).then((data) => {
          this.product = this.data
          this.selected = this.data.category_id
        }).then(() => {
          this.loading = false
        })
      }
    },
    setCategory (category) {
      if (category) {
        this.product.category_id = category.id
        return
      }
      this.product.category_id = ''
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      this.getCategories()
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveProduct () {
      this.submitted = true
      this.$v.product.$touch()
      if (this.$v.product.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      const formData = new FormData()
      if (this.product.id) {
        formData.append('id', this.product.id)
      }
      formData.append('name', this.product.name)
      formData.append('description', this.product.description)
      formData.append('category_id', this.product.category_id)
      formData.append('points_cost', this.product.points_cost)
      formData.append('stock_quantity', this.product.stock_quantity)

      if (this.product.binaryphoto) {
        formData.append('photo', this.product.binaryphoto, 'photo.jpg')
      }
      await this.postData(formData).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
        this.loading = false
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.product = {}
      this.$v.$reset()
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
input[type="file"] {
  display: none;
}
.product-archived { color: #909090; background: #fff0f0}
.mt-15 { margin-top:15px }
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
