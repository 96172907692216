<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="!setting.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden text-center mt-4">
                <h5 class="text-truncate font-size-15 ">Olá, {{ user.name }}.</h5>
                <p class="text-muted">Indentificamos que a sua plataforma ainda não está configurada.</p>
                <router-link :to="{ name: 'event' }">
                  <b-button variant="primary" size="lg" class="mt-4 mb-4" >
                    <i class="bx bx-cog font-size-16 align-middle mr-2"></i> CONFIGURAR AGORA!
                  </b-button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="setting.id || configEvent">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs no-pills content-class="p-3 text-muted">
              <hr />
              <b-tab active>
                <template v-slot:title>
                  <div class="card-radio">
                    <div>
                      <i
                        class="bx bx-chat font-size-18 text-dark align-middle mr-2"
                      ></i>
                      <span>Chat</span>
                    </div>
                  </div>
                </template>
                <form @submit.prevent="saveIntegration({ chat_token: integrations.chat_token, id: setting.id })">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="whatsapp">Token Key</label>
                        <input
                          id="chat_token"
                          v-model="integrations.chat_token"
                          name="name"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mt-4">
                        <button type="submit" class="btn btn-primary mr-1">
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <div class="card-radio">
                    <div>
                      <i
                        class="bx bx-cog font-size-18 text-dark align-middle mr-2"
                      ></i>
                      <span>Pusher</span>
                    </div>
                  </div>
                </template>
                <form @submit.prevent="saveIntegration({ pusher_key: integrations.pusher_key, pusher_cluster: integrations.pusher_cluster, id: setting.id })">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="whatsapp">Key</label>
                        <input
                          id="whatsapp"
                          v-model="integrations.pusher_key"
                          name="name"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="pusher_cluster">Cluster</label>
                        <select
                          class="custom-select"
                          name="pusher_cluster"
                          id="pusher_cluster"
                          v-model="integrations.pusher_cluster"
                        >
                          <option value="us2">us2</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mt-4">
                        <button type="submit" class="btn btn-primary mr-1">
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <div class="card-radio">
                    <div>
                      <i
                        class="bx bx-cog font-size-18 text-dark align-middle mr-2"
                      ></i>
                      <span>OneSignal</span>
                    </div>
                  </div>
                </template>
                <form @submit.prevent="saveIntegration({ onesignal_key_id: integrations.onesignal_key_id, onesignal_key_api: integrations.onesignal_key_api, id: setting.id })">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="onesignal_key_id">App ID</label>
                        <input
                          id="google_analytic"
                          v-model="integrations.onesignal_key_id"
                          name="name"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="onesignal_key_api">Key API</label>
                        <input
                          id="onesignal_key_api"
                          v-model="integrations.onesignal_key_api"
                          name="name"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <button type="submit" class="btn btn-primary mr-1">
                        Salvar
                      </button>
                    </div>
                  </div>
                </form>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <div class="card-radio">
                    <div>
                      <i
                        class="bx bx-cog font-size-18 text-default align-middle mr-2"
                      ></i>
                      <span>App - Notificação</span>
                    </div>
                  </div>
                </template>
                <form @submit.prevent="addNotificationToList({ title: titlePusher, message })">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="titlePusher">Título</label>
                        <input
                          id="titlePusher"
                          v-model="titlePusher"
                          name="name"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="message">Mensagem</label>
                        <input
                          id="message"
                          v-model="message"
                          name="name"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mt-4">
                        <button type="submit" class="btn btn-primary">
                          Adicionar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="">
                  <NotificationList ref="notificationlist" />
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import NotificationList from './NotificationList.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    NotificationList,
    PageHeader
  },
  name: 'Integrações',
  page: {
    title: 'Integrações',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      configEvent: false,
      integrations: {},
      totalRows: 1,
      perPage: 20,
      currentPage: 1,
      statusLabel: ['Aguardando', 'Enviado'],
      titlePusher: '',
      message: '',
      title: 'Integrações',
      items: [{
        text: 'Meu Evento',
        href: '/'
      },
      {
        text: 'Integrações',
        active: true
      }
      ]
    }
  },
  mounted () {
    let loader = this.$loading.show()
    this.GetSetting().then(() => {
      this.integrations = this.setting.integrations.data
      loader.hide()
    })
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('event', ['setting'])
  },
  methods: {
    ...mapActions('event', {
      GetSetting: 'GetSetting',
      postIntegration: 'SaveSettingIntegration',
      postNotification: 'createNotification'
    }),
    initSetting () {
      this.configEvent = true
    },
    async addNotificationToList (form) {
      if (!form.title || !form.message) {
        errorMessage('Alerta: Preencha todos os campos')
        return
      }
      let loader = this.$loading.show()
      try {
        await this.postNotification(form).then(() => {
          let message = 'Sucesso: Mensagem enviada.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
          this.titlePusher = ''
          this.message = ''
          this.$refs.notificationlist.$emit('createdNotification')
        })
      } catch (err) {
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async saveIntegration (form) {
      let loader = this.$loading.show()
      try {
        await this.postIntegration(form).then(() => {
          let message = 'Sucesso: Dados atualizados com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
        })
      } catch (err) {
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    }
  }
}
</script>

<style>
hr { margin-top:0 }
.nav-tabs { border: none !important}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link { border: none !important}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus { border: none !important}
</style>
