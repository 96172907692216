<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveVideo()" autocomplete="off">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="title">Título</label>
                <input
                  id="title"
                  v-model="video.title"
                  name="title"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.video.title.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.video.title.required"
                  class="invalid-feedback"
                >
                  O campo título é obrigatório.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="url_video">URL Vídeo</label>
                <input
                  id="url_video"
                  v-model="video.url_video"
                  name="url_video"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.video.url_video.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.video.url_video.required"
                  class="invalid-feedback"
                >
                  O campo url é obrigatório.
                </div>
                <div
                  v-if="submitted && !$v.video.url_video.url"
                  class="invalid-feedback"
                >
                O campo url vídeo deve ser uma url válida
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="url_image">URL Imagem (Vimeo)</label>
                <input
                  id="url_image"
                  v-model="video.url_image"
                  name="url_image"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.video.url_image.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.video.url_image.url"
                  class="invalid-feedback"
                >
                O campo url imagem deve ser uma url válida
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="description">Descrição</label>
                <textarea
                  id="description"
                  v-model="video.description"
                  name="description"
                  rows="3"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveVideo()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required, url } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading
  },
  props: {
    idVideo: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Vídeo',
      loading: true,
      fullPage: true,
      submitted: false,
      video: {},
      modal: false
    }
  },
  validations: {
    video: {
      title: { required },
      url_video: { required, url },
      url_image: { url }
    }
  },
  methods: {
    ...mapActions('video', {
      getVideo: 'show',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.title = 'Editar Vídeo'
        this.getVideo(id).then((data) => {
          this.video = this.data
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveVideo () {
      this.submitted = true
      this.$v.video.$touch()
      if (this.$v.video.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.video).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.video = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('video', { data: 'video' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
