<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="savePartner()">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Informações do Parceiro</h4>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group row mb-4 actions">
                    <label for="name" class="col-form-label col-lg-3">Logo</label>
                    <div class="col-lg-9">
                      <input
                        ref="logo"
                        type="file"
                        name="logo"
                        accept="image/*"
                        @change="setImage"
                      />
                      <div class="link " @click.prevent="showChooser(0)">
                        <div class="dropzone" v-if="!cropImg && !partner.logo">
                          <div class="dropzone-custom-content text-center ">
                            <i class="display-4 text-muted bx bxs-cloud-upload mt-4"></i>
                            <h5 class="text-muted ">Selecionar imagem</h5>
                          </div>
                        </div>
                        <img :src="cropImg || (partner.logo)" v-if="cropImg || partner.logo" class="rounded  border-light" style="width:50%;height:20%">
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-4 actions">
                    <label for="name" class="col-form-label col-lg-3">Banner</label>
                    <div class="col-lg-9">
                      <input
                        ref="banner"
                        type="file"
                        name="banner"
                        accept="image/*"
                        @change="setBanner"
                      />
                      <div class="link " @click.prevent="showChooser(1)">
                        <div class="dropzone" v-if="!cropBanner && !partner.banner">
                          <div class="dropzone-custom-content text-center ">
                            <i class="display-4 text-muted bx bxs-cloud-upload mt-4"></i>
                            <h5 class="text-muted ">Selecionar imagem</h5>
                          </div>
                        </div>
                        <img :src="cropBanner || (partner.banner)" v-if="cropBanner || partner.banner" class="rounded thumb border-light">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-sm-9">
                      <div class="form-group">
                        <label for="name">Nome</label>
                        <input
                          id="name"
                          v-model="partner.name"
                          name="name"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid': submitted && $v.partner.name.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.partner.name.required"
                          class="invalid-feedback"
                        >
                          O campo nome é obrigatório
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="partner_category_id">Categoria</label>
                        <multiselect
                          v-model="selected"
                          :options="categories" class="helo"
                          @input="setCategory"
                          :max-height="180"
                          trackBy="id"
                          label="name"
                          placeholder="Selecione uma opção"
                        >
                        </multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="website">Website</label>
                        <input
                          id="website"
                          v-model="partner.website"
                          name="website"
                          type="text"
                          class="form-control"
                          placeholder="URL do Website"
                          :class="{
                            'is-invalid': submitted && $v.partner.website.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.partner.website.url"
                          class="invalid-feedback"
                        >
                        informe uma url válida.
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="Facebook">Facebook</label>
                        <input
                          id="facebook"
                          v-model="partner.facebook"
                          name="facebook"
                          type="text"
                          class="form-control"
                          placeholder="URL do facebook"
                          :class="{
                            'is-invalid': submitted && $v.partner.facebook.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.partner.facebook.url"
                          class="invalid-feedback"
                        >
                        informe uma url válida.
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="instagram">Instagram</label>
                        <input
                          id="instagram"
                          v-model="partner.instagram"
                          name="instagram"
                          type="text"
                          class="form-control"
                          placeholder="URL do instagram"
                          :class="{
                            'is-invalid': submitted && $v.partner.instagram.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.partner.instagram.url"
                          class="invalid-feedback"
                        >
                        informe uma url válida.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="twitter">Twitter</label>
                        <input
                          id="twitter"
                          v-model="partner.twitter"
                          name="twitter"
                          type="text"
                          class="form-control"
                          placeholder="URL do twitter"
                          :class="{
                            'is-invalid': submitted && $v.partner.twitter.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.partner.twitter.url"
                          class="invalid-feedback"
                        >
                        informe uma url válida.
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="youtube">Youtube</label>
                        <input
                          id="youtube"
                          v-model="partner.youtube"
                          name="youtube"
                          type="text"
                          class="form-control"
                          placeholder="URL do youtube"
                          :class="{
                            'is-invalid': submitted && $v.partner.youtube.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.partner.youtube.url"
                          class="invalid-feedback"
                        >
                        informe uma url válida.
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="linkedin">linkedin</label>
                        <input
                          id="linkedin"
                          v-model="partner.linkedin"
                          name="linkedin"
                          type="text"
                          class="form-control"
                          placeholder="URL do linkedin"
                          :class="{
                            'is-invalid': submitted && $v.partner.linkedin.$error,
                          }"
                        />
                        <div
                          v-if="submitted && !$v.partner.linkedin.required"
                          class="invalid-feedback"
                        >
                        informe uma url válida.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="title">Descrição</label>
                    <ckeditor
                      :editor="editor"
                      v-model="partner.about"
                      :config="editorConfig"
                    >
                    </ckeditor>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="float-right">
                <button type="buton" @click="cancelar()" class="btn btn-dark mr-4">
                  Cancelar
                </button>
                <button type="submit" class="btn btn-primary mr-1">
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <b-modal
      id="modal-center"
      ref="modal-thumb"
      centered
      title="Logo - Parceiro"
      title-class="font-18"
      @hidden="cancel('logo', 1)"
      @ok="cropImage"
    >
      <div class="img-cropper border-dashed" v-show="imgSrc">
        <vue-cropper
          ref="cropper"
          :src="imgSrc"
          preview=".preview"
        />
      </div>
    </b-modal>
    <b-modal
      id="modal-center"
      ref="modal-banner"
      centered
      size="lg"
      title="Banner - Parceiro"
      title-class="font-18"
      @hidden="cancel('banner', 1)"
      @ok="cropImageB"
    >
      <div class="img-cropper border-dashed" v-show="imgBanner">
        <vue-cropper
          ref="cropperB"
          :src="imgBanner"
          preview=".preview"
        />
      </div>
    </b-modal>
  </Layout>
</template>
<script>
import 'vue2-datepicker/locale/pt-br'
import CKEditor from '@ckeditor/ckeditor5-vue'
import FullEditor from 'ckeditor5-build-full'
import Multiselect from 'vue-multiselect'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required, url } from 'vuelidate/lib/validators'
import service from './services'

export default {
  components: {
    ckeditor: CKEditor.component,
    VueCropper,
    Layout,
    PageHeader,
    Multiselect
  },
  name: 'Parceiro',
  page: {
    title: 'Parceiro',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      categories: [],
      selected: '',
      partner: {
        id: '',
        name: '',
        facebook: '',
        website: '',
        instagram: '',
        twitter: '',
        linkedin: '',
        youtube: '',
        about: '',
        logo: ''
      },
      editor: FullEditor,
      editorConfig: {},
      thumb: require('@/assets/images/image-default.png'),
      imgSrc: '',
      imgBanner: '',
      cropBanner: '',
      cropImg: '',
      submitted: false,
      title: 'Parceiro',
      items: [{
        text: 'Parceiros',
        href: '/partners'
      },
      {
        text: 'Novo Parceiro',
        active: true
      }
      ]
    }
  },
  validations: {
    partner: {
      name: { required },
      facebook: { url },
      website: { url },
      instagram: { url },
      twitter: { url },
      youtube: { url },
      linkedin: { url }
    }
  },
  mounted () {
    this.getCategories()
    this.loadItem()
  },
  computed: {
    ...mapState('partner', { data: 'partner' })
  },
  methods: {
    ...mapActions('partner', {
      fetch: 'show',
      postData: 'save'
    }),
    cancelar () {
      this.$router.push({ name: 'partners' })
    },
    getCategories () {
      service.getCategories().then(({ data }) => {
        this.categories = data
      })
    },
    setCategory (category) {
      if (category) {
        this.partner.partner_category_id = category.id
        return
      }
      this.partner.partner_category_id = ''
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.items[1].text = 'Editar Parceiro'
        this.fetch(this.$route.params.id)
          .then(() => {
            this.partner = this.data
            this.selected = this.categories.filter(o => o.id === parseInt(this.partner.partner_category_id))[0]
          }).then(() => {
            loader.hide()
          })
      }
      let loader = this.$loading.show()
      loader.hide()
    },
    cropImage () {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.partner.binarylogo = blob
      })
    },
    cropImageB () {
      // get image data for post processing, e.g. upload or setting image src
      this.cropBanner = this.$refs.cropperB.getCroppedCanvas().toDataURL()
      this.$refs.cropperB.getCroppedCanvas().toBlob((blob) => {
        this.partner.binarybanner = blob
      })
    },
    cancel (ref) {
      this.$refs[`${ref}`].value = null
      this.imgSrc = ''
      this.imgBanner = ''
    },
    setImage (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 1
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 1MB!')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
        this.$refs['modal-thumb'].show()
        this.setData()
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    setBanner (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 2MB!')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgBanner = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropperB.replace(event.target.result)
        }
        reader.readAsDataURL(file)
        this.$refs['modal-banner'].show()
        this.setData(1)
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    setData (type = 0) {
      if (type) {
        setTimeout(() => {
          this.$refs.cropperB.setCropBoxData({ width: 900, height: 240 })
        }, 100)
        return
      }
      setTimeout(() => {
        this.$refs.cropper.setCropBoxData({ width: 600, height: 300 })
      }, 100)
    },
    showChooser (type = 0) {
      if (type) {
        this.$refs.banner.click()
        return
      }
      this.$refs.logo.click()
    },
    async savePartner () {
      this.submitted = true
      this.$v.partner.$touch()
      if (this.$v.partner.$invalid) {
        console.log('teste', this.$v.partner)
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      const formData = new FormData()
      formData.append('id', this.partner.id)
      if (this.partner.partner_category_id) {
        formData.append('partner_category_id', this.partner.partner_category_id)
      }
      formData.append('name', this.partner.name)
      formData.append('facebook', this.partner.facebook)
      formData.append('instagram', this.partner.instagram)
      formData.append('twitter', this.partner.twitter)
      formData.append('website', this.partner.website)
      formData.append('linkedin', this.partner.linkedin)
      formData.append('youtube', this.partner.youtube)
      formData.append('about', this.partner.about)

      if (this.partner.binarylogo) {
        formData.append('logo', this.partner.binarylogo, 'logo.jpg')
      }

      if (this.partner.binarybanner) {
        formData.append('banner', this.partner.binarybanner, 'banner.jpg')
      }

      await this.postData(formData).then(res => {
        let message = 'Sucesso: Dados atualizados com sucesso.'
        let delay = 2500
        loader.hide()
        successMessage(message, delay)
        this.cancelar()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.link { cursor: pointer; }
.thumb {
  max-width: 100%;
  max-height: 112px;
  min-height: 112px;
}
.dropzone { max-width: 400px; padding: 0px !important; max-height: 170px; overflow: hidden;}
.border-light {
  padding: 4px;
  display: flex;
  background: #f1f1f1;
  border-radius: 0.25rem;
}
.preview-lg {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.logo-preview {
  width:200px;
  overflow:hidden;
  height: 112px;
}
.actions {
  margin-top: 1rem;
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.border-dashed {
  border: 2px dashed #ced4da !important;
}
</style>
