<template>
  <div style="margin-left:-15px">
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="text-sm-right">
          <button type="button" class="btn btn-success btn-sm btn-rounded" @click="newTransfer()">
            <i class="mdi mdi-plus"></i> Adicionar Transfer
          </button>
        </div>
      </div>
      <!-- end col-->
    </div>
    <div class="row">
      <div class="col-md-12 text-center" v-show="transfers.length === 0">
        <h5 class="text-truncate font-size-14 p-4 mt-2">Nenhum transfer cadastrado. </h5>
        <i class="bx bx-tired bx-lg"></i>
        <br />
      </div>
      <div class="table-responsive mt-3 mb-0" v-show="transfers.length > 0">
        <table class="table table-centered table-nowrap table-hover">
          <thead>
            <tr>
              <th width="20%">Nome</th>
              <th width="20%">Local</th>
              <th width="20%">Data e Hora</th>
              <th width="20%">Ponto de encontro</th>
              <th>Tipo</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(s, i) in transfers" :key="i" class="link">
              <td @click="showTransfer(s.id)">{{ s.name }}</td>
              <td @click="showTransfer(s.id)">{{ s.local }}</td>
              <td @click="showTransfer(s.id)">{{ s.date_hour }}</td>
              <td @click="showTransfer(s.id)">{{ s.meeting_point }}</td>
              <td @click="showTransfer(s.id)">{{ s.type_transfer_l }}</td>
              <td>
                <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal font-size-18"></i>
                  </template>
                  <b-dropdown-item @click="showTransfer(s.id)">
                    <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteTransfer(s.id)">
                    <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-4" v-show="transfers.length > 0">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
          </ul>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-center"
      v-model="modal"
      ref="modal-transfer"
      :title="transfer.id ? 'Editar Transfer' : 'Cadastrar Transfer'"
      title-class="font-18"
      size="lg"
      :no-close-on-backdrop="true"
    >
      <form @submit.prevent="createTransfer()">
        <div class="row mt-2">
          <div class="col-sm-2">
            <div class="form-group">
              <label for="type_transfer">Tipo</label>
              <select
                id="type_transfer"
                v-model="transfer.type_transfer"
                name="type_transfer"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.transfer.type_transfer.$error }"
              >
                <option value="1">Ida</option>
                <option value="2">Volta</option>
              </select>
              <div
                v-if="submitted && !$v.transfer.type_transfer.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <div class="form-group">
              <label for="description">Descrição</label>
              <input
                id="description"
                v-model="transfer.name"
                name="description"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.transfer.name.$error }"
              />
              <div
                v-if="submitted && !$v.transfer.name.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <div class="form-group">
              <label for="local">Local</label>
              <input
                id="local"
                v-model="transfer.local"
                name="local"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.transfer.local.$error }"
              />
              <div
                v-if="submitted && !$v.transfer.local.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="date_hour">Data e Horários</label>
              <input
                id="date_hour"
                v-model="transfer.date_hour"
                name="date_hour"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.transfer.date_hour.$error }"
              />
              <div
                v-if="submitted && !$v.transfer.date_hour.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="meeting_point">Ponto de encontro</label>
              <input
                id="meeting_point"
                v-model="transfer.meeting_point"
                name="meeting_point"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.transfer.meeting_point.$error }"
              />
              <div
                v-if="submitted && !$v.transfer.meeting_point.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="obs">Observações</label>
              <textarea
                id="obs"
                v-model="transfer.obs"
                name="obs"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeModal()">Cancelar</b-button>
        <b-button variant="primary" @click="createTransfer()">Salvar</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import 'cropperjs/dist/cropper.css'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
  },
  name: 'transfers',
  data () {
    return {
      submitted: false,
      params: {},
      modal: false,
      transfer: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 20
    }
  },
  validations: {
    transfer: {
      name: { required },
      local: { required },
      date_hour: { required },
      meeting_point: { required },
      type_transfer: { required }

    }
  },
  beforeMount () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  computed: {
    ...mapState('transfer', ['transfers', 'pagination']),
    ...mapState('transfer', { data: 'transfer' }),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('transfer', {
      fetchData: 'getList',
      fetchItem: 'show',
      postData: 'save',
      changeStatus: 'changeStatus',
      deleteItem: 'deleteTransfer'
    }),
    cleanStreaming () {
      this.transfer = {}
    },
    newTransfer () {
      this.$refs['modal-transfer'].show()
      this.modal = true
    },
    showTransfer (id) {
      this.fetchItem({ partner_id: this.$route.params.id, id }).then((res) => {
        this.$refs['modal-transfer'].show()
        this.transfer = this.data
      })
    },
    closeModal () {
      this.$refs['modal-transfer'].hide()
      this.cleanStreaming()
      this.$v.$reset()
    },
    deleteTransfer (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem({ partner_id: this.$route.params.id, id: id }).then((res) => {
              successMessage('Sucesso: Vídeo excluído com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    async createTransfer () {
      this.submitted = true
      this.$v.transfer.$touch()
      if (this.$v.transfer.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      await this.postData(this.transfer).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.closeModal()
        this.updateList()
        loader.hide()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    },
    updateList () {
      this.fetchData({ partner_id: this.$route.params.id, limit: this.perPage, page: this.currentPage })
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.closeModal()
      }
    }
  }
}
</script>

<style>
.transfer-archived { color: #909090; background: #fff0f0}
.mt-15 { margin-top:15px }
.table-responsive { min-height: 400px;}
</style>
