<template>
  <b-modal v-model="modal" :title="title" size="lg" scrollable :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveRule()" autocomplete="off">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="objetivo">Informe as regras para a gamificação</label>
                <ckeditor
                  :editor="editor"
                  v-model="rule.rules"
                  :config="editorConfig"
                >
                </ckeditor>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveRule()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import service from './services'

export default {
  components: {
    Loading,
    ckeditor: CKEditor.component
  },
  data () {
    return {
      editor: ClassicEditor,
      rule: {},
      editorConfig: { toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ] },
      title: 'Regras/Regulamento da Gamificação',
      loading: true,
      fullPage: true,
      submitted: false,
      modal: false
    }
  },
  methods: {
    loadItem () {
      this.loading = true
      service.getGamificationRules().then((data) => {
        this.rule = data.data
        this.loading = false
      })
    },
    async saveRule () {
      this.submitted = true
      this.loading = true
      await service.saveGamificationRule(this.rule).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.loading = false
        this.close()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    open () {
      this.modal = true
      this.loadItem()
    },
    close () {
      this.modal = false
      this.submitted = false
      this.rule = {}
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
