<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="text-sm-right">
              <button type="button" class="btn btn-dark btn-sm btn-rounded mr-2" @click="openCategory()">
                <i class="mdi mdi-plus"></i> Categorias
              </button>
              <button type="button" class="btn btn-success btn-sm btn-rounded mr-2" @click="newProduct()">
                <i class="mdi mdi-plus mr-1"></i> Novo Produto
              </button>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table project-list-table table-nowrap table-centered table-borderless">
            <thead>
              <tr>
                <th scope="col" style="width: 20px"></th>
                <th scope="col" style="width: 20px"></th>
                <th scope="col" width="10%">Categoria</th>
                <th scope="col" width="40%">Nome</th>
                <th scope="col" width="10%">Valor</th>
                <th scope="col" width="10%">Estoque</th>
                <th scope="col" width="10%">Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            <draggable
              v-model="products"
              :element="'tbody'"
              tag="tbody"
              :options="{animation:200, handle:'.mover'}"
              @change="updateSequence"
            >
              <tr v-for="(s, i) in products" :key="i" class="link" :class="{ 'product-archived': s.archived }">
                <td class="mover"><i class="bx bx-move"></i></td>
                <td class="mover">
                  <span class="badge font-size-13 badge-soft-primary p-1 badge-num">{{ s.sequence }}</span>
                </td>
                <td @click="editProduct(s.id)" title="Click para editar">{{ s.category.data.name }}</td>
                <td @click="editProduct(s.id)" title="Click para editar">{{ s.name }}</td>
                <td @click="editProduct(s.id)" title="Click para editar">{{ s.points_cost }}</td>
                <td @click="editProduct(s.id)" title="Click para editar">{{ s.stock_quantity }}</td>
                <td>
                  <b-form-checkbox v-model="s.status" switch @change="toggleStatus(s.id)" class="success"></b-form-checkbox>
                </td>
                <td>
                  <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18"></i>
                    </template>
                    <b-dropdown-item @click="editProduct(s.id)">
                      <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteProduct(s.id)">
                      <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
        <div class="row mt-4" v-show="products.length > 0">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
              </ul>
            </div>
          </div>
        </div>
        <product v-model="modalProduct" ref="modalProduct" @created="updateList"></product>
        <category v-model="modalCategory" ref="modalCategory"></category>
      </div>
    </div>
  </Layout>
</template>
<script>
import Swal from 'sweetalert2'
import draggable from 'vuedraggable'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import Category from './Category'
import Product from './Product'

export default {
  components: {
    draggable,
    Layout,
    PageHeader,
    Category,
    Product
  },
  name: 'store-products',
  page: {
    title: 'Produtos'
  },
  data () {
    return {
      submitted: false,
      modalCategory: false,
      modalProduct: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      title: 'Produtos',
      items: [{
        text: 'Produtos'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  beforeMount () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  computed: {
    ...mapState('product', { data: 'products' }),
    ...mapState('product', ['pagination']),
    rows () {
      return this.pagination.total
    },
    products: {
      get () {
        return this.data
      },
      set (value) {
        let loader = this.$loading.show()
        this.updateSequence({ itens: value }).then(res => {
          loader.hide()
          successMessage('Sucesso: Ordem atualizada.', 2500)
        })
      }
    }
  },
  methods: {
    ...mapActions('product', {
      fetchData: 'getList',
      changeStatus: 'changeStatus',
      deleteItem: 'deleteProduct',
      updateSequence: 'orderList'
    }),
    openCategory () {
      this.$refs.modalCategory.open()
    },
    newProduct () {
      this.$refs.modalProduct.open()
      this.modalProduct = true
    },
    editProduct (id) {
      this.$refs.modalProduct.open(id)
      this.modalProduct = true
    },
    deleteProduct (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem({ id: id }).then((res) => {
              successMessage('Sucesso: Produto excluído com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    async toggleStatus (id, status = '') {
      try {
        await this.changeStatus({ id, status })
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    updateList () {
      this.fetchData({ limit: this.perPage, page: this.currentPage, include: 'category' })
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.product-archived { color: #909090; background: #fff0f0}
.mt-15 { margin-top:15px }
</style>
