<template>
  <div style="margin-left:-15px">
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveHotel()">
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="name">Nome</label>
                    <input
                      id="name"
                      name="name"
                      v-model="hotel.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.hotel.name.$error,
                      }"
                      placeholder="Inform o nome do evento"
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="address">Endereço</label>
                    <input
                      id="address"
                      name="address"
                      v-model="hotel.address"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.hotel.address.$error,
                      }"
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="accommodation">Acomodações</label>
                    <input
                      id="accommodation"
                      name="accommodation"
                      v-model="hotel.accommodation"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.hotel.accommodation.$error,
                      }"
                    />
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="has_restaurant">Tem Restaurante?</label>
                    <select
                      id="has_restaurant"
                      name="has_restaurant"
                      v-model="hotel.has_restaurant"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.hotel.has_restaurant.$error,
                      }"
                    >
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row" v-if="parseInt(hotel.has_restaurant) === 1">
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="restaurant_name">Nome do restaurante</label>
                    <input
                      id="restaurant_name"
                      name="restaurant_name"
                      v-model="hotel.restaurant_name"
                      type="text"
                      class="form-control"
                      placeholder="Informe o nome do restaurante"
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="restaurant_breakfast">Horário café da manhã</label>
                    <input
                      id="restaurant_breakfast"
                      name="restaurant_breakfast"
                      v-model="hotel.restaurant_breakfast"
                      type="text"
                      class="form-control"
                      placeholder="Informe o nome do restaurante"
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="restaurant_lunch">Horário de almoço</label>
                    <input
                      id="restaurant_lunch"
                      name="restaurant_lunch"
                      v-model="hotel.restaurant_lunch"
                      type="text"
                      class="form-control"
                      placeholder="Informe o nome do restaurante"
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="restaurant_dinner">Horário de jantar</label>
                    <input
                      id="restaurant_dinner"
                      name="restaurant_dinner"
                      v-model="hotel.restaurant_dinner"
                      type="text"
                      class="form-control"
                      placeholder="Informe o nome do restaurante"
                    />
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-lg-12">
                    <label for="phone">Fotos do hotel</label>
                </div>

                <div class="col-lg-3">
                  <label for="photo">Imagem</label>
                    <input
                      id="photo"
                      ref="photo"
                      name="photo"
                      type="file"
                      @change="getPhoto"
                      class="form-control"
                    />
                </div>
                <div class="col-lg-1 mt-4">
                  <button type="button" class="btn btn-primary mr-1" @click="addPhoto()">
                    Adicionar
                  </button>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-lg-12">
                  <div class="popup-gallery d-flex flex-wrap gap-2">
                    <div class="float-left rounded p-1" v-for="(p, idx) in photos" :key="idx">
                      <img :src="p.photo" style="width:10rem;height:8rem">
                      <p> <i class="fas fa-trash-alt text-danger mr-1 link" @click="delItem(idx)"></i></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-1 float-right">
                <button type="submit" class="btn btn-primary mr-1">
                  Salvar
                </button>
              </div>
            </form>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage, warningMessage } from '@/components/alertsMessages'

export default {
  components: {
  },
  name: 'products',
  data () {
    return {
      submitted: false,
      photoFile: null,
      photos: [],
      hotel: {
        status: false
      }
    }
  },
  validations: {
    hotel: {
      name: { required },
      address: { required },
      accommodation: { required }
    }
  },
  mounted () {
    let loader = this.$loading.show()
    this.fetchItem().then((res) => {
      this.hotel = this.data
      if (this.data.photos.data) {
        this.photos = this.data.photos.data
      }
      loader.hide()
    }).catch(loader.hide())
  },
  computed: {
    ...mapState('hotel', { data: 'hotel' })
  },
  methods: {
    ...mapActions('hotel', {
      fetchItem: 'show',
      postData: 'save',
      changeStatus: 'changeStatus',
      deleteItem: 'deletePhoto'
    }),
    getPhoto (e) {
      const file = e.target.files[0]
      this.photoUrl = URL.createObjectURL(file)
      this.photoFile = file
    },
    addPhoto () {
      if (this.photoFile) {
        this.photos.push({ file: this.photoFile, photo: this.photoUrl })
      }
      console.log(this.photos)
    },
    delItem (indice) {
      if (this.photos.length === 1) {
        warningMessage('Atenção: Hotel deve conter ao menos uma foto!')
        return
      }
      let item = this.photos[indice]
      if (item.hasOwnProperty('id')) {
        this.deletePhoto(item.id, indice)
      } else {
        this.photos.splice(indice, 1)
      }
    },
    deletePhoto (id, indice) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem({ id: id }).then((res) => {
              successMessage('Sucesso: Foto excluída com sucesso.', 2500)
              this.photos.splice(indice, 1)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    async saveHotel () {
      this.submitted = true
      this.$v.hotel.$touch()
      if (this.$v.hotel.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      const formData = new FormData()
      formData.append('id', this.hotel.id ? this.hotel.id : '')
      formData.append('name', this.hotel.name)
      formData.append('address', this.hotel.address)
      formData.append('accommodation', this.hotel.accommodation)
      formData.append('has_restaurant', this.hotel.has_restaurant)
      formData.append('restaurant_name', this.hotel.restaurant_name ? this.hotel.restaurant_name : '')
      formData.append('restaurant_breakfast', this.hotel.restaurant_breakfast ? this.hotel.restaurant_breakfast : '')
      formData.append('restaurant_lunch', this.hotel.restaurant_lunch ? this.hotel.restaurant_lunch : '')
      formData.append('restaurant_dinner', this.hotel.restaurant_dinner ? this.hotel.restaurant_dinner : '')

      if (this.photos.length) {
        this.photos.forEach((option, index) => {
          if (!option.id) {
            formData.append(`photos[${index}][picture]`, option.file)
          }
        })
      }

      await this.postData(formData).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        loader.hide()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    }
  }
}
</script>

<style scoped>
.product-archived { color: #909090; background: #fff0f0}
.mt-15 { margin-top:15px }
</style>
