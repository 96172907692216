<template>
  <b-modal v-model="modal" :title="title" size="md" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="sendEmailTest()" autocomplete="off">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="name">Nome</label>
                <input
                  id="name"
                  v-model="test.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.test.name.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.test.name.required"
                  class="invalid-feedback"
                >
                  O campo nome é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label for="email">E-mail</label>
                <input
                  id="email"
                  v-model="test.email"
                  name="email"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.test.email.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.test.email.required"
                  class="invalid-feedback"
                >
                  O campo email é obrigatório.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="sendEmailTest()">Enviar E-mail</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import service from './services'

export default {
  components: {
    Loading
  },
  data () {
    return {
      title: 'Enviar E-mail de teste',
      loading: true,
      fullPage: true,
      submitted: false,
      test: {},
      modal: false
    }
  },
  validations () {
    return {
      test: {
        name: { required },
        email: { required }
      }
    }
  },
  methods: {
    // sendEmailTest () {
    //   service.list({ type: 1 }).then((res) => {
    //     this.optionsRooms = res.data.data
    //   })
    // },
    open (id = null) {
      this.modal = true
      this.loading = false
    },
    async sendEmailTest () {
      this.submitted = true
      this.$v.test.$touch()
      if (this.$v.test.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await service.sendEmail(this.test).then(res => {
        let message = 'Sucesso: Em alguns minutos você receberá um e-mail de teste.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.test = {}
      this.$v.$reset()
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
