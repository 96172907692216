<template>
  <div style="margin-left:-15px">
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="text-sm-right">
          <button type="button" class="btn btn-success btn-sm btn-rounded" @click="newStreaming()">
            <i class="mdi mdi-plus"></i> Inserir Vídeo
          </button>
        </div>
      </div>
      <!-- end col-->
    </div>
    <div class="row">
      <div class="col-md-12 text-center" v-show="videos.length === 0">
        <h5 class="text-truncate font-size-14 p-4 mt-2">Nenhum vídeo cadastrado. </h5>
        <i class="bx bx-tired bx-lg"></i>
        <br />
      </div>
      <div class="table-responsive mt-3 mb-0" v-show="videos.length > 0">
        <table class="table table-centered table-nowrap table-hover">
          <thead>
            <tr>
              <th width="40%">Descrição</th>
              <th width="50%">URL</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(s, i) in videos" :key="i" class="link" :class="{ 'streaming-archived': s.archived }">
              <td @click="showStreaming(s.id)">{{ s.description }}</td>
              <td @click="showStreaming(s.id)">{{ s.url_video }}</td>
              <td>
                <b-form-checkbox v-model="s.status" switch @change="toggleStatus(s.id)" :disabled="s.archived ? true : false"></b-form-checkbox>
              </td>
              <td>
                <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal font-size-18"></i>
                  </template>
                  <b-dropdown-item @click="showStreaming(s.id)">
                    <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteStreaming(s.id)">
                    <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-4" v-show="videos.length > 0">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
          </ul>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-center"
      v-model="modal"
      ref="modal-streaming"
      :title="video.id ? 'Editar Vídeo' : 'Cadastrar Vídeo'"
      title-class="font-18"
      size="lg"
      :no-close-on-backdrop="true"
    >
      <form @submit.prevent="createStreaming()">
        <div class="row mt-2">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="description">Descrição</label>
              <input
                id="description"
                v-model="video.description"
                name="description"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.video.description.$error }"
              />
              <div
                v-if="submitted && !$v.video.description.required"
                class="invalid-feedback"
              >
                O campo descrição é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="url_video">Link do vídeo</label>
              <input
                id="url_video"
                v-model="video.url_video"
                name="url_video"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.video.url_video.$error }"
              />
              <div
                v-if="submitted && !$v.video.url_video.required"
                class="invalid-feedback"
              >
                O campo link do vídeo é obrigatório
              </div>
              <div
                v-if="submitted && !$v.video.url_video.url"
                class="invalid-feedback"
              >
                O campo link do vídeo deve ser uma URL válida
              </div>
            </div>
          </div>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeModal()">Cancelar</b-button>
        <b-button variant="primary" @click="createStreaming()">Salvar</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import 'cropperjs/dist/cropper.css'
import { required, url } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
  },
  name: 'videos',
  data () {
    return {
      submitted: false,
      params: {},
      modal: false,
      video: {
        status: false
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20
    }
  },
  validations: {
    video: {
      description: { required },
      url_video: { required, url }

    }
  },
  beforeMount () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  computed: {
    ...mapState('partnerVideos', ['videos', 'pagination']),
    ...mapState('partnerVideos', { data: 'video' }),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('partnerVideos', {
      fetchData: 'getList',
      fetchItem: 'show',
      postData: 'save',
      changeStatus: 'changeStatus',
      deleteItem: 'deleteStreaming'
    }),
    cleanStreaming () {
      this.video = {}
    },
    newStreaming () {
      this.$refs['modal-streaming'].show()
      this.modal = true
    },
    showStreaming (id) {
      this.fetchItem({ partner_id: this.$route.params.id, id }).then((res) => {
        this.$refs['modal-streaming'].show()
        this.video = this.data
      })
    },
    closeModal () {
      this.$refs['modal-streaming'].hide()
      this.cleanStreaming()
      this.$v.$reset()
    },
    deleteStreaming (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem({ partner_id: this.$route.params.id, id: id }).then((res) => {
              successMessage('Sucesso: Vídeo excluído com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    async toggleStatus (id, status = '') {
      try {
        await this.changeStatus({ partner_id: this.$route.params.id, id, status })
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async createStreaming () {
      this.submitted = true
      this.video.partner_id = this.$route.params.id
      this.$v.video.$touch()
      if (this.$v.video.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      await this.postData(this.video).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.closeModal()
        this.updateList()
        loader.hide()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    },
    updateList () {
      this.fetchData({ partner_id: this.$route.params.id, limit: this.perPage, page: this.currentPage })
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.closeModal()
      }
    }
  }
}
</script>

<style>
.streaming-archived { color: #909090; background: #fff0f0}
.mt-15 { margin-top:15px }
.table-responsive { min-height: 400px;}
</style>
