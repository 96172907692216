<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveSurvey()" autocomplete="off">
          <div class="row">
            <div class="col-sm-9">
              <div class="form-group">
                <label for="asking">Pergunta</label>
                <input
                  id="asking"
                  v-model="survey.asking"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.survey.asking.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.survey.asking.required"
                  class="invalid-feedback"
                >
                  O campo pergunta é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="required_answer">Obrigatoriedade</label>
                <select
                  id="required_answer"
                  v-model="survey.required_answer"
                  name="name"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.survey.required_answer.$error
                  }"
                >
                  <option value="1">Exige Resposta</option>
                  <option value="0">Opcional</option>
                </select>
                <div
                  v-if="submitted && !$v.survey.required_answer.required"
                  class="invalid-feedback"
                >
                  O campo Obrigatoriedade é obrigatório.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label for="description">Descrição Extra</label>
                <input
                  id="description"
                  v-model="survey.description"
                  name="description"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="asking_type">Tipo</label>
                <multiselect v-model="selected" @input="setType" placeholder="Selecione uma opção" label="label" track-by="value" :options="asking_types" :option-height="104" :show-labels="false">
                  <template slot="singleLabel" slot-scope="props">
                    <i :class="props.option.icon"></i>
                    <span class="option__desc ml-2">
                      <span class="option__title">{{ props.option.label }}</span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <i :class="props.option.icon"></i>
                    <span class="option__desc ml-2">
                      <span class="option__title">{{ props.option.label }}</span>
                    </span>
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row mb-2" v-if="showAnswers">
            <div class="col-sm-12">
              <h5 class="text-truncate font-size-14 float-left mr-3">Respostas</h5>
              <span class="link text-success" @click="addItem()"><i class="mdi mdi-plus text-success"></i> Adicionar</span>
              <!-- <hr class="mt-0" /> -->
            </div>
          </div>
          <span v-if="showAnswers">
            <div class="row" v-for="(askingAnswer, index) in survey.answers" :key="'answ-'+index">
              <div class="col-sm-11">
                <div class="form-group">
                  <label for="asking">Resposta</label>
                  <input
                    v-model="askingAnswer.answer"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.survey.answers.$each[index].answer.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.survey.answers.$each[index].answer.required"
                    class="invalid-feedback"
                  >
                  O campo resposta é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-1">
                <div class="form-group mt-4">
                  <i class="fas fa-trash-alt text-danger link mt-15" @click="delItem(index)"></i>
                </div>
              </div>
            </div>
          </span>
          <span v-if="showRating">
            <div class="row">
              <div class="col-sm-8">
                <div class="form-group">
                  <label for="minRateDescription">Descrição avaliação mínina</label>
                  <input
                    v-model="survey.minRateDescription"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.survey.minRateDescription.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.survey.minRateDescription.required"
                    class="invalid-feedback"
                  >
                  O campo resposta é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label for="rateMin">Classificação mínina</label>
                  <input
                    v-model="survey.rateMin"
                    type="number"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.survey.rateMin.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.survey.rateMin.required"
                    class="invalid-feedback"
                  >
                  O campo resposta é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <div class="form-group">
                  <label for="maxRateDescription">Descrição avaliação máxima</label>
                  <input
                    v-model="survey.maxRateDescription"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.survey.maxRateDescription.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.survey.maxRateDescription.required"
                    class="invalid-feedback"
                  >
                  O campo resposta é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label for="rateMax">Classificação máxima</label>
                  <input
                    v-model="survey.rateMax"
                    type="number"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.survey.rateMax.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.survey.rateMax.required"
                    class="invalid-feedback"
                  >
                  O campo resposta é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </span>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveSurvey()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import Swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage, warningMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading,
    Multiselect
  },
  props: {
    idSurvey: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Nova Pergunta',
      loading: true,
      selected: '',
      showAnswers: true,
      showRating: false,
      asking_types: [
        { value: 1, label: 'Escolha única', icon: 'mdi mdi-order-bool-descending font-size-16' },
        { value: 2, label: 'Multipla escolha', icon: 'dripicons-checklist font-size-16' },
        { value: 3, label: 'Texto de resposta', icon: 'bx bx-text font-size-16' },
        { value: 4, label: 'Avaliação', icon: 'mdi mdi-numeric font-size-16' }
      ],
      fullPage: true,
      submitted: false,
      survey: {
        answers: [{
          answer: ''
        }]
      },
      modal: false
    }
  },
  validations () {
    if (this.survey.asking_type === 3) {
      return {
        selected: { required },
        survey: {
          asking: { required },
          required_answer: { required }
        }
      }
    } else if (this.survey.asking_type === 4) {
      return {
        selected: { required },
        survey: {
          asking: { required },
          minRateDescription: { required },
          maxRateDescription: { required },
          rateMin: { required },
          rateMax: { required },
          required_answer: { required }
        }
      }
    } else {
      return {
        selected: { required },
        survey: {
          asking: { required },
          required_answer: { required },
          answers: {
            $each: {
              answer: { required }
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('survey', {
      getAsking: 'show',
      postData: 'save',
      deleteAnswer: 'deleteAnswer'
    }),
    loadItem (id) {
      if (id) {
        this.title = 'Editar Pergunta/Resposta'
        this.getAsking(id).then((data) => {
          this.survey = this.data
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        console.log(id)
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    addItem () {
      const asnwer = { answer: '' }
      this.survey.answers = [...this.survey.answers, asnwer]
    },
    delItem (indice) {
      if (this.survey.answers.length === 1 & this.survey.asking_type <= 2) {
        warningMessage('Atenção: Pergunta deve conter ao menos uma resposta!')
        return
      }
      let item = this.survey.answers[indice]
      if (item.hasOwnProperty('id')) {
        this.confirmDeleteAnswer(item.id, indice)
      } else {
        this.survey.answers.splice(indice, 1)
      }
    },
    confirmDeleteAnswer (id, indice) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.loading = true
            this.deleteAnswer(id).then((res) => {
              successMessage('Sucesso: Resposta excluída com sucesso.', 2500)
              this.survey.answers.splice(indice, 1)
              this.loading = false
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
              this.loading = false
            })
          } catch (err) {
            console.log(err)
            this.loading = false
          }
        }
      })
    },
    setType (option) {
      if (typeof (option) === 'object') {
        this.survey.asking_type = option.value
        this.showAnswers = true
        this.showRating = false
        if (option.value === 3) {
          this.survey.answers = []
          this.showAnswers = false
          this.showRating = false
          return
        }
        if (option.value === 4) {
          this.survey.answers = []
          this.showRating = true
          this.showAnswers = false
        }
      }
    },
    async saveSurvey () {
      this.submitted = true
      this.$v.survey.$touch()
      if (this.$v.survey.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      if (typeof (this.selected) === 'object') {
        this.survey.asking_type = this.selected.value
      }
      await this.postData(this.survey).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.showRating = false
      this.survey = { answers: [{ answer: '' }] }
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('survey', { data: 'survey' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    },
    'survey.asking_type' (value) {
      this.selected = this.asking_types.filter(o => o.value === value)[0]
      this.setType(this.selected)
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
.swal2-popup {
  font-size: 11px !important;
}
.mt-15 { margin-top:15px }
</style>
