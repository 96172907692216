<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12">
          <div class="row mb-2">
            <!-- <div class="col-sm-4">
              <div class="search-box mr-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input type="text" class="form-control" placeholder="Search..." />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div> -->
            <div class="col-sm-12">
              <div class="text-sm-right">
                <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newRoom()">
                  <i class="mdi mdi-plus mr-1"></i> Nova Sala
                </button>
              </div>
            </div>
            <!-- end col-->
          </div>
          <div class="table-responsive">
            <table class="table project-list-table table-nowrap table-centered table-borderless">
              <thead>
                <tr>
                  <th scope="col" style="width: 20px"></th>
                  <th scope="col" style="width: 20px"></th>
                  <th scope="col" style="width: 100px"></th>
                  <th scope="col">Sala</th>
                  <th scope="col">Status</th>
                  <th scope="col">Abertura</th>
                  <th scope="col">Encerramento</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <draggable
                v-model="rooms"
                :element="'tbody'"
                tag="tbody"
                :options="{animation:200, handle:'.mover'}"
                @change="updateSequence"
              >
                <tr v-for="(room, i) in rooms" :key="i" class="link">
                  <td class="mover"><i class="bx bx-move"></i></td>
                  <td class="mover">
                    <span class="badge font-size-13 badge-soft-primary p-1 badge-num">{{ room.sequence }}</span>
                  </td>
                  <td @click="showRoom(room.id)">
                    <img :src="`${room.thumb_image}`" alt class="rounded avatar-thumb" />
                  </td>
                  <td @click="showRoom(room.id)">
                    <h5 class="text-truncate font-size-14">
                        <a href="javascript: void(0);" class="text-dark">{{ room.name }} <i v-show="room.allow_users" class="bx bxs-lock-alt" alt="Sala Restrita" title="Sala Restrita"></i></a>
                    </h5>
                    <span class="badge badge-soft-primary font-size-11 m-1">SALA {{ room.sequence }}</span>
                  </td>
                  <td @click="showRoom(room.id)">
                    <span class="badge badge-pill font-size-12" :class="{
                        'badge-soft-success': `${parseInt(room.status)}` === '1',
                        'badge-soft-danger': `${parseInt(room.status)}` === '0',
                      }">{{ statusLabel[room.status] }}</span>
                  </td>
                  <td @click="showRoom(room.id)">{{ $moment(room.start_date).format('DD/MM HH:mm') }}</td>
                  <td @click="showRoom(room.id)">{{ $moment(room.end_date).format('DD/MM HH:mm') }}</td>
                  <td>
                    <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>
                        <b-dropdown-item @click="editRoom(room.id)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="modalSpeakers(room.id)">
                          <i class="fas fa-users mr-1"></i> Vincular Palestrantes
                        </b-dropdown-item>
                        <b-dropdown-item @click="toggleStatus(room.id)">
                          <i class="fas fa-circle mr-1" :class="{ 'text-danger': parseInt(room.status) === 1, 'text-success': parseInt(room.status) === 0 }"></i> {{ room.status ? 'Inativar' : 'Ativar' }}</b-dropdown-item>
                        <b-dropdown-item @click="deleteRoom(room.id)">
                          <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                        </b-dropdown-item>
                      </b-dropdown>
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <modal-palestrantes-sala ref="modalPalestrantes" @success="defineSpeakers"></modal-palestrantes-sala>
  </Layout>
</template>
<script>
import draggable from 'vuedraggable'
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import ModalPalestrantesSala from '@/components/modals/modal-palestrantes-sala'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  components: {
    ModalPalestrantesSala,
    draggable,
    Layout,
    PageHeader
  },
  name: 'Salas',
  page: {
    title: 'Salas',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      params: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      statusLabel: ['Inativo', 'Ativo'],
      imgSrc: '',
      speakersList: [],
      cropImg: '',
      submitted: false,
      title: 'Salas',
      items: [{
        text: 'Salas'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapGetters('room', { data: 'ROOMS' }),
    ...mapState('room', ['pagination']),
    rows () {
      return this.pagination.total
    },
    rooms: {
      get () {
        return this.data
      },
      set (value) {
        let loader = this.$loading.show()
        this.updateSequence({ itens: value }).then(res => {
          loader.hide()
          successMessage('Sucesso: Ordem atualizada.', 2500)
        })
      }
    }
  },
  methods: {
    ...mapActions('room', {
      GetRooms: 'GetRooms',
      changeStatus: 'changeStatus',
      deleteItem: 'deleteRoom',
      updateSequence: 'orderList',
      attachSpeakers: 'addSpeakers'
    }),
    showRoom (id) {
      this.$router.push({
        name: 'room-show',
        params: {
          id: id
        }
      })
    },
    newRoom () {
      this.$router.push({
        name: 'room-new'
      })
    },
    editRoom (id) {
      this.$router.push({
        name: 'room-edit',
        params: {
          id: id
        }
      })
    },
    modalSpeakers (roomId) {
      let room = this.rooms.filter(r => r.id === roomId)[0]
      if (room.participants) {
        this.speakersList = [...room.participants.data]
      }
      this.$refs.modalPalestrantes.open(roomId, this.speakersList)
    },
    async defineSpeakers (data) {
      let loader = this.$loading.show()
      await this.attachSpeakers({ room_id: data.room_id, speakers: data }).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        loader.hide()
        successMessage(message, delay)
        this.updateList()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    },
    async toggleStatus (id) {
      try {
        await this.changeStatus(id)
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    deleteRoom (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem(id).then((res) => {
              successMessage('Sucesso: Sala excluído com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    updateList () {
      let loader = this.$loading.show()
      this.GetRooms({ limit: this.perPage, include: 'schedules,participants', page: this.currentPage }).then(() => {
        loader.hide()
      })
    }
  },
  watch: {
    currentPage () { this.updateList() },
    total () { this.updateList() },
    dataPerPage () { this.updateList() }
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.avatar-thumb { height: 4rem; width: 6rem;}
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 400px;}
</style>
