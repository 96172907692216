<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="!setting.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden text-center mt-4">
                <h5 class="text-truncate font-size-15 ">Olá, {{ user.name }}.</h5>
                <p class="text-muted">Indentificamos que a sua plataforma ainda não está configurada.</p>
                <router-link :to="{ name: 'event' }">
                  <b-button variant="primary" size="lg" class="mt-4 mb-4" >
                    <i class="bx bx-cog font-size-16 align-middle mr-2"></i> CONFIGURAR AGORA!
                  </b-button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="setting.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Imagens</h4>
            <div class="row">
              <div class="col-lg-4">
                <vue-dropzone
                  id="dropzone-header"
                  :options="dropzoneOptions"
                  :useCustomSlot="true" ref="dropzone"
                  v-on:vdropzone-sending="sendingEvent"
                  v-on:vdropzone-success="successUpload"

                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">Imagem de logotipo</h3>
                    <div class="subtitle">Arraste e solte ou clique aqui para escolher uma imagem </div>
                  </div>
                </vue-dropzone>

                <p class="card-title-desc mt-1">Recomendado: 200x150 pixels, formato PNG</p>
              </div>

              <div class="col-lg-4">
                <vue-dropzone
                  id="dropzone-header"
                  :options="dropzoneOptions"
                  v-on:vdropzone-sending="sendingEvent2"
                  :useCustomSlot="true" ref="dropzone2"
                  v-on:vdropzone-success="successUpload"

                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">Imagem banner de autenticação</h3>
                    <div class="subtitle">Arraste e solte ou clique aqui para escolher uma imagem </div>
                  </div>
                </vue-dropzone>

                <p class="card-title-desc mt-1">Recomendado: 1500x1072 pixels, formato JPG ou PNG</p>
              </div>

              <div class="col-lg-4">
                <vue-dropzone
                  id="dropzone-header"
                  :options="dropzoneOptions"
                  :useCustomSlot="true" ref="dropzone3"
                  v-on:vdropzone-sending="sendingEvent3"
                  v-on:vdropzone-success="successUpload"

                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">Imagem banner perfil</h3>
                    <div class="subtitle">Arraste e solte ou clique aqui para escolher uma imagem </div>
                  </div>
                </vue-dropzone>

                <p class="card-title-desc mt-1">Recomendado: 1500x935 pixels, formato JPG ou PNG</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Cores</h4>
            <form @submit.prevent="updateColors()">
              <div class="row">
                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="color_header">Cor Header</label>
                    <b-form-input
                      id="color_header"
                      v-model="colors.color_header"
                      type="color"
                      :class="{
                        'is-invalid': submitted && $v.colors.color_header.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.colors.color_header.required"
                      class="invalid-feedback"
                    >
                      O campo cor header é obrigatório.
                    </div>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="color_icon_header">Cor Icon Header</label>
                    <b-form-input
                      id="color_icon_header"
                      v-model="colors.color_icon_header"
                      type="color"
                      :class="{
                        'is-invalid': submitted && $v.colors.color_icon_header.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.colors.color_icon_header.required"
                      class="invalid-feedback"
                    >
                      O campo cor icon header é obrigatório.
                    </div>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="app_color_bg_primary">Cor primária</label>
                    <b-form-input
                      id="app_color_bg_primary"
                      v-model="colors.app_color_bg_primary"
                      type="color"
                      :class="{
                        'is-invalid': submitted && $v.colors.app_color_bg_primary.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.colors.app_color_bg_primary.required"
                      class="invalid-feedback"
                    >
                      O campo cor principal é obrigatório.
                    </div>
                  </div>
                </div>

                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="app_color_button_primary">Cor de botões</label>
                    <b-form-input
                      id="app_color_button_primary"
                      v-model="colors.app_color_button_primary"
                      type="color"
                      :class="{
                        'is-invalid': submitted && $v.colors.app_color_button_primary.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.colors.app_color_button_primary.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatória.
                    </div>
                  </div>
                </div>

                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="app_color_svg">Cor de icones</label>
                    <b-form-input
                      id="app_color_svg"
                      v-model="colors.app_color_svg"
                      type="color"
                      :class="{
                        'is-invalid': submitted && $v.colors.app_color_svg.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.colors.app_color_svg.required"
                      class="invalid-feedback"
                    >
                      O campo cor Secundária é obrigatória.
                    </div>
                  </div>
                </div>

                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="app_color_font_primary">Cor de texto</label>
                    <b-form-input
                      id="app_color_font_primary"
                      v-model="colors.app_color_font_primary"
                      type="color"
                      :class="{
                        'is-invalid': submitted && $v.colors.app_color_font_primary.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.colors.app_color_font_primary.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatória.
                    </div>
                  </div>
                </div>
                <div class="col-sm-2">
                  <button type="submit" class="btn btn-primary mr-1 mt-4">
                    Atualizar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  </Layout>
</template>
<script>
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import * as storage from '@/modules/auth/storage'

export default {
  components: {
    vueDropzone,
    Layout,
    PageHeader
  },
  name: 'customization',
  page: {
    title: 'Customizações',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      imageUploaded: false,
      colors: {},
      images: {},
      dropzoneOptions: {
        url: process.env.VUE_APP_DEFAULT_AUTH + '/admin/settings/images',
        thumbnailWidth: 600,
        addRemoveLinks: true,
        headers: { 'Authorization': 'bearer ' + this.token }
      },
      submitted: false,
      title: 'Customizações',
      items: [{
        text: 'Meu Evento',
        href: '/'
      },
      {
        text: 'Customizações',
        active: true
      }
      ]
    }
  },
  validations: {
    colors: {
      app_color_bg_primary: { required },
      color_header: { required },
      color_icon_header: { required },
      app_color_button_primary: { required },
      app_color_svg: { required },
      app_color_font_primary: { required }
    }
  },
  mounted () {
    let loader = this.$loading.show()
    this.GetSetting().then(() => {
      this.colors = this.setting.colors.data
      this.$refs.dropzone.setOption('headers', { 'Authorization': 'bearer ' + this.token })
      this.$refs.dropzone2.setOption('headers', { 'Authorization': 'bearer ' + this.token })
      this.$refs.dropzone3.setOption('headers', { 'Authorization': 'bearer ' + this.token })
      loader.hide()
    })
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('event', ['setting']),
    token () {
      return storage.getLocalToken()
    }
  },
  methods: {
    ...mapActions('event', {
      GetSetting: 'GetSetting',
      postSetting: 'SaveSetting',
      postColors: 'changeColors'
    }),
    removeFile (file, error, xhr) {
      this.images.app_logo_header = null
    },
    successUpload (file, response) {
      successMessage('Sucesso: Imagem atualizada.', 3500).then((
        this.$refs.dropzone.removeAllFiles(),
        this.$refs.dropzone2.removeAllFiles(),
        this.$refs.dropzone3.removeAllFiles()
      ))
    },
    sendingEvent (file, xhr, formData) {
      formData.append('app_logo_header', file)
    },
    sendingEvent2 (file, xhr, formData) {
      formData.append('app_banner_login', file)
    },
    sendingEvent3 (file, xhr, formData) {
      formData.append('app_banner_profile', file)
    },
    async clearHeader () {
      let loader = this.$loading.show()
      try {
        this.submitted = true
        await this.destroyHeader(this.emailtemplate.id).then((data) => {
          let message = 'Sucesso: Imagem removida'
          let delay = 2500
          successMessage(message, delay)
          // this.emailtemplate.image_header = null
          this.imageUploaded = false
          this.$refs.dropzone.removeAllFiles()
          console.log(data)
          loader.hide()
        })
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
        loader.hide()
      }
    },
    async updateColors () {
      this.submitted = true
      this.$v.colors.$touch()

      if (this.$v.colors.$invalid) {
        window.scroll(0, 0)
        return
      }

      let loader = this.$loading.show()

      this.colors.setting_id = this.setting.id
      await this.postColors(this.colors).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.GetSetting().then(() => {
          this.$v.$reset()
          loader.hide()
        })
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: block !important;
}
.emojis { max-width: 64px; overflow: hidden;}
.link { cursor: pointer; }
.preview-lg {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.logo-preview {
  width:200px;
  overflow:hidden;
  height: 65px;
}
.actions {
  margin-top: 1rem;
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.bg-black { background-color: #afafaf; opacity: 1; }
.border-dashed {
  border: 2px dashed #ced4da !important;
  padding: 4px;
}
.vue-dropzone .dz-message {
  margin: 1em 0 !important;
}
.dropzone-custom-content {
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #0065b7;
  font-size: 18px;
}

.subtitle {
  color: #314b5f;
  font-size: 14px;
}
.footerEmail {
  text-align: center;
  padding: 5px;
  background: #F8F8FA;
  margin-bottom: 0.5rem;
}
.iconSocial {
  padding: 0;
    display: inline-block;
    height: auto;
    margin: 0;
}

.btn-trash {
  position: absolute;
  top: 10px;
  left: 20px;
}
</style>
