var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"title":_vm.title,"size":"lg","no-enforce-focus":true,"no-close-on-backdrop":true},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancelar")]),_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"},on:{"click":function($event){return _vm.saveFeed()}}},[_vm._v("Salvar")])]},proxy:true}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('loading',{attrs:{"active":_vm.loading,"color":"#556ee6","loader":"dots","can-cancel":false,"is-full-page":false},on:{"update:active":function($event){_vm.loading=$event}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.saveFeed()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nome")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.feed.name),expression:"feed.name"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.feed.name.$error
                },attrs:{"id":"name","name":"name","type":"text"},domProps:{"value":(_vm.feed.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.feed, "name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.feed.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"color"}},[_vm._v("Pode postar")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.feed.can_post),expression:"feed.can_post"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.feed.can_post.$error
                },attrs:{"id":"color","name":"color"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.feed, "can_post", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option'),_c('option',{attrs:{"value":"1"}},[_vm._v("SIM")]),_c('option',{attrs:{"value":"0"}},[_vm._v("NÃO")])]),(_vm.submitted && !_vm.$v.feed.can_post.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"color"}},[_vm._v("Pode Curtir")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.feed.can_like),expression:"feed.can_like"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.feed.can_like.$error
                },attrs:{"id":"color","name":"color"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.feed, "can_like", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option'),_c('option',{attrs:{"value":"1"}},[_vm._v("SIM")]),_c('option',{attrs:{"value":"0"}},[_vm._v("NÃO")])]),(_vm.submitted && !_vm.$v.feed.can_like.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"color"}},[_vm._v("Pode Comentar")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.feed.can_comment),expression:"feed.can_comment"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.feed.can_comment.$error
                },attrs:{"id":"color","name":"color"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.feed, "can_comment", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option'),_c('option',{attrs:{"value":"1"}},[_vm._v("SIM")]),_c('option',{attrs:{"value":"0"}},[_vm._v("NÃO")])]),(_vm.submitted && !_vm.$v.feed.can_comment.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }