<template>
  <div class="container-fluid">
    <div class="center mt-5 ml-2 mr-2 justify-content-md-center" >
      <loading
        :active.sync="loading"
        color="#556ee6"
        loader="dots"
        :can-cancel="false"
        :is-full-page="false">
      </loading>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <p class="text-muted font-size-16">Nuvem de Palavras</p>
              <h4 class="card-title mb-4">{{ wordCloud.description }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body" style="min-height: 350px;padding: 80px">
              <div class="col-lg-12 text-center" style="">
                <div class="word-cloud-result">
                  <div class="tag-word">
                    <span class="word" :style="{ color: randomColor(), fontSize: fontCalc(w.total) }" v-for="(w, i) in words_cloud" :key="i">{{ w.word }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import http from '@/http'

export default {
  components: { Loading },
  data () {
    return {
      timeout: 10000,
      loading: true,
      wordCloudId: null,
      wordCloud: {},
      words_cloud: []
    }
  },
  methods: {
    getInitWord () {
      http.get(`/admin/rooms/${this.$route.params.id}/word_cloud/${this.wordCloudId}`).then((response) => {
        if (response.data.data.id) {
          this.wordCloud = response.data.data
          this.words_cloud = this.wordCloud.words_group.sort(() => Math.random() > 0.5 ? -1 : 1)
        }
      }).then(() => {
        this.loading = false
        this.reloadSync()
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    randomColor () {
      return '#' + (Math.random() * 0x3a37db << 0).toString(16)
    },
    fontCalc (total) {
      let font = 10
      font += total * 0.6
      if (font > 52) {
        return '52px'
      }
      return font + 'px'
    },
    reloadSync () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.getInitWord()
      }, 10000)
    }
  },
  beforeMount () {
    this.loading = true
    if (this.$route.params.wordCloudId) {
      this.wordCloudId = this.$route.params.wordCloudId
      this.getInitWord()
      return
    }
    this.loading = false
  },
  mounted () {
  },
  computed: {
  }
}
</script>
<style scoped>
.word-cloud-result {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 100%;
  transition: all 180ms ease-in-out 0s;
}
.tag-word {
  text-align: center;
  overflow: hidden;
}
.word {
  margin: 0px 3px;
  vertical-align: middle;
  display: inline-block;
  font-size: 10px;
}
</style>
