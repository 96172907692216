<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveMenu()" autocomplete="off">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label for="name">Título</label>
                <input
                  id="name"
                  v-model="menu.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.menu.name.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.menu.name.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="link">Link</label>
                <input
                  id="link"
                  v-model="menu.link"
                  name="link"
                  type="text"
                  class="form-control"
                  :disabled="menu.module"
                />
                <div
                  v-if="submitted && !$v.menu.link.url"
                  class="invalid-feedback"
                >
                O campo Link deve ser uma url válida
                </div>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group">
                <label for="icon">Icone</label>
                <input
                  id="icon"
                  v-model="menu.icon"
                  name="icon"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </form>
          <div class="row" v-if="menu.id">
            <div class="col-sm-12">
              <h5 class="text-truncate font-size-14 float-left mr-3">Submenus</h5>
              <span class="link text-success" @click="openModalSubMenu(menu.id)"><i class="mdi mdi-plus text-success"></i> Adicionar</span>
            </div>
          </div>
          <div class="row" v-if="menu.children && menu.children.length > 0">
            <div class="col-lg-12">
              <div class="table-responsive mt-1 mb-0">
                <table class="table table-centered table-nowrap table-hover">
                  <thead>
                    <tr>
                      <th width="40%">Nome</th>
                      <th width="35%">Link</th>
                      <th width="10%">Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(s, i) in menu.children" :key="i" class="link">
                      <td @click="openModalSubMenu(menu.id, s.id)" title="Click para editar">{{ s.name }}</td>
                      <td @click="openModalSubMenu(menu.id, s.id)" title="Click para editar">{{ s.link }}</td>
                      <td @click="openModalSubMenu(menu.id, s.id)" title="Click para editar">
                        <span class="badge badge-pill font-size-12" :class="{
                        'badge-soft-success': `${parseInt(s.status)}` === '1',
                        'badge-soft-danger': `${parseInt(s.status)}` === '0',
                      }">{{ statusLabel[s.status] }}</span>
                      </td>
                      <td>
                        <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>
                        <b-dropdown-item @click="openModalSubMenu(menu.id, s.id)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="toggleStatus(s.id)">
                          <i class="fas fa-circle mr-1" :class="{ 'text-danger': parseInt(s.status) === 1, 'text-success': parseInt(s.status) === 0 }"></i> {{ s.status ? 'Inativar' : 'Ativar' }}
                        </b-dropdown-item>
                        <!-- <b-dropdown-item @click="deleteMenu(list.id)" v-if="!list.module">
                          <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                        </b-dropdown-item> -->
                      </b-dropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveMenu()">Salvar</button>
    </template>
    <sub-menu-form v-model="modalSubMenu" ref="modalSubMenu" @update="loadItem(menu.id)"></sub-menu-form>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required, url } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import SubMenuForm from './SubMenuForm'

export default {
  components: {
    Loading,
    SubMenuForm
  },
  props: {
    idMenu: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Menu',
      statusLabel: ['Inativo', 'Ativo'],
      loading: true,
      fullPage: true,
      submitted: false,
      menu: {},
      modal: false,
      modalSubMenu: false
    }
  },
  validations: {
    menu: {
      name: { required },
      link: { url }
    }
  },
  methods: {
    ...mapActions('menu', {
      getMenu: 'show',
      changeStatus: 'changeStatus',
      postData: 'save'
    }),
    openModalSubMenu (refId, id = null) {
      this.$refs.modalSubMenu.open(refId, id)
      this.modalSubMenu = true
    },
    loadItem (id) {
      if (id) {
        this.title = 'Editar Menu'
        this.getMenu(id).then((data) => {
          this.menu = this.data
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveMenu () {
      this.submitted = true
      this.$v.menu.$touch()
      if (this.$v.menu.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.menu).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    async toggleStatus (id) {
      try {
        await this.changeStatus(id).then((res) => {
          let message = 'Sucesso: Status atualizado com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          this.loadItem(this.menu.id)
        }).catch((error) => {
          errorMessage('Alerta: ' + error.message)
        })
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    close () {
      this.modal = false
      this.menu = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('menu', { data: 'menu' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
