<template>
  <b-modal v-model="modal" :title="title" size="xl" :no-enforce-focus="true" :no-close-on-backdrop="true" scrollable>
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="col-lg-12">
      <div class="table-responsive mt-3 mb-0" v-if="indication.length > 0">
        <table class="table table-centered table-nowrap table-hover">
          <thead>
            <tr>
              <th width="40%">Nome</th>
              <th>IP</th>
              <th>Dipositivo</th>
              <th>Horário</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(g, i) in indication" :key="i">
              <td>
                {{ g.indicated.data.name }}<br />
                <span class="text-muted font-size-11" style="word-break: break-all;">{{ g.indicated.data.email }}</span>
                <span class="text-muted font-size-11" style="word-break: break-all;">{{ g.indicated.data.phone }}</span>
              </td>
              <td>
                {{ g.ip }}<br />
              </td>
              <td>
                {{ g.device }}<br />
                <span class="text-muted font-size-11" style="word-break: break-all;">{{ g.os +  ' (' + g.browser + ')'}}</span>
              </td>
              <td width="8%">{{ $moment(g.created_at).format('DD/MM/YYYY HH:mm:ss') }}</td>
              <td width="8%">
                <i class="fas fa-trash-alt text-danger mr-1 link" @click="deleteIndication(g.id, i)"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p v-if="indication.length === 0">Nenhuma indicação encontrada</p>
    </div>
    <template v-slot:modal-footer>
      <span class="float-left" style="justify-content: left;">
        TOTAL:  <b-badge variant="primary" class="font-size-15 p-2" style="min-width:30px">{{ totalIndication }} indicações</b-badge>
      </span>
      <b-button variant="secondary" @click="close()">Fechar</b-button>
    </template>
  </b-modal>
</template>
<script>
import serviceReport from '@/pages/reports/services'
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import Swal from 'sweetalert2'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  name: 'gamification-details',
  components: {
    Loading
  },
  props: {},
  data () {
    return {
      loading: false,
      title: '',
      indication: [],
      user: {},
      modal: false
    }
  },
  methods: {
    loadItem (id) {
      if (id) {
        console.log(id)
        serviceReport.getIndicationDetail(id).then((data) => {
          this.user = data.data
          this.indication = this.user.indication.data
          console.log(this.indication)
          this.title = `Indicações - ${this.user.name}`
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    close () {
      this.modal = false
      this.indication = {}
      this.$emit('closed')
    },
    deleteIndication (id, index) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            serviceReport.deleteIndication(id).then((data) => {
              successMessage(data.message, 2500)
              this.indication.splice(index, 1)
            }).catch(error => {
              errorMessage('Alerta: ' + error.message)
            })
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    }
  },
  computed: {
    totalIndication () {
      if (this.indication.length) {
        return this.indication.length
      }
      return 0
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
.swal2-popup {
  font-size: 11px !important;
}
</style>
