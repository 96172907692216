<template>
  <div style="margin-left:-15px">
    <form @submit.prevent="saveCallAction()" autocomplete="off">
      <div class="row">
        <div class="col-sm-9">
          <div class="form-group">
            <label for="message">Mensagem</label>
            <input
              id="message"
              v-model="call_action.message"
              name="message"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.call_action.message.$error }"
            />
            <div
              v-if="submitted && !$v.call_action.message.required"
              class="invalid-feedback"
            >
              O campo mensage é obrigatório
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-9">
          <div class="form-group">
            <label for="url_action">Link de ação</label>
            <input
              id="url_action"
              v-model="call_action.url_action"
              name="url_action"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.call_action.url_action.$error }"
            />
            <div
              v-if="submitted && !$v.call_action.url_action.url"
              class="invalid-feedback"
            >
              O campo link de ação deve ser uma URL válida
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <label for="cta">CTA</label>
            <select
              class="custom-select"
              name="cta"
              id="cta"
              v-model="call_action.cta"
            >
              <option value="">Nenhum</option>
              <option value="Clique aqui">Clique aqui</option>
              <option value="Saiba mais">Saiba mais</option>
              <option value="Cadastre-se">Cadastre-se</option>
              <option value="Baixar">Baixar</option>
              <option value="Ver agora">Ver agora</option>
              <option value="Fale conosco">Fale conosco</option>
              <option value="Participe">Participe</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-9">
          <div class="float-right">
            <button type="submit" class="btn btn-primary mr-1">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { required, url } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
  },
  name: 'call-action',
  data () {
    return {
      submitted: false,
      call_action: {
        message: '',
        url_video: '',
        cta: ''
      }
    }
  },
  validations: {
    call_action: {
      message: { required },
      url_action: { url }
    }
  },
  beforeMount () {
    let loader = this.$loading.show()
    this.fetchData(this.$route.params.id).then((res) => {
      this.call_action = this.data
      loader.hide()
    })
  },
  computed: {
    ...mapState('callAction', { data: 'call_action' })
  },
  methods: {
    ...mapActions('callAction', {
      fetchData: 'getList',
      postData: 'save'
    }),
    async saveCallAction () {
      this.submitted = true
      this.call_action.room_id = this.$route.params.id
      this.$v.call_action.$touch()
      if (this.$v.call_action.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      await this.postData(this.call_action).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        loader.hide()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    }
  }
}
</script>
