<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="applyFilter()" autocomplete="off">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label for="domain">E-mail (Domínio)</label>
                <input
                  id="domain"
                  v-model="filter.domain"
                  name="domain"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="city">Cidade</label>
                <input
                  id="city"
                  v-model="filter.city"
                  name="city"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="district">Estado</label>
                <multiselect
                  v-model="selected"
                  :options="states" class="helo"
                  :max-height="180"
                  trackBy="value"
                  label="label"
                  placeholder="Selecione"
                >
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label for="room_id">Sala</label>
                <room-select
                  id="room_id"
                  :value="roomSelected"
                  @input="setRoom"
                >
                </room-select>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="status">Status</label>
                <select
                  class="custom-select"
                  name="status"
                  v-model="filter.status"
                >
                  <option value="">Todos</option>
                  <option value="0">Não Respondida</option>
                  <option value="1">Respondida</option>
                  <option value="2">Respondida ao vivo</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Fechar</b-button>
      <b-button class="btn btn-dark" @click="clean()">Limpar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="applyFilter()">Aplicar</button>
    </template>
  </b-modal>
</template>
<script>
import { states } from '@/pages/participants/statesData'
import Multiselect from 'vue-multiselect'
import roomSelect from '@/components/selects/room-select'

export default {
  name: 'asking-filter',
  components: {
    roomSelect,
    Multiselect
  },
  props: {},
  data () {
    return {
      title: 'Filtro - Perguntas',
      selected: '',
      states: states,
      roomSelected: '',
      filter: {},
      modal: false
    }
  },
  methods: {
    open () {
      this.modal = true
    },
    setRoom (value) {
      this.filter.room_id = value
    },
    applyFilter () {
      if (typeof (this.selected) === 'object') {
        this.filter.district = this.selected.value
      }
      this.$emit('askingFiltered', this.filter)
      this.close()
    },
    close () {
      this.modal = false
    },
    clean () {
      this.filter = {}
      this.roomSelected = ''
      this.selected = ''
    }
  },
  computed: {
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
        return
      }
      if (this.modal && this.filter.room_id) {
        this.roomSelected = this.filter.room_id
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
