<template>
  <div>
  <multiselect
    v-model="selected"
    :options="options"
    class="helo"
    trackBy="id"
    :class="classError ? 'is-invalid' : ''"
    selectLabel="Click para selecionar"
    selectedLabel="Selecionado"
    deselectLabel="Click para remover"
    label="name"
    :max-height="180"
    open-direction="bottom"
    placeholder="Selecione uma sala"
  >
  </multiselect>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import roomService from '@/pages/rooms/services'

export default {
  name: 'KeyMultiselect',
  inheritAttrs: false,
  components: {
    Multiselect
  },
  props: {
    value: {
      default: null
    },
    classError: {
      default: false
    }
  },
  data () {
    return {
      selected: null,
      options: []
    }
  },
  computed: {
    optionSelected () {
      return this.value
    }
  },
  watch: {
    'selected' (value) {
      if (value) {
        this.$emit('input', value.id)
        return
      }
      this.$emit('input', null)
    },
    'value' (value) {
      this.selected = this.options.filter(o => o.id === parseInt(value))[0]
    }
  },
  methods: {
    loadItems () {
      roomService.list().then((res) => {
        this.options = res.data.data
        if (this.value) {
          this.selected = this.options.filter(o => o.id === parseInt(this.value))[0]
        }
      })
    }
  },
  beforeMount () {
    this.loadItems()
  }
}
</script>
<style>
.multiselect.is-invalid .multiselect__tags {
  border-color:  #f86c6b !important;
}
</style>
