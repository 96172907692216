<template>
  <div class="center mt-5 ml-2 mr-2 uppercase">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row justify-content-md-center">
      <div class="col-lg-10">
        <div class="card">
          <div class="card-body">
            <p class="text-muted font-size-22">Pergunta</p>
            <h2 class="card-title mb-4 font-size-22">{{ poll.description }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-lg-10">
        <div class="card">
          <div class="card-body" style="background: #424242 !important;">
            <!-- Bar Chart -->
            <apexchart
              v-show="!loading"
              class="apex-charts-modal"
              height="520"
              type="bar"
              ref="pollChart"
              dir="ltr"
              :series="series"
              :options="chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import http from '@/http'

export default {
  components: { Loading },
  data () {
    return {
      loading: true,
      pollId: null,
      poll: {},
      answers: [],
      series: [{
        data: []
      }]
    }
  },
  methods: {
    getInitPoll () {
      http.get(`/admin/charts/poll/${this.pollId}`).then((response) => {
        if (response.data.data.id) {
          this.poll = response.data.data
          this.answers = response.data.result.map(o => {
            return o.option
          })
          const totais = response.data.result.map(o => {
            return o.porcentagem
          })
          this.series[0].data = totais
          this.loading = false
        }
      }).then(() => {
        this.loading = false
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    connectSocket () {
      this.$pusher.subscribe(`chart-poll.${this.pollId}`).bind('ChartPoll', (response) => {
        if (response.data.id) {
          const totais = response.result.map(o => {
            return o.porcentagem
          })
          this.$refs.pollChart.updateSeries([{
            data: totais
          }])
        }
      })
    }
  },
  beforeMount () {
    this.loading = true
    if (this.$route.params.pollId) {
      this.pollId = this.$route.params.pollId
      this.getInitPoll()
      this.connectSocket()
      return
    }
    this.loading = false
  },
  mounted () {
    // this.getInitPoll()
    // Echo.channel('chart-quiz.' + this.sala)
    //   .listen('ChartQuiz', (e) => {
    //     if (e.data.id) {
    //       const totais = e.resultado.map(o => {
    //         return o.porcentagem
    //       })
    //       this.$refs.chart.updateSeries([{
    //         data: totais
    //       }])
    //     }
    //   })
  },
  computed: {
    chartOptions () {
      return {
        theme: {
          mode: 'dark'
        },
        chart: {
          type: 'bar',
          height: 520,
          toolbar: {
            tools: {
              zoomin: true,
              zoomout: true
            }
          },
          animations: {
            enabled: true,
            easing: 'bar',
            dynamicAnimation: {
              speed: 1000
            }
          }
        },
        plotOptions: {
          bar: {
            barHeight: '90%',
            columnWidth: '80%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom'
            }
          }
        },
        colors: ['#008ffb', '#7081ce', '#34c38f', '#a157bf', '#b5704f'],
        dataLabels: {
          enabled: true,
          textAnchor: 'center',
          style: {
            fontSize: '30px',
            colors: ['#FFF']
          },
          fill: {
            colors: ['#FFF']
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ': ' + val + '%'
          },
          offsetX: 0,
          dropShadow: {
            enabled: true
          }
        },
        legend: {
          show: true,
          fontSize: '25px',
          offsetY: 0
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: this.answers,
          labels: {
            tickAmount: 10,
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        title: {
          text: 'Respostas',
          align: 'center',
          floating: true,
          style: {
            color: '#3c4b64'
          }
        },
        tooltip: {
          enabled: false,
          theme: 'dark',
          x: {
            enabled: false,
            show: false
          },
          y: {
            show: true
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
.uppercase { text-transform: uppercase !important; }
.apex-charts-modal {
    min-height: 10px !important;
    text {
        fill: #FFF !important;
    }
    .apexcharts-canvas {
        margin: 0 auto;
    }
}
.apexcharts-yaxis,
.apexcharts-xaxis {
  text {
      fill: #FFF !important;
  }
}
.font-size-22 { font-size: 22px !important }
</style>
