<template>
  <div>
    <b-card >
      <b-nav tabs class="nav-tabs-custom">
        <b-nav-item :to="{ name: 'partner-videos' }" exact exact-active-class="active">Vídeos</b-nav-item>
        <b-nav-item :to="{ name: 'partner-archives' }" exact exact-active-class="active">Arquivos</b-nav-item>
        <b-nav-item :to="{ name: 'partner-products' }" exact exact-active-class="active">Produtos</b-nav-item>
        <b-nav-item :to="{ name: 'partner-contacts' }" exact exact-active-class="active">Contatos</b-nav-item>
      </b-nav>
      <b-card-body class="main-content-default">
        <router-view></router-view>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
export default {
  name: 'ContentPartner',
  components: {},
  props: {
  },
  data () {
    return {
      chat: false
    }
  },
  created () {}
}
</script>
<style>
.main-content-default {min-height: 307px;}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #5fc78a;
    background-color: #33b46d;
}
</style>
