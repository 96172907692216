<template>
  <div style="margin-left:-15px">
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="text-sm-right">
          <button type="button" class="btn btn-success btn-sm btn-rounded" @click="newWifi()">
            <i class="mdi mdi-plus"></i> Adicionar Rede Wifi
          </button>
        </div>
      </div>
      <!-- end col-->
    </div>
    <div class="row">
      <div class="col-md-12 text-center" v-show="wifis.length === 0">
        <h5 class="text-truncate font-size-14 p-4 mt-2">Nenhuma rede wifi cadastrada.</h5>
        <br />
      </div>
      <div class="table-responsive mt-3 mb-0" v-show="wifis.length > 0">
        <table class="table table-centered table-nowrap table-hover">
          <thead>
            <tr>
              <th width="30%">Nome</th>
              <th width="40%">Local</th>
              <th width="20%">Senha</th>
              <th width="10%">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(s, i) in wifis" :key="i" class="link">
              <td @click="showWifi(s.id)">{{ s.name }}</td>
              <td @click="showWifi(s.id)">{{ s.local_wifi }}</td>
              <td @click="showWifi(s.id)">{{ s.password }}</td>
              <td>
                <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal font-size-18"></i>
                  </template>
                  <b-dropdown-item @click="showWifi(s.id)">
                    <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteWifi(s.id)">
                    <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-4" v-show="wifis.length > 0">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
          </ul>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-center"
      ref="modal-wifi"
      v-model="modal"
      title="Detalhes"
      title-class="font-18"
      size="lg"
      :no-close-on-backdrop="true"
    >
      <form @submit.prevent="saveWifi()">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="name">Nome</label>
              <input
                id="name"
                v-model="wifi.name"
                name="name"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.wifi.name.$error }"
              />
              <div
                v-if="submitted && !$v.wifi.name.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="password">Senha</label>
              <input
                id="password"
                v-model="wifi.password"
                name="password"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="local_wifi">Local da Wifi</label>
              <input
                id="local_wifi"
                v-model="wifi.local_wifi"
                name="local_wifi"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.wifi.local_wifi.$error }"
              />
              <div
                v-if="submitted && !$v.wifi.local_wifi.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </div>
            </div>
          </div>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeModal()">Fechar</b-button>
        <b-button variant="primary" @click="saveWifi()">Salvar</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
export default {
  components: {
  },
  name: 'wifis',
  data () {
    return {
      submitted: false,
      params: {},
      modal: false,
      wifi: {
        status: false,
        user: {}
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20
    }
  },
  validations: {
    wifi: {
      name: { required },
      local_wifi: { required }

    }
  },
  beforeMount () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  computed: {
    ...mapState('wifi', ['wifis', 'pagination']),
    ...mapState('wifi', { data: 'wifi' }),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('wifi', {
      fetchData: 'getList',
      fetchItem: 'show',
      postData: 'save',
      deleteItem: 'deleteWifi'
    }),
    showWifi (id) {
      this.fetchItem({ id }).then((res) => {
        this.$refs['modal-wifi'].show()
        this.wifi = this.data
      })
    },
    cleanWifi () {
      this.wifi = {}
    },
    newWifi () {
      this.$refs['modal-wifi'].show()
      this.modal = true
    },
    closeModal () {
      this.$refs['modal-wifi'].hide()
      this.cleanWifi()
      this.$v.$reset()
    },
    deleteWifi (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem({ id }).then((res) => {
              successMessage('Sucesso: Wifi excluída com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    async saveWifi () {
      this.submitted = true
      this.$v.wifi.$touch()
      if (this.$v.wifi.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      await this.postData(this.wifi).then(res => {
        let message = 'Sucesso: Ação registrada com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.closeModal()
        this.updateList()
        loader.hide()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    },
    updateList () {
      this.fetchData({ limit: this.perPage, page: this.currentPage })
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.closeModal()
      }
    }
  }
}
</script>

<style scoped>
.archive-archived { color: #909090; background: #fff0f0}
.mt-15 { margin-top:15px }
</style>
